import { Component, Input } from '@angular/core';
import { Borehole } from 'src/app/graphql/types/default';

@Component({
  selector: 'app-location',
  templateUrl: './location.component.html',
  styleUrls: ['./location.component.scss'],
})
export class LocationComponent {
  @Input() borehole!: Borehole;
  constructor() {}
}
