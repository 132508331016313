import { gql } from 'apollo-angular';

export const sdeScenarioInformations = gql`
  query Sde($order: orderBy, $filters: [filterBy]) {
    sde(order: $order, filters: $filters) {
      __typename
      id
      scenarioInformations {
        id
        scenario

        p90_aquifer_k_h_net_dm
        p50_aquifer_k_h_net_dm
        p10_aquifer_k_h_net_dm

        p90_pump_volume_flow_m3_h
        p50_pump_volume_flow_m3_h
        p10_pump_volume_flow_m3_h

        p90_geothermal_power_mw
        p50_geothermal_power_mw
        p10_geothermal_power_mw

        p90_aquifer_pressure_at_producer_bar
        p50_aquifer_pressure_at_producer_bar
        p10_aquifer_pressure_at_producer_bar

        p90_pressure_difference_at_injector_bar
        p50_pressure_difference_at_injector_bar
        p10_pressure_difference_at_injector_bar

        p90_aquifer_temperature_at_producer_c
        p50_aquifer_temperature_at_producer_c
        p10_aquifer_temperature_at_producer_c

        p90_temperature_at_heat_exchanger_c
        p50_temperature_at_heat_exchanger_c
        p10_temperature_at_heat_exchanger_c
      }
    }
  }
`;
