import { Component, EventEmitter, Input, Output, ViewChild } from '@angular/core';
import styling from './styling';
import { GoogleMap } from '@angular/google-maps';
import { BoreholeWithLocation, MiningLocationWithLocation } from 'src/app/interfaces/location.interface';
import { GoogleMapsService } from '@services/google-maps.service';
import { BehaviorSubject, interval } from 'rxjs';
import { debounce, takeUntil } from 'rxjs/operators';
import { DestroyComponent } from 'src/app/abstracts/destroy.component';
import { AuthService } from '@services/auth.service';

@Component({
  selector: 'app-map',
  templateUrl: './map.component.html',
  styleUrls: ['./map.component.scss'],
})
export class MapComponent extends DestroyComponent {
  @ViewChild(GoogleMap, { static: false }) set map(map: GoogleMap) {
    if (map && !this.googleMapsService.map) {
      this.googleMapsService.map = map;
      map.fitBounds(this.bounds);
      this.googleMapsService.initDrawingManager();
      // this.googleMapsService.initLayers();

      this.subscribeToLocations();
      this.subscribeToZoomAndDrag(map);
    }
  }

  @Input() locationsSubject!: BehaviorSubject<(BoreholeWithLocation | MiningLocationWithLocation)[] | null>;
  @Output() boreholeClick = new EventEmitter<BoreholeWithLocation>();
  @Output() miningLocationClick = new EventEmitter<MiningLocationWithLocation>();

  private cluster!: MarkerClusterer;
  private markers!: google.maps.Marker[];
  private markersAreClustered: boolean = true;

  private the_netherlands_bounds = {
    north: 53.68169722,
    south: 50.65947114,
    west: 2.81815748,
    east: 8.20322846,
  };

  public options: google.maps.MapOptions = {
    styles: styling as google.maps.MapTypeStyle[],
    mapTypeControl: false,
    panControl: false,
    streetViewControl: false,
    fullscreenControl: false,
    restriction: {
      latLngBounds: this.the_netherlands_bounds,
      strictBounds: false,
    },
  };

  private bounds = {
    south: 50.75947114,
    west: 2.81815748,
    north: 53.68169722,
    east: 6.20322846,
  };

  constructor(public googleMapsService: GoogleMapsService, public authService: AuthService) {
    super();
  }

  public handleBoreholeClick(deepBorehole: BoreholeWithLocation): void {
    this.boreholeClick.next(deepBorehole);
  }

  public handleMiningLocationClick(miningLocation: MiningLocationWithLocation): void {
    this.miningLocationClick.next(miningLocation);
  }

  private subscribeToLocations(): void {
    this.locationsSubject.subscribe((filteredLocations) => {
      this.setMarkers(this.googleMapsService.map, filteredLocations);
    });
  }

  private setMarkers(map: GoogleMap, locations: (BoreholeWithLocation | MiningLocationWithLocation)[] | null): void {
    if (map?.googleMap) {
      if (this.markers) {
        this.markers.forEach((marker) => {
          marker.setMap(null);
        });
      }
      const markers: google.maps.Marker[] = [];

      if (locations) {
        locations.forEach((location) => {
          const marker = this.makeMarker(location);
          if (marker) {
            markers.push(marker);
          }
        });
        this.markers = markers;
      }

      if (this.markersAreClustered) {
        this.setMarkersWithCluster(map, markers);
      } else {
        this.setMarkersWithoutCluster(map, markers);
      }
    }
  }

  private setMarkersWithoutCluster(map: GoogleMap, markers: google.maps.Marker[]) {
    this.markersAreClustered = false;
    const bounds = map.getBounds();
    const showLabel = map.getZoom() > 13;

    markers.forEach((marker) => {
      const position = marker.getPosition();
      if (map.googleMap) {
        if (marker && position && marker.getVisible() && bounds && bounds.contains(position)) {
          marker.setMap(map.googleMap);
          if (showLabel && !marker.getTitle()?.includes('-S')) {
            marker.setLabel({
              text: marker.getTitle() ?? '',
              color: 'black',
            });
          } else {
            marker.setLabel(null);
          }
        } else {
          marker.setMap(null);
        }
      }
    });
  }

  private setMarkersWithCluster(map: GoogleMap, markers: google.maps.Marker[]) {
    this.markersAreClustered = true;
    if (map?.googleMap) {
      if (!this.cluster) {
        this.cluster = new MarkerClusterer(map.googleMap, markers, {
          imagePath: 'https://developers.google.com/maps/documentation/javascript/examples/markerclusterer/m',
        });
      } else {
        this.cluster.clearMarkers();
        const bounds = map.getBounds();
        this.cluster.addMarkers(
          markers.filter((marker) => {
            const position = marker.getPosition();
            return marker && position && marker.getVisible() && bounds && bounds.contains(position);
          })
        );
      }
    }
  }

  private makeMarker(location: BoreholeWithLocation | MiningLocationWithLocation): google.maps.Marker | null {
    let icon_url: string = '';
    if (location.location && location.__typename === 'Borehole' && location.isGeothermal) {
      icon_url = '/assets/markers/geothermal-borehole-marker.png';
    } else if (location.location && location.__typename === 'MiningLocation') {
      icon_url = '/assets/markers/mining-location-marker.png';
    } else if (location.location && location.__typename === 'Borehole' && !location.isGeothermal) {
      icon_url = '/assets/markers/borehole-marker.png';
    }

    if (location.location) {
      const marker = new google.maps.Marker({
        visible: true,
        position: location.location,
        icon: {
          url: icon_url,
          labelOrigin: new google.maps.Point(60, 6),
        },
        // set the title attribute so we can use this for the label this.setMarkersWithoutCluster
        title: location.__typename === 'Borehole' ? location.boorgatcode ?? '' : location.mijnbouwwerk_code ?? '',
      });

      marker.addListener('click', () => {
        if (location.__typename === 'Borehole') {
          this.handleBoreholeClick(location);
        } else if (location.__typename === 'MiningLocation') {
          this.handleMiningLocationClick(location);
        }
      });

      if (location.location && location.__typename === 'MiningLocation') {
        marker.setZIndex(10);
      } else {
        marker.setZIndex(5);
      }

      return marker;
    }
    return null;
  }

  private subscribeToZoomAndDrag(map: GoogleMap) {
    map.zoomChanged
      .pipe(
        takeUntil(this.destroy),
        debounce(() => interval(500))
      )
      .subscribe(() => {
        if (map.getZoom() > 10) {
          if (this.markersAreClustered) {
            this.cluster.clearMarkers();
          }
          this.setMarkersWithoutCluster(map, this.markers);
        } else {
          if (!this.markersAreClustered) {
            this.setMarkersWithCluster(map, this.markers);
          }
        }
      });

    map.mapDragend
      .pipe(
        takeUntil(this.destroy),
        debounce(() => interval(500))
      )
      .subscribe(() => {
        if (map.getZoom() > 10) {
          if (this.markersAreClustered) {
            this.cluster.clearMarkers();
          }
          this.setMarkersWithoutCluster(map, this.markers);
        } else {
          this.setMarkersWithCluster(map, this.markers);
        }
      });
  }
}
