<div style="height: 520px" class="modal_body">
  <div *ngIf="!borehole" class="flex items-center justify-around p-10">
    <mat-spinner class="custom-material-styles" [diameter]="20"></mat-spinner>
  </div>
  <ng-container *ngIf="borehole">
    <div class="p-8">
      <div class="flex items-baseline">
        <img *ngIf="!borehole.isGeothermal" src="/assets/svg/borehole.svg" class="w-6 mr-3" />
        <img *ngIf="borehole.isGeothermal" src="/assets/svg/geothermal-borehole.svg" class="w-6 mr-3" />
        <h2 class="text-3xl heading">{{ borehole.boorgatcode }}</h2>
      </div>
      <span class="text-brand-blue text-2xl heading">Borehole information</span>
    </div>
    <app-tabs tabbarClass="px-8">
      <app-tab icon="information-circle">
        <div class="p-8 flex flex-col">
          <app-information [borehole]="borehole"></app-information>
        </div>
      </app-tab>
      <app-tab icon="location">
        <div class="p-8 flex flex-col">
          <app-location [borehole]="borehole"></app-location>
        </div>
      </app-tab>
      <app-tab icon="construct">
        <div class="p-8 flex flex-col">
          <app-technical [borehole]="borehole"></app-technical>
        </div>
      </app-tab>
    </app-tabs>
  </ng-container>
</div>
<div class="flex space-x-4 justify-items-stretch p-8">
  <button class="btn bg-white hover:bg-gray-100 text-black min-w-40" mat-button mat-dialog-close>Sluiten</button>
  <button class="btn min-w-40" mat-button (click)="doExport()">Export</button>
</div>
