<div class="mb-5 flex">
  <div class="w-1/2">
    <div class="font-bold leading-6">Kickoff diepte AH</div>
    <div *ngIf="borehole.kickoff_diepte_ah">{{ borehole.kickoff_diepte_ah ? borehole.kickoff_diepte_ah : '-' }}m</div>
  </div>
</div>

<div class="mb-5 flex">
  <div class="w-1/2">
    <div class="font-bold leading-6">Boorgatdiepte AH</div>
    <div *ngIf="borehole.boorgatdiepte_AH">{{ borehole.boorgatdiepte_AH ? borehole.boorgatdiepte_AH : '-' }}m</div>
  </div>
  <div class="w-1/2">
    <div class="font-bold">Boorgatdiepte TVD</div>
    <div *ngIf="borehole.boorgatdiepte_TVD">{{ borehole.boorgatdiepte_TVD ? borehole.boorgatdiepte_TVD : '-' }}m</div>
  </div>
</div>

<div class="mb-5 flex">
  <div class="w-1/2">
    <div class="font-bold leading-6">Hoogte referentievlak t.o.v. {{ borehole.tov }}</div>
    <div *ngIf="borehole.hoogte_referentievlak && borehole.tov">
      {{ borehole.hoogte_referentievlak ? borehole.hoogte_referentievlak : '-' }}m
    </div>
  </div>
</div>

<div class="mb-5 flex">
  <div class="w-1/2">
    <div class="font-bold leading-6">Boorgatdiepte AH t.o.v. NAP / MSL</div>
    <div *ngIf="borehole.boorgatdiepte_AH_tov_NAP">
      {{ borehole.boorgatdiepte_AH_tov_NAP ? borehole.boorgatdiepte_AH_tov_NAP : '-' }}m
    </div>
  </div>
  <div class="w-1/2">
    <div class="font-bold leading-6">Boorgatdiepte TVD t.o.v. NAP / MSL</div>
    <div *ngIf="borehole.boorgatdiepte_TVD_tov_NAP">
      {{ borehole.boorgatdiepte_TVD_tov_NAP ? borehole.boorgatdiepte_TVD_tov_NAP : '-' }}m
    </div>
  </div>
</div>

<div class="mb-5 flex">
  <div class="w-1/2">
    <div class="font-bold leading-6">Lithostratigrafie code</div>
    <div *ngIf="borehole.lithostratigrafie_code">
      {{ borehole.lithostratigrafie_code ? borehole.lithostratigrafie_code : '-' }}
    </div>
  </div>
  <div class="w-1/2">
    <div class="font-bold leading-6">Chronostratigrafie code</div>
    <div *ngIf="borehole.chronotratigrafie_code">
      {{ borehole.chronotratigrafie_code ? borehole.chronotratigrafie_code : '-' }}
    </div>
  </div>
</div>

<div class="mb-5 flex">
  <div class="w-1/2">
    <div class="font-bold leading-6">Boortoren/-platform</div>
    <div>{{ borehole.boortoren ? borehole.boortoren : '-' }}</div>
  </div>
  <div class="w-1/2">
    <div class="font-bold leading-6">Boorfirma</div>
    <div>{{ borehole.boorfirma ? borehole.boorfirma : '-' }}</div>
  </div>
</div>

<div class="mb-5 flex">
  <div class="w-1/2">
    <div class="font-bold leading-6">Startdatum</div>
    <div *ngIf="borehole.startdatum">{{ borehole.startdatum ? borehole.startdatum : '-' }}</div>
  </div>
  <div class="w-1/2">
    <div class="font-bold leading-6">Einddatum</div>
    <div *ngIf="borehole.einddatum">{{ borehole.einddatum ? borehole.einddatum : '-' }}</div>
  </div>
</div>

<div class="mb-5 flex">
  <div class="w-1/2">
    <div class="font-bold leading-6">Openbaar vanaf</div>
    <div *ngIf="borehole.openbaar_vanaf">
      {{ borehole.openbaar_vanaf | betterDate : 'dd/MM/yyyy' : 'yyyy-MM-dd HH:mm:ss' }}
    </div>
    <div *ngIf="!borehole.openbaar_vanaf">-</div>
  </div>
</div>
