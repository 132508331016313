import { Pipe, PipeTransform } from '@angular/core';
import { parse, format as dateFnsFormat } from 'date-fns';

@Pipe({
  name: 'betterDate',
})
export class BetterDatePipe implements PipeTransform {
  /**
   *
   * @param value to format
   * @param format output format
   * @param fromFormat input format
   * @returns string
   */

  transform(value: string, format: string, fromFormat?: string): string {
    if (!fromFormat) {
      throw Error("fromFormat can't be null when when value is a string");
    }
    const parsedValue = parse(value, fromFormat, new Date());

    return dateFnsFormat(parsedValue, format);
  }
}
