import { Component } from '@angular/core';
import { FormArray, FormControl, FormGroup } from '@angular/forms';
import { BoreholeDataService } from '@services/borehole-data.service';
import { BoreholesTypes } from 'src/app/graphql/types/default';

@Component({
  selector: 'app-nonpro-modus',
  templateUrl: './nonpro-modus.component.html',
  styleUrls: ['./nonpro-modus.component.scss'],
})
export class NonproModusComponent {
  public results: string[] = [];

  constructor(public boreholeDataService: BoreholeDataService) {}
}
