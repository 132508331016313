import { Component, OnInit } from '@angular/core';
import { ProModusService } from '@services/pro-modus.service';
import { GoogleMapsService } from '@services/google-maps.service';
import { FormArray, FormControl } from '@angular/forms';
import { LegendDialogComponent } from './legend-dialog/legend-dialog.component';
import { MatDialog } from '@angular/material/dialog';

@Component({
  selector: 'app-map-options',
  templateUrl: './map-options.component.html',
  styleUrls: ['./map-options.component.scss'],
})
export class MapOptionsComponent implements OnInit {
  public warmtenettenControl: FormControl = new FormControl(false);
  public woonkernenControl: FormControl = new FormControl(false);
  public kassenControl: FormControl = new FormControl(false);
  public thermoGis629Control: FormControl = new FormControl(false);

  public hideOptionMenu: boolean = false;

  constructor(
    public proModusService: ProModusService,
    public googleMapsService: GoogleMapsService,
    public dialog: MatDialog
  ) {}

  ngOnInit() {
    this.warmtenettenControl.valueChanges.subscribe((value) => {
      if (value) {
        this.googleMapsService.warmteAtlasLayers.push(new FormControl('bundled:WarmteNetten'));
      } else {
        this.removeFormArrayItem(this.googleMapsService.warmteAtlasLayers, 'bundled:WarmteNetten');
      }
    });
    this.kassenControl.valueChanges.subscribe((value) => {
      if (value) {
        this.googleMapsService.warmteAtlasLayers.push(new FormControl('bundled:Kassen'));
      } else {
        this.removeFormArrayItem(this.googleMapsService.warmteAtlasLayers, 'bundled:Kassen');
      }
    });
    this.woonkernenControl.valueChanges.subscribe((value) => {
      if (value) {
        this.googleMapsService.warmteAtlasLayers.push(new FormControl('bundled:WoonKernen'));
      } else {
        this.removeFormArrayItem(this.googleMapsService.warmteAtlasLayers, 'bundled:WoonKernen');
      }
    });

    this.thermoGis629Control.valueChanges.subscribe((value) => {
      if (value) {
        this.googleMapsService.thermoGisLayers.push(new FormControl('629'));
      } else {
        this.removeFormArrayItem(this.googleMapsService.thermoGisLayers, '629');
      }
    });
  }

  private removeFormArrayItem(formArray: FormArray, value: string): void {
    (formArray.controls as FormControl[]).forEach((ctrl: FormControl, index: number) => {
      if (ctrl.value == value) {
        formArray.removeAt(index);
        return;
      }
    });
  }
  public toggleMenu(): void {
    this.hideOptionMenu = !this.hideOptionMenu;
  }

  public openLegendPage() {
    this.dialog.open(LegendDialogComponent);
  }
}
