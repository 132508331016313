import { gql } from 'apollo-angular';

export const scenarioOptions = gql`
  query ScenarioInformation($filters: [filterBy]) {
    scenarioInformation(filters: $filters) {
      id
      scenario
    }
  }
`;
