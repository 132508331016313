import { gql } from 'apollo-angular';

export const exportTemperatureQuery = gql`
  query Export($temperatureDownloadable: TemperatureDownloadable) {
    export(temperatureDownloadable: $temperatureDownloadable)
  }
`;

export const exportCorrectedTemperatureQuery = gql`
  query Export($correctedTemperatureDownloadable: CorrectedTemperatureDownloadable) {
    export(correctedTemperatureDownloadable: $correctedTemperatureDownloadable)
  }
`;

export const exportPoropermQuery = gql`
  query Export($poropermDownloadable: PoropermDownloadable) {
    export(poropermDownloadable: $poropermDownloadable)
  }
`;

export const exportPermeabilityQuery = gql`
  query Export($permeabilityDownloadable: PermeabilityDownloadable) {
    export(permeabilityDownloadable: $permeabilityDownloadable)
  }
`;

export const exportSdeScenarioQuery = gql`
  query Export($sdeScenarioDownloadable: SdeScenarioDownloadable) {
    export(sdeScenarioDownloadable: $sdeScenarioDownloadable)
  }
`;

export const exportGeoThermalHeatQuery = gql`
  query Export($geoThermalHeatDownloadable: GeoThermalHeatDownloadable) {
    export(geoThermalHeatDownloadable: $geoThermalHeatDownloadable)
  }
`;

export const exportBoreholeQuery = gql`
  query Export($boreholeDownloadable: BoreholeDownloadable) {
    export(boreholeDownloadable: $boreholeDownloadable)
  }
`;

export const exportSde = gql`
  query Export($sdeDownloadable: SdeDownloadable) {
    export(sdeDownloadable: $sdeDownloadable)
  }
`;
