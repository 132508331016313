import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { LoginMutation } from '../graphql/mutations/login.mutation';
import { Apollo } from 'apollo-angular';
import { tap } from 'rxjs/operators';
import { Router } from '@angular/router';
import { logOut } from '../graphql/queries/logout.query';
import { UserService } from './user.service';

@Injectable({
  providedIn: 'root',
})
export class AuthService {
  constructor(private apollo: Apollo, public router: Router, public userService: UserService) {}

  public login(variables: any): Observable<any> {
    return this.apollo
      .use('auth')
      .mutate({
        mutation: LoginMutation,
        variables,
      })
      .pipe(
        tap((response: any) => {
          this.saveUserData(response?.data?.logIn?.api_token);
          this.userService.isLoggedIn$.next(true);
        })
      );
  }

  private saveUserData(token: string) {
    localStorage.setItem('api_token', token);
  }

  public loginUser() {
    this.router.navigate(['/auth/login']).then(() => window.location.reload());
  }

  public logout() {
    localStorage.removeItem('api_token');
    localStorage.removeItem('permissions');
    this.router.navigate(['/']).then(() => window.location.reload());
  }

  public can(permission: string): boolean {
    const permissions = localStorage.getItem('permissions');

    if (permissions !== null) {
      const parsed = JSON.parse(permissions);

      // Use `some` to check if at least one permission matches the provided name
      return parsed.some((p: any) => p.name === permission);
    }

    return false;
  }
}
