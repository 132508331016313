import { Component, Inject, OnInit } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { Apollo } from 'apollo-angular';
import { ChartDataSets, ChartOptions } from 'chart.js';
import { parse } from 'date-fns';
import { Color, Label } from 'ng2-charts';
import { Borehole, GeoThermalGraph, GeoThermalHeat, MiningLocation } from 'src/app/graphql/types/default';
import { map } from 'rxjs/operators';
import { miningLocationWaterGraphQuery } from 'src/app/graphql/queries/mining-location';
import { downloadHelper } from '@helpers/mapping-helper';
import { DownloadService } from '@services/download.service';
import { exportGeoThermalHeatQuery, exportTemperatureQuery } from 'src/app/graphql/queries/export';
import { boreholeWaterGraphQuery } from 'src/app/graphql/queries/queries';

@Component({
  selector: 'app-water-amount-chart',
  templateUrl: './water-amount-chart.component.html',
  styleUrls: ['./water-amount-chart.component.scss'],
})
export class WaterAmountChartComponent implements OnInit {
  public geoThermalHeats!: GeoThermalHeat[];
  private fontFamily =
    '"IBM Plex Mono", ui-monospace, SFMono-Regular, Menlo, Monaco, Consolas, "Liberation Mono", "Courier New", monospace';
  isDownloading!: boolean;

  constructor(
    private apollo: Apollo,
    public dialogRef: MatDialogRef<WaterAmountChartComponent>,
    @Inject(MAT_DIALOG_DATA)
    public data: {
      forType: 'Borehole' | 'MiningLocation';
      id: MiningLocation['id'] | Borehole['id'];
      miningLocationCode: MiningLocation['mijnbouwwerk_code'];
      data_column: 'water_amount' | 'injected_water_amount';
      heading: string;
    },
    private downloadService: DownloadService
  ) {}

  ngOnInit(): void {
    if (this.data.forType === 'MiningLocation') {
      this.fetchGeoThermalHeatsForMiningLocation();
    } else {
      this.fetchGeoThermalHeatsForBorehole();
    }
  }

  public lineChartData: ChartDataSets[] = [{ data: [] }];
  public lineChartLabels: Array<Label> = [];
  public lineChartOptions: ChartOptions = {
    tooltips: {
      displayColors: false,
      callbacks: {
        label: function (tooltipItems, data) {
          return tooltipItems.yLabel + ' (m3/maand)';
        },
      },
    },
    responsive: true,
    elements: { line: { tension: 0, fill: false, borderWidth: 2 } },

    scales: {
      yAxes: [
        {
          scaleLabel: {
            labelString: 'M3 PER MAAND',
            display: true,
            fontColor: 'black',
            fontStyle: 'bold',
            fontFamily: this.fontFamily,
            padding: 10,
          },
          gridLines: {
            color: 'rgba(0,0,0,0.3)',
            zeroLineColor: 'rgba(0,0,0,0.3)',
            drawBorder: false,
          },

          id: 'y-axis-0',
          ticks: {
            callback: (value: number) => {
              return value.toLocaleString('nl-NL');
            },
            maxTicksLimit: 4,
            beginAtZero: true,
            fontColor: 'black',
            fontFamily: this.fontFamily,
            padding: 5,
          },
        },
      ],
      xAxes: [
        {
          gridLines: { display: false },
          id: 'x-axis-0',
          scaleLabel: {
            labelString: 'JAAR',
            display: true,
            fontColor: 'black',
            fontStyle: 'bold',
            fontFamily: this.fontFamily,
            padding: 10,
          },
          ticks: {
            callback: (value: string, index, values: string[]) => {
              const firstOfYear = values.find((dateString) => dateString.substr(0, 4) === value.substr(0, 4));
              if (firstOfYear === value) {
                const date = parse(value, 'yyyy-MM', new Date());
                return date.getFullYear().toString();
              } else {
                return null;
              }
            },
            beginAtZero: false,
            fontColor: 'black',
            fontFamily: this.fontFamily,
            padding: 10,
            maxRotation: 0,
            minRotation: 0,
            autoSkip: false,
          },
        },
      ],
    },
  };
  public lineChartColors: Color[] = [
    {
      backgroundColor: 'rgba(255,0,0,0.3)',
      borderColor: 'red',
      pointBackgroundColor: 'transparent',
      pointBorderColor: 'transparent',
      pointHoverBackgroundColor: 'black',
      pointHoverBorderColor: 'rgba(148,159,177,0.8)',
    },
  ];

  private fetchGeoThermalHeatsForMiningLocation(): void {
    const query = miningLocationWaterGraphQuery;
    this.apollo
      .query<{ miningLocation: MiningLocation[] }>({
        query: query,
        variables: {
          order: null,
          filters: [
            {
              field: 'id',
              values: [this.data.id.toString()],
            },
          ],
        },
      })
      .pipe(
        map((response) => response.data.miningLocation[0].geo_thermal_graph as GeoThermalGraph[]),
        map(this.parseHeatDates),
        map(this.sortGraphData)
      )
      .subscribe((geoThermalHeats: GeoThermalGraph[]) => {
        const months = this.extractAllMonthsOfListedYears(geoThermalHeats);

        this.setChartData(geoThermalHeats, months);
        this.setChartLabels(months);
      });
  }

  private fetchGeoThermalHeatsForBorehole(): void {
    const query = boreholeWaterGraphQuery;
    this.apollo
      .query<{ borehole: Borehole[] }>({
        query: query,
        variables: {
          order: null,
          filters: [
            {
              field: 'id',
              values: [this.data.id.toString()],
            },
          ],
        },
      })
      .pipe(
        map((response) => response.data.borehole[0].geo_thermal_graph as GeoThermalGraph[]),
        map(this.parseHeatDates),
        map(this.sortGraphData)
      )
      .subscribe((geoThermalHeats: GeoThermalGraph[]) => {
        const months = this.extractAllMonthsOfListedYears(geoThermalHeats);

        this.setChartData(geoThermalHeats, months);
        this.setChartLabels(months);
      });
  }

  private setChartData(geoThermalHeats: GeoThermalGraph[], months: string[]): void {
    this.lineChartData = [
      {
        data: months.map((month) => {
          const heat = geoThermalHeats.find((heat) => heat.datum === month);
          if (heat) {
            const amount = heat[this.data.data_column];
            return amount ?? 0;
          }
          return null;
        }),
      },
    ];
  }

  private setChartLabels(months: string[]): void {
    this.lineChartLabels = months;
  }

  private parseHeatDates(graphItems: GeoThermalGraph[]): GeoThermalHeatTransformed[] {
    return graphItems
      ? graphItems.map((heat) => {
          return {
            ...heat,
            datumObj: heat.datum ? parse(heat.datum, 'yyyy-MM', new Date()) : null,
          };
        })
      : [];
  }

  private sortGraphData(geoThermalHeats: GeoThermalHeatTransformed[]): GeoThermalHeatTransformed[] {
    return geoThermalHeats.sort((heatA: GeoThermalHeatTransformed, heatB: GeoThermalHeatTransformed) => {
      const dateA = heatA.datumObj ? heatA.datumObj.getTime() : 0;
      const dateB = heatB.datumObj ? heatB.datumObj.getTime() : 0;

      return dateA - dateB;
    });
  }

  /**
   * Creates array of labels including missing months of listed years
   * This ensures that the chart always shows a complete year even when there is only
   * data for one month
   */
  private extractAllMonthsOfListedYears(geoThermalHeats: GeoThermalGraph[]): string[] {
    const years: string[] = [];
    const months: string[] = [];

    geoThermalHeats.forEach((heat) => {
      const year = heat.datum?.substr(0, 4);
      if (year) {
        if (!years.includes(year)) {
          years.push(year);
          for (let month = 1; month < 13; month++) {
            const monthString = `0${month}`.slice(-2);
            months.push(`${year}-${monthString}`);
          }
        }
      }
    });

    return months;
  }

  doExport(): void {
    this.isDownloading = true;
    this.apollo
      .query({
        query: exportGeoThermalHeatQuery,
        fetchPolicy: 'no-cache',
        variables: {
          geoThermalHeatDownloadable: {
            by_type_name: this.data.forType,
            filters: [
              {
                field: 'id',
                values: [this.data.id.toString()],
              },
            ],
          },
        },
      })
      .subscribe((result) => {
        if (result.data) {
          this.downloadService.downloadExport((<any>result.data).export).subscribe((res) => {
            downloadHelper(res, (<any>result.data).export);
            this.isDownloading = false;
          });
        }
      });
  }
}
interface GeoThermalHeatTransformed extends GeoThermalGraph {
  datumObj: Date | null;
}
