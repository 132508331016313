import { Component, OnDestroy, OnInit } from '@angular/core';
import { FormArray, FormControl } from '@angular/forms';
import { BoreholeDataService } from '@services/borehole-data.service';
import { ProModusService } from '@services/pro-modus.service';
import { Apollo, gql } from 'apollo-angular';
import { BehaviorSubject, Subject, Subscription } from 'rxjs';
import { takeUntil } from 'rxjs/operators';
import { DestroyComponent } from 'src/app/abstracts/destroy.component';
import { boreholeStatus } from 'src/app/graphql/queries/queries';
import { scenarioOptions } from 'src/app/graphql/queries/scenario-informations';
import { sdeRondeDistinctQuery } from 'src/app/graphql/queries/sde-distinct';
import { Borehole, BoreholesTypes, ScenarioInformation, SdeDistinct } from 'src/app/graphql/types/default';

@Component({
  selector: 'app-filters',
  templateUrl: './filters.component.html',
  styleUrls: ['./filters.component.scss'],
})
export class FiltersComponent extends DestroyComponent implements OnInit {
  public boreholeTypeEnum = BoreholesTypes;
  public typeControl = new FormControl(this.boreholeDataService.typeControl.value);
  public filterTypes = [
    ...this.boreholeDataService.filterTypes,
    { description: 'SDE scenarios van installaties', value: 'SDE' },
  ];

  public boreholeStatusOptions: string[] = [];
  public scenarioOptions: ScenarioOption[] = [];

  public provinceOptions = [
    { description: 'Drenthe', value: 'DR' },
    { description: 'Flevoland', value: 'FL' },
    { description: 'Friesland', value: 'FR' },
    { description: 'Gelderland', value: 'GL' },
    { description: 'Groningen', value: 'GR' },
    { description: 'Limburg', value: 'LI' },
    { description: 'Noord-Brabant', value: 'NB' },
    { description: 'Noord-Holland', value: 'NH' },
    { description: 'Overijssel', value: 'OV' },
    { description: 'Utrecht', value: 'UT' },
    { description: 'Zeeland', value: 'ZE' },
    { description: 'Zuid-Holland', value: 'ZH' },
  ];

  public sdeRondeOptions: { description: string; value: string }[] = [];

  public isMinimalized = new BehaviorSubject<boolean>(true);

  constructor(
    public boreholeDataService: BoreholeDataService,
    private apollo: Apollo,
    public proModusService: ProModusService
  ) {
    super();
  }

  ngOnInit(): void {
    this.fetchBoreholeStatusOptions();
    this.fetchScenarioOptions();

    this.listenToTypeControl();
  }

  private listenToTypeControl(): void {
    this.typeControl.valueChanges.pipe(takeUntil(this.destroy)).subscribe((value) => {
      this.boreholeDataService.typeControl.setValue(value === 'SDE' ? BoreholesTypes.Facilities : value);
    });
  }

  private fetchBoreholeStatusOptions() {
    this.apollo
      .query<{ borehole: Borehole[] }>({
        query: boreholeStatus,
        variables: {
          distinct: true,
          order: {
            field: 'boorgatstatus',
            direction: 'asc',
          },
        },
      })
      .subscribe((response) => {
        if (response?.data?.borehole) {
          this.boreholeStatusOptions = response.data.borehole
            .map((item) => item.boorgatstatus)
            .filter((item) => item) as string[];
        }
      });
  }

  private fetchScenarioOptions() {
    this.apollo
      .query<{ scenarioInformation: ScenarioInformation[] }>({
        query: scenarioOptions,
        variables: {
          filters: [],
        },
      })
      .subscribe((response) => {
        if (response?.data?.scenarioInformation) {
          this.scenarioOptions = response.data.scenarioInformation as ScenarioOption[];
        }
      });
  }
}
interface ScenarioOption {
  id: number;
  scenario: string;
}
