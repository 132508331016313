import { Injectable } from '@angular/core';
import { MouseTool } from '@enums/mouse-tool.enum';
import { BehaviorSubject } from 'rxjs';
import { BoreholeWithLocation, MiningLocationWithLocation } from '../interfaces/location.interface';

@Injectable({
  providedIn: 'root',
})
export class ProModusService {
  public isEnabledSubject = new BehaviorSubject<boolean>(false);
  public mouseTool = new BehaviorSubject<MouseTool | null>(null);

  public selectedLocations = new BehaviorSubject<(BoreholeWithLocation | MiningLocationWithLocation)[]>([]);

  constructor() {}

  get isEnabled() {
    return this.isEnabledSubject.value;
  }

  public enable(): void {
    this.isEnabledSubject.next(true);
  }

  public disable(): void {
    this.isEnabledSubject.next(false);
    this.selectedLocations.next([]);
    this.mouseTool.next(null);
  }

  public toggleLocation(event: BoreholeWithLocation | MiningLocationWithLocation) {
    if (this.locationIsSelected(event)) {
      this.removeLocation(event);
    } else {
      this.addLocation(event);
    }
  }

  public addLocation(location: BoreholeWithLocation | MiningLocationWithLocation) {
    this.selectedLocations.next([location, ...this.selectedLocations.value]);
  }

  public removeLocation(event: BoreholeWithLocation | MiningLocationWithLocation) {
    const newValue = this.selectedLocations.value.filter((location) => {
      return (
        (location.__typename === event.__typename && location.id !== event.id) ||
        location.__typename !== event.__typename
      );
    });
    this.selectedLocations.next(newValue);
  }

  public locationIsSelected(location: BoreholeWithLocation | MiningLocationWithLocation) {
    const selectedIndex = this.selectedLocations.value.findIndex((currentLocation) => {
      return currentLocation.__typename === location.__typename && currentLocation.id === location.id;
    });

    return selectedIndex >= 0;
  }
}
