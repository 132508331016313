<ul *ngIf="!hideTabBar" class="bg-para-oleander nav nav-tabs flex items-stretch space-x-2" [ngClass]="tabbarClass">
  <li *ngFor="let tab of tabs" [class.active]="tab.active">
    <button
      (click)="selectTab(tab)"
      class="hover:text-white transition duration-200 h-16 px-5 flex items-center justify-around"
      [ngClass]="{ 'text-brand-blue': !tab.active, 'bg-brand-blue text-para-oleander': tab.active }"
    >
      <ng-container *ngIf="tab.icon">
        <ion-icon [name]="tab.icon" class="text-2xl"></ion-icon>
      </ng-container>
      <span *ngIf="tab.title" class="ml-2">{{ tab.title }}</span>
    </button>
  </li>
</ul>
<ng-content></ng-content>
