import { Component, Inject, OnInit } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { downloadHelper } from '@helpers/mapping-helper';
import { DownloadService } from '@services/download.service';
import { Apollo } from 'apollo-angular';
import Chart from 'chart.js';
import { ChartData, ChartDataSets, ChartOptions } from 'chart.js';
import { map } from 'rxjs/operators';
import { exportPermeabilityQuery } from 'src/app/graphql/queries/export';
import { permeabilityGraphQuery } from 'src/app/graphql/queries/queries';
import { Borehole } from 'src/app/graphql/types/default';
import { BubbleChartBaseComponent } from '../bubble-chart-base/bubble-chart-base.component';

@Component({
  selector: 'app-permeability-chart',
  templateUrl: './permeability-chart.component.html',
  styleUrls: ['./permeability-chart.component.scss'],
})
export class PermeabilityChartComponent extends BubbleChartBaseComponent implements OnInit {
  private isDownloading = false;

  constructor(
    private apollo: Apollo,
    public dialogRef: MatDialogRef<PermeabilityChartComponent>,
    private downloadService: DownloadService,
    @Inject(MAT_DIALOG_DATA)
    public data: {
      boreholeIds: Array<Borehole['id']>;
    }
  ) {
    super();
  }

  ngOnInit(): void {
    this.fetchBoreholes();

    this.dialogRef.addPanelClass(['w-full', 'max-w-4xl', 'custom-material-styles']);
  }

  public bubbleChartOptions: ChartOptions = {
    ...this.chartOptionsBase,
    tooltips: {
      callbacks: {
        label: function (tooltipItem, data: ChartData) {
          let dataSet: ChartDataSets | undefined = undefined;
          if (data.datasets && tooltipItem.datasetIndex !== undefined) {
            dataSet = data.datasets[tooltipItem.datasetIndex];
          }
          return `(${dataSet?.label}) Permeabiliteit: ${tooltipItem.xLabel} mD Diepte: ${tooltipItem.yLabel} meter`;
        },
      },
    },
    scales: {
      yAxes: [
        {
          scaleLabel: {
            ...this.scaleLabel,
            labelString: 'Diepte in meters',
          },
          ticks: {
            ...this.ticks,
            reverse: true,
            stepSize: 100,
          },
          gridLines: this.gridLines,
        },
      ],
      xAxes: [
        {
          type: 'logarithmic',
          scaleLabel: {
            ...this.scaleLabel,
            labelString: 'Permeabiliteit (mD)',
          },
          ticks: {
            ...this.ticks,
            callback: (...args) => {
              // https://github.com/chartjs/Chart.js/issues/3121#issuecomment-613321095
              const value = (Chart as any).Ticks.formatters.logarithmic.call(this, ...args);
              if (value.length) {
                return Number(value).toLocaleString();
              }
              return value;
            },
          },
          gridLines: {
            ...this.gridLines,
            zeroLineColor: 'rgba(255,0,0,0)',
          },
        },
      ],
    },
  };

  private fetchBoreholes() {
    this.isLoading = true;
    this.apollo
      .query<{ borehole: Borehole[] }>({
        query: permeabilityGraphQuery,
        variables: {
          order: null,
          filters: [
            {
              field: 'id',
              values: this.data.boreholeIds.map((id) => id.toString()),
            },
          ],
        },
      })
      .pipe(map((response) => response.data.borehole))
      .subscribe((boreholes: Borehole[]) => {
        this.setChartData(boreholes);
        this.isLoading = false;
      });
  }

  private setChartData(boreholes: Borehole[]) {
    const chartData = boreholes
      .map((borehole) => {
        if (borehole.poroperms && borehole.poroperms.length > 0) {
          const color = borehole.boorgatcode ? this.getColor(borehole.boorgatcode) : 'red';
          return {
            label: borehole.boorgatcode ?? '',
            backgroundColor: color,
            borderColor: color,
            radius: 6,
            data: borehole.poroperms
              .filter((poroperm) => {
                return !!poroperm && poroperm?.hor_permeability !== null && poroperm?.depth !== null;
              })
              .map((poroperm) => {
                return {
                  x: poroperm?.hor_permeability ? poroperm.hor_permeability : 0,
                  y: poroperm?.depth ? parseFloat(poroperm.depth) : 0,
                };
              }),
          };
        } else {
          return null;
        }
      })
      .filter((chartDataSet: ChartDataSets | null) => {
        return chartDataSet;
      }) as ChartDataSets[];

    this.chartData = chartData;
    this.chartHasData = chartData && chartData.length > 0;
  }

  doExport(): void {
    this.isDownloading = true;
    this.apollo
      .query({
        query: exportPermeabilityQuery,
        fetchPolicy: 'no-cache',
        variables: {
          permeabilityDownloadable: {
            borehole_ids: this.data.boreholeIds,
          },
        },
      })
      .subscribe((result) => {
        if (result.data) {
          console.log((<any>result.data).export);
          this.downloadService.downloadExport((<any>result.data).export).subscribe((res) => {
            downloadHelper(res, (<any>result.data).export);
            this.isDownloading = false;
          });
        }
      });
  }
}
