import { ComponentFactoryResolver, Injectable } from '@angular/core';
import { Apollo } from 'apollo-angular';
import { BehaviorSubject } from 'rxjs';
import { isLoggedIn } from '../graphql/queries/auth.query';
import { map, tap } from 'rxjs/operators';
import { User } from '../graphql/types/default';

@Injectable({
  providedIn: 'root',
})
export class UserService {
  public isLoggedIn$ = new BehaviorSubject<boolean>(false);
  public currentUser?: User;

  constructor(private apollo: Apollo) {}

  public user() {
    return this.apollo
      .query({
        query: isLoggedIn,
      })
      .pipe(
        tap(
          ({
            data: {
              isLoggedIn: { loggedIn, user },
            },
          }: any) => {
            if (loggedIn) {
              this.isLoggedIn$.next(true);
              this.currentUser = user;
            } else {
              this.isLoggedIn$.next(false);
            }
          }
        )
      );
  }

  get getCurrentUser() {
    return this.currentUser;
  }

  get getIsLoggedIn() {
    return this.isLoggedIn$.value;
  }
}
