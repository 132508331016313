<div style="height: 670px" class="modal_body">
  <div *ngIf="!miningLocation" class="flex items-center justify-around p-10">
    <mat-spinner class="custom-material-styles" [diameter]="20"></mat-spinner>
  </div>
  <ng-container *ngIf="miningLocation">
    <div class="p-8 pb-0 mb-4">
      <div class="flex items-center">
        <img src="/assets/svg/mining-location.svg" class="w-6 mr-3" />
        <h2 class="text-3xl heading">{{ miningLocation.mijnbouwwerk_code }}</h2>
      </div>
      <span class="text-brand-blue text-2xl heading">{{ miningLocation.mijnbouwwerk_naam }}</span>
    </div>

    <div class="p-8">
      <div class="mb-5 flex">
        <div class="w-1/2">
          <button class="text-left" (click)="openWaterChartForMiningLocation('water_amount')">
            <div class="font-bold leading-6">Geproduceerd water</div>
            <div class="font-bold">(gemiddeld per maand)</div>
            <div>
              <span class="mr-3">
                <ion-icon name="bar-chart"></ion-icon>
              </span>
              <ng-container *ngIf="miningLocation.total_water_amount || miningLocation.total_water_amount === 0">
                <span class="text-red font-bold text-xl">{{ miningLocation.total_water_amount.toFixed(0) }}</span>
                <span class="font-bold"> M3</span>
              </ng-container>
              <span
                *ngIf="!miningLocation.total_water_amount && miningLocation.total_water_amount !== 0"
                class="font-bold text-xl"
                >-</span
              >
            </div>
          </button>
        </div>
        <div class="w-1/2">
          <button class="text-left" (click)="openWaterChartForMiningLocation('injected_water_amount')">
            <div class="font-bold">Geinjecteerd water</div>
            <div class="font-bold">(gemiddeld per maand)</div>
            <div>
              <span class="mr-3">
                <ion-icon name="bar-chart"></ion-icon>
              </span>
              <ng-container
                *ngIf="miningLocation.total_injected_water_amount || miningLocation.total_injected_water_amount === 0"
              >
                <span class="text-brand-blue font-bold text-xl">
                  {{ miningLocation.total_injected_water_amount.toFixed(0) }}
                </span>
                <span class="font-bold"> M3</span>
              </ng-container>
              <span
                *ngIf="!miningLocation.total_injected_water_amount && miningLocation.total_injected_water_amount !== 0"
                class="font-bold text-xl"
                >-</span
              >
            </div>
          </button>
        </div>
      </div>

      <div class="mb-5 flex">
        <div class="w-1/2">
          <div class="font-bold leading-6">Aanvrager naam</div>
          <div>
            {{ miningLocation.sde && miningLocation.sde.aanvrager_naam ? miningLocation.sde.aanvrager_naam : '-' }}
          </div>
        </div>
        <div class="w-1/2">
          <div class="font-bold">Sde number</div>
          <div>{{ miningLocation.sde && miningLocation.sde.referentie ? miningLocation.sde.referentie : '-' }}</div>
        </div>
      </div>

      <div class="mb-5 flex">
        <div class="w-1/2">
          <div class="font-bold leading-6">Gemiddeld vermogen (MW)</div>
          <div *ngIf="vermogen || vermogen === 0">{{ vermogen.toFixed(1) }}</div>
          <div *ngIf="!vermogen && vermogen !== 0">-</div>
        </div>
        <div class="w-1/2">
          <div class="font-bold">Sde round</div>
          <div>{{ miningLocation.sde && miningLocation.sde.sde_ronde ? miningLocation.sde.sde_ronde : '-' }}</div>
        </div>
      </div>

      <div class="mb-5 flex">
        <div class="w-1/2">
          <div class="font-bold leading-6">Beschikbare productie per jaar (MWh)</div>
          <div *ngIf="beschikbareProductiePerJaar || beschikbareProductiePerJaar === 0">
            {{ beschikbareProductiePerJaar }}
          </div>
          <div *ngIf="!beschikbareProductiePerJaar && beschikbareProductiePerJaar !== 0">-</div>
        </div>
        <div class="w-1/2">
          <div class="font-bold">Looptijd (jaar)</div>
          <div>{{ miningLocation.sde && miningLocation.sde.looptijd ? miningLocation.sde.looptijd : '-' }}</div>
        </div>
      </div>

      <div class="mb-5 flex">
        <div class="w-1/2">
          <div class="font-bold leading-6">Maximale subsidie</div>
          <div *ngIf="maximaleSubsidie">
            {{ maximaleSubsidie | currency : 'EUR' : 'symbol' : '0.0-0' : 'nl-NL' }}
          </div>
          <div *ngIf="!maximaleSubsidie">-</div>
        </div>
        <div class="w-1/2">
          <div class="font-bold">SDE scenarios</div>
          <div><button class="link" (click)="openSdeScenarios()">Bekijk de scenarios</button></div>
        </div>
      </div>
    </div>

    <app-tabs tabbarClass="px-8">
      <app-tab icon="ellipse">
        <app-related-mine-boreholes [miningLocation]="miningLocation"></app-related-mine-boreholes>
      </app-tab>
      <app-tab icon="information-circle">
        <div class="p-8 flex flex-col">
          <app-information [miningLocation]="miningLocation"></app-information>
        </div>
      </app-tab>
    </app-tabs>
  </ng-container>
</div>
<div class="flex space-x-4 justify-items-stretch p-8">
  <button class="btn bg-white hover:bg-gray-100 text-black min-w-40" mat-button mat-dialog-close>Sluiten</button>
  <button class="btn min-w-40" (click)="doExport()">Export</button>
</div>
