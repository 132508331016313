import { Component, Input, OnInit } from '@angular/core';
import { Borehole } from 'src/app/graphql/types/default';

@Component({
  selector: 'app-technical',
  templateUrl: './technical.component.html',
  styleUrls: ['./technical.component.scss'],
})
export class TechnicalComponent {
  @Input() borehole!: Borehole;
  constructor() {}
}
