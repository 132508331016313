<div class="px-8 flex flex-col flex-1">
  <div class="flex-1 flex flex-col overflow-hidden">
    <div class="flex-1 overflow-hidden h-1/2 py-2 relative">
      <div class="overflow-y-auto h-full">
        <cdk-virtual-scroll-viewport itemSize="30" class="h-full">
          <div *cdkVirtualFor="let location of locations" class="flex items-center bg-white py-2 px-3 pr-4">
            <ng-container *ngIf="location.__typename === 'MiningLocation'">
              <img src="/assets/svg/mining-location.svg" class="w-6 mr-3" />
              <div class="flex-1">
                {{ location.mijnbouwwerk_naam }}
              </div>
              <input
                type="checkbox"
                [value]="location.mijnbouwwerk_naam"
                [checked]="getLocationIsChecked(location)"
                (change)="proModusService.toggleLocation(location)"
              />
            </ng-container>

            <ng-container *ngIf="location.__typename === 'Borehole'">
              <img *ngIf="!location.isGeothermal" src="/assets/svg/borehole.svg" class="w-6 mr-3" />
              <img *ngIf="location.isGeothermal" src="/assets/svg/geothermal-borehole.svg" class="w-6 mr-3" />
              <div class="flex-1">
                {{ location.boorgatnaam }}
              </div>
              <input
                type="checkbox"
                [value]="location.boorgatcode"
                (change)="proModusService.toggleLocation(location)"
                [checked]="getLocationIsChecked(location)"
              />
            </ng-container>
          </div>
        </cdk-virtual-scroll-viewport>
      </div>
      <div
        *ngIf="boreholeDataService.isLoading.value"
        class="absolute inset-0 bg-white flex items-center justify-around"
      >
        <mat-spinner class="custom-material-styles" [diameter]="20"></mat-spinner>
      </div>
    </div>
    <div class="border-t border-gray-300 pt-3 pb-1 py-1 font-bold">
      Geselecteerde (geothermische) boringen/installaties
    </div>
    <div class="flex-1 overflow-auto h-1/2 py-2">
      <cdk-virtual-scroll-viewport itemSize="30" class="h-full" *ngIf="selectedLocations && selectedLocations.length">
        <div
          *cdkVirtualFor="let location of proModusService.selectedLocations.value"
          class="flex items-center bg-white py-2 px-3 pr-4"
        >
          <ng-container *ngIf="location.__typename === 'MiningLocation'">
            <img src="/assets/svg/mining-location.svg" class="w-6 mr-3" />
            <div class="flex-1">
              {{ location.mijnbouwwerk_naam }}
            </div>
            <button (click)="proModusService.removeLocation(location)" type="button">
              <input type="checkbox" [checked]="true" [disabled]="true" class="pointer-events-none" />
            </button>
          </ng-container>

          <ng-container *ngIf="location.__typename === 'Borehole'">
            <img *ngIf="!location.isGeothermal" src="/assets/svg/borehole.svg" class="w-6 mr-3" />
            <img *ngIf="location.isGeothermal" src="/assets/svg/geothermal-borehole.svg" class="w-6 mr-3" />
            <div class="flex-1">
              {{ location.boorgatnaam }}
            </div>
            <button (click)="proModusService.removeLocation(location)" type="button">
              <input type="checkbox" [checked]="true" [disabled]="true" class="pointer-events-none" />
            </button>
          </ng-container>
        </div>
      </cdk-virtual-scroll-viewport>
      <div
        *ngIf="!proModusService.selectedLocations.value || !proModusService.selectedLocations.value.length"
        class="text-gray-400 py-2 px-3"
      >
        Selecteer
      </div>
    </div>
  </div>

  <div class="mt-4">
    <button class="btn w-1/2" (click)="doExport()">Export</button>
  </div>
</div>
