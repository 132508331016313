import { Component, ViewChild } from '@angular/core';
import { ChartDataSets, ChartOptions } from 'chart.js';
import { BaseChartDirective } from 'ng2-charts';

@Component({
  selector: 'app-bubble-chart-base',
  template: ``,
  styles: [],
})
export class BubbleChartBaseComponent {
  @ViewChild(BaseChartDirective) chart: BaseChartDirective | undefined;
  public fontFamily =
    '"IBM Plex Mono", ui-monospace, SFMono-Regular, Menlo, Monaco, Consolas, "Liberation Mono", "Courier New", monospace';

  public chartData: ChartDataSets[] = [];
  public isLoading = false;
  public chartHasData = false;

  public chartOptionsBase: ChartOptions = {
    legend: {
      display: true,
      position: 'right',
      align: 'start',
      labels: {
        usePointStyle: true,
        boxWidth: 8,
        padding: 20,
      },
      onClick: () => {},
    },
    maintainAspectRatio: false,
  };

  public scaleLabel = {
    display: true,
    fontColor: 'black',
    fontStyle: 'bold',
    fontFamily: this.fontFamily,
    padding: 10,
  };

  public ticks = {
    fontColor: 'black',
    fontFamily: this.fontFamily,
    padding: 5,
    min: 0,
  };

  public gridLines = {
    color: 'rgba(0,0,0,0.3)',
    zeroLineColor: 'rgba(0,0,0,0.3)',
    drawBorder: false,
  };

  public getColor(str: string) {
    var hash = 0;
    for (var i = 0; i < str.length; i++) {
      hash = str.charCodeAt(i) + ((hash << 5) - hash);
    }
    var colour = '#';
    for (var i = 0; i < 3; i++) {
      var value = (hash >> (i * 8)) & 0xff;
      colour += ('00' + value.toString(16)).substr(-2);
    }
    return colour;
  }
}
