import { Component, Inject, OnInit } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { Apollo } from 'apollo-angular';
import { MiningLocation, ScenarioInformation, Sde } from 'src/app/graphql/types/default';
import { map } from 'rxjs/operators';
import { exportSdeScenarioQuery } from 'src/app/graphql/queries/export';
import { downloadHelper } from '@helpers/mapping-helper';
import { DownloadService } from '@services/download.service';
import { sdeScenarioInformations } from 'src/app/graphql/queries/sde';
import { FormControl } from '@angular/forms';

@Component({
  selector: 'app-scenario-information',
  templateUrl: './scenario-information.component.html',
  styleUrls: ['./scenario-information.component.scss'],
})
export class ScenarioInformationComponent implements OnInit {
  public scenarioInformations!: ScenarioInformation[];
  public isDownloading!: boolean;
  public selectedScenarioId = new FormControl([null]);
  public selectedScenario: ScenarioInformation | null = null;

  constructor(
    private apollo: Apollo,
    public dialogRef: MatDialogRef<ScenarioInformationComponent>,
    @Inject(MAT_DIALOG_DATA)
    public data: {
      miningLocation: Omit<MiningLocation, 'sde'> & { sde: Sde };
    },
    private downloadService: DownloadService
  ) {}

  ngOnInit(): void {
    this.fetchGeoThermalHeats();
    this.listenToSelectedScenario();
  }

  private fetchGeoThermalHeats(): void {
    this.apollo
      .query<{ sde: Sde[] }>({
        query: sdeScenarioInformations,
        variables: {
          order: null,
          filters: [
            {
              field: 'id',
              values: [this.data.miningLocation.sde.id.toString()],
            },
          ],
        },
      })
      .pipe(map((response) => response.data.sde[0].scenarioInformations as ScenarioInformation[]))
      .subscribe((scenarioInformations: ScenarioInformation[]) => {
        this.scenarioInformations = scenarioInformations;
        this.selectedScenario = scenarioInformations[0];
        this.selectedScenarioId.setValue(scenarioInformations[0].id);
      });
  }

  private listenToSelectedScenario() {
    this.selectedScenarioId.valueChanges.subscribe((value) => {
      this.selectedScenario =
        this.scenarioInformations.find((scenario) => {
          return scenario.id === value;
        }) ?? null;
    });
  }

  doExport(): void {
    this.isDownloading = true;
    this.apollo
      .query({
        query: exportSdeScenarioQuery,
        fetchPolicy: 'no-cache',
        variables: {
          sdeScenarioDownloadable: {
            filters: [
              {
                field: 'gf_sde_nr',
                values: [this.data.miningLocation.sde.referentie],
              },
            ],
          },
        },
      })
      .subscribe((result) => {
        if (result.data) {
          if ((<any>result.data).export === null) {
            this.isDownloading = false;
            alert('Er is geen data om te exporteren');
            return;
          }
          this.downloadService.downloadExport((<any>result.data).export).subscribe((res) => {
            downloadHelper(res, (<any>result.data).export);
            this.isDownloading = false;
          });
        }
      });
  }
}
