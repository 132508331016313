import { Component, Inject, OnInit } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { downloadHelper } from '@helpers/mapping-helper';
import { DownloadService } from '@services/download.service';
import { Apollo } from 'apollo-angular';
import { ChartData, ChartDataSets, ChartOptions } from 'chart.js';
import { map } from 'rxjs/operators';
import { exportCorrectedTemperatureQuery, exportTemperatureQuery } from 'src/app/graphql/queries/export';
import { temperatureGraphQuery } from 'src/app/graphql/queries/queries';
import { Borehole } from 'src/app/graphql/types/default';
import { BubbleChartBaseComponent } from '../bubble-chart-base/bubble-chart-base.component';

@Component({
  selector: 'app-temperature-chart',
  templateUrl: './temperature-chart.component.html',
  styleUrls: ['./temperature-chart.component.scss'],
})
export class TemperatureChartComponent extends BubbleChartBaseComponent implements OnInit {
  private isDownloading = false;

  constructor(
    private apollo: Apollo,
    public dialogRef: MatDialogRef<TemperatureChartComponent>,
    private downloadService: DownloadService,
    @Inject(MAT_DIALOG_DATA)
    public data: {
      boreholeIds: Array<Borehole['id']>;
    }
  ) {
    super();
  }

  ngOnInit(): void {
    this.fetchBoreholes();

    this.dialogRef.addPanelClass(['w-full', 'max-w-4xl', 'custom-material-styles']);
  }

  public bubbleChartOptions: ChartOptions = {
    ...this.chartOptionsBase,
    tooltips: {
      callbacks: {
        label: function (tooltipItem, data: ChartData) {
          let dataSet: ChartDataSets | undefined = undefined;
          if (data.datasets && tooltipItem.datasetIndex !== undefined) {
            dataSet = data.datasets[tooltipItem.datasetIndex];
          }
          return `(${dataSet?.label}) Temperatuur: ${tooltipItem.xLabel} °C Diepte: ${tooltipItem.yLabel} meter`;
        },
      },
    },
    scales: {
      yAxes: [
        {
          scaleLabel: {
            ...this.scaleLabel,
            labelString: 'Diepte in meters',
          },
          ticks: {
            ...this.ticks,
            reverse: true,
            stepSize: 100,
          },
          gridLines: this.gridLines,
        },
      ],
      xAxes: [
        {
          scaleLabel: {
            ...this.scaleLabel,
            labelString: 'Temperatuur in °C',
          },
          ticks: {
            ...this.ticks,
            stepSize: 10,
            callback: (value: number) => {
              return `${value} °C`;
            },
          },
          gridLines: {
            ...this.gridLines,
            zeroLineColor: 'rgba(255,0,0,0)',
          },
        },
      ],
    },
  };

  private fetchBoreholes() {
    this.isLoading = true;
    this.apollo
      .query<{ borehole: Borehole[] }>({
        query: temperatureGraphQuery,
        variables: {
          order: null,
          filters: [
            {
              field: 'id',
              values: this.data.boreholeIds.map((id) => id.toString()),
            },
          ],
        },
      })
      .pipe(map((response) => response.data.borehole))
      .subscribe((boreholes: Borehole[]) => {
        this.setChartData(boreholes);
        this.isLoading = false;
      });
  }

  private setChartData(boreholes: Borehole[]) {
    const chartData = boreholes
      .filter((borehole) => {
        return borehole.corrected_temperatures && borehole.corrected_temperatures.length > 0;
      })
      .map((borehole) => {
        if (borehole.corrected_temperatures && borehole.corrected_temperatures.length > 0) {
          const color = borehole.boorgatcode ? this.getColor(borehole.boorgatcode) : 'red';
          return {
            label: borehole.boorgatcode ?? '',
            backgroundColor: color,
            borderColor: color,
            radius: 6,
            data: borehole.corrected_temperatures
              .filter((poroperm) => {
                return !!poroperm && poroperm?.temperature !== null && poroperm?.z !== null;
              })
              .map((poroperm) => {
                return {
                  x: poroperm?.temperature ? poroperm.temperature : 0,
                  y: poroperm?.z ? parseFloat(poroperm.z) : 0,
                };
              }),
          };
        } else {
          return null;
        }
      })
      .filter((chartDataSet: ChartDataSets | null) => {
        return chartDataSet;
      }) as ChartDataSets[];

    this.chartData = chartData;
    this.chartHasData = chartData && chartData.length > 0;
  }

  doExport(): void {
    this.isDownloading = true;
    this.apollo
      .query({
        query: exportCorrectedTemperatureQuery,
        fetchPolicy: 'no-cache',
        variables: {
          correctedTemperatureDownloadable: {
            borehole_ids: this.data.boreholeIds,
          },
        },
      })
      .subscribe((result) => {
        if (result.data) {
          this.downloadService.downloadExport((<any>result.data).export).subscribe((res) => {
            downloadHelper(res, (<any>result.data).export);
            this.isDownloading = false;
          });
        }
      });
  }
}
