<div class="flex w-full flex-col bg-white modal_body" style="height: calc(95vh - 78px)">
  <span class="text-3xl font-bold p-8">Porositeit (%)</span>
  <div class="flex items-stretch flex-1">
    <div *ngIf="isLoading" class="flex items-center justify-around p-10 w-full h-full flex-1">
      <mat-spinner class="custom-material-styles" [diameter]="20"></mat-spinner>
    </div>
    <div *ngIf="!isLoading" class="pr-4 pl-2 flex-1 relative">
      <canvas baseChart [datasets]="chartData" [options]="bubbleChartOptions" [legend]="true" chartType="bubble">
      </canvas>
      <div *ngIf="!chartHasData" class="absolute inset-0 bg-white flex items-center justify-around">
        Gekozen boreholes hebben geen porositeit data
      </div>
    </div>
  </div>
</div>
<div class="flex space-x-4 justify-items-stretch p-8 bg-white">
  <button class="btn bg-black hover:bg-gray-100 text-white min-w-40" mat-button mat-dialog-close>Sluiten</button>
  <button class="btn min-w-40" (click)="doExport()" mat-button>Export</button>
</div>
