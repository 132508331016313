import { Component, OnInit } from '@angular/core';
import { ProModusService } from '@services/pro-modus.service';
import { GoogleMapsService } from '@services/google-maps.service';

@Component({
  selector: 'app-map-legend',
  templateUrl: './map-legend.component.html',
  styleUrls: ['./map-legend.component.scss'],
})
export class MapLegendComponent implements OnInit {
  public hideOptionMenu: boolean = false;

  constructor(public proModusService: ProModusService, public googleMapsService: GoogleMapsService) {}

  ngOnInit() {
    this.toggleMenu();
  }

  public toggleMenu(): void {
    this.hideOptionMenu = !this.hideOptionMenu;
  }
}
