import { Component, Input } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { WaterAmountChartComponent } from '@components/water-amount-chart/water-amount-chart.component';
import { Borehole, HoleFunction, MiningLocation } from 'src/app/graphql/types/default';
import { BoreholeDetailsComponent } from '../../borehole-details/borehole-details.component';

@Component({
  selector: 'app-related-mine-boreholes',
  templateUrl: './related-mine-boreholes.component.html',
  styleUrls: ['./related-mine-boreholes.component.scss'],
})
export class RelatedMineBoreholesComponent {
  @Input() miningLocation!: MiningLocation;

  constructor(private dialogService: MatDialog) {}

  getBoorgatcode(boorgatcode: string | null | undefined): number {
    if (!boorgatcode) {
      return 0;
    }
    const split = boorgatcode.split('-');
    if (split[split.length - 1].includes('S')) {
      return Number(split[split.length - 2]);
    }

    return Number(split[split.length - 1]);
  }

  get sortBoreholesBySiteTrack(): Borehole[] {
    let boreholes: Borehole[] = [];
    this.miningLocation.relatedBoreholes?.forEach((borehole) => {
      boreholes.push(borehole);
    });

    boreholes.sort((a, b) => this.getBoorgatcode(a.boorgatcode) - this.getBoorgatcode(b.boorgatcode));
    return boreholes;
  }

  public isSideTrack(boorgatcode: string | null | undefined): boolean {
    if (!boorgatcode) {
      return false;
    }
    const split = boorgatcode.split('-');
    return split[split.length - 1].includes('S');
  }

  public handleBoreholeClick(boreholeId: Borehole['id']): void {
    this.dialogService.open(BoreholeDetailsComponent, {
      data: { id: boreholeId },
      panelClass: 'custom-material-styles',
      backdropClass: ['bg-black', 'bg-opacity-70'],
      width: '545px',
      autoFocus: false,
    });
  }

  public openWaterChartForBorehole(borehole: Borehole): void {
    if (!borehole.hole_function) {
      window.alert('Borehole heeft geen productie of injectie data');
      return;
    }

    const isProduction = borehole.hole_function === HoleFunction.Production;
    const heading = isProduction ? 'Hoeveelheid geproduceerd water' : 'Hoeveelheid geïnjecteerd water';
    this.dialogService.open(WaterAmountChartComponent, {
      data: {
        id: borehole.id,
        miningLocationCode: borehole.put_naam,
        data_column: isProduction ? 'water_amount' : 'injected_water_amount',
        heading,
        forType: 'Borehole',
      },
      panelClass: ['custom-material-styles', 'bg-white'],
      width: '630px',
      autoFocus: false,
    });
  }
}
