<div *ngIf="miningLocation && miningLocation.content">
  <span class="font-bold text-2xl text-brand-blue mb-2">{{ miningLocation.content.title }}</span>
  <p class="font-bold my-2">{{ miningLocation.content.header }}</p>
  <p class="my-2">{{ miningLocation.content.body }}</p>

  <div
    *ngIf="miningLocation.content.images && miningLocation.content.images.length > 0"
    class="overflow-auto w-full block mt-4"
  >
    <div class="flex h-20 items-stretch content-images space-x-4">
      <div *ngFor="let uri of miningLocation.content.images" class="h-full">
        <img [src]="uri" class="max-h-full" />
      </div>
    </div>
  </div>

  <ul *ngIf="miningLocation.content.images && miningLocation.content.images.length > 0" class="mt-4">
    <li *ngFor="let link of miningLocation.content.links">
      <a [href]="link?.uri" class="link" target="_blank">{{ link?.title }}</a>
    </li>
  </ul>
</div>
<div *ngIf="!miningLocation || !miningLocation.content">
  <p>Geen extra informatie beschikbaar</p>
</div>
