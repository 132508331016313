import { Component, Input } from '@angular/core';

@Component({
  selector: 'app-tab',
  templateUrl: './tab.component.html',
  styleUrls: ['./tab.component.scss'],
})
export class TabComponent {
  @Input() title?: string;
  @Input() icon?: string;
  @Input() containerClass?: string = '';

  @Input() active = false;

  constructor() {}
}
