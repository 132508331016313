<div class="bg-white pointer-events-auto flex flex-col ml-4 max-h-full overflow-auto py-8" style="width: 300px">
  <div class="flex justify-between items-center px-8 pb-4">
    <span class="font-bold">Selectie mogelijkheden</span>
    <button type="button" (click)="isMinimalized.next(!isMinimalized.value)" class="p-2">
      <div class="border-b-2 border-white w-2 h-2 block"></div>
    </button>
  </div>

  <div *ngIf="isMinimalized.value" class="flex-1 overflow-auto">
    <div class="max-h-full overflow-auto">
      <div class="px-8 mt-2">
        <div class="flex">
          <button
            (click)="setMouseTool(mouseToolEnum.POINTER)"
            class="bg-gray-300 text-white transition duration-300 ease-in-out h-12 w-12 flex items-center justify-around"
            [ngClass]="{
              'bg-gray-300': proModusService.mouseTool.value !== mouseToolEnum.POINTER,
              'bg-brand-blue': proModusService.mouseTool.value === mouseToolEnum.POINTER
            }"
          >
            <img src="/assets/svg/pointer.svg" alt="Pointer" class="w-3" />
          </button>
          <button
            (click)="setMouseTool(mouseToolEnum.LASSO)"
            class="bg-gray-300 text-white transition duration-300 ease-in-out h-12 w-12 flex items-center justify-around"
            [ngClass]="{
              'bg-gray-300': proModusService.mouseTool.value !== mouseToolEnum.LASSO,
              'bg-brand-blue': proModusService.mouseTool.value === mouseToolEnum.LASSO
            }"
          >
            <img src="/assets/svg/lasso.svg" alt="Lasso" class="w-3" />
          </button>

          <button
            (click)="setMouseTool(mouseToolEnum.RECTANGLE)"
            class="bg-gray-300 text-white transition duration-300 ease-in-out h-12 w-12 flex items-center justify-around"
            [ngClass]="{
              'bg-gray-300': proModusService.mouseTool.value !== mouseToolEnum.RECTANGLE,
              'bg-brand-blue': proModusService.mouseTool.value === mouseToolEnum.RECTANGLE
            }"
          >
            <img src="/assets/svg/rectangle.svg" alt="Lasso" class="w-3" />
          </button>

          <button
            (click)="removeLassoShapes()"
            *ngIf="
              proModusService.mouseTool.value === mouseToolEnum.LASSO ||
              proModusService.mouseTool.value === mouseToolEnum.RECTANGLE
            "
            class="bg-red-600 hover:bg-red-700 text-white transition duration-300 ease-in-out h-12 w-12 flex items-center justify-around ml-3"
          >
            <ion-icon name="trash" class="text-white"></ion-icon>
          </button>
        </div>
      </div>
    </div>
  </div>
</div>
