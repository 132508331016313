import { HttpClient, HttpHeaders } from '@angular/common/http';

import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { environment } from '@environments/environment';

@Injectable({
  providedIn: 'root',
})
export class DownloadService {
  constructor(private http: HttpClient) {}

  public downloadExport(file: string): Observable<any> {
    return this.http.get(`${environment.base}download/${file}`, {
      responseType: 'blob',
    });
  }
}
