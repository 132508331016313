import { Component, EventEmitter, Host, Input, Output } from '@angular/core';

@Component({
  selector: 'app-graph-button',
  templateUrl: './graph-button.component.html',
  styleUrls: ['./graph-button.component.scss'],
})
export class GraphButtonComponent {
  @Input() title!: string;
  @Input() selectedCount!: number;

  constructor() {}
}
