import { gql } from 'apollo-angular';

export const boreholeListFragment = gql`
  fragment boreholeListFragment on Borehole {
    __typename
    id
    boorgatdoel
    mijnbouwwerk_code
    boorgatcode
    boorgatnaam
    nITG_nummer
    longitude_ED50
    latitude_ED50
    isGeothermal
  }
`;

export const boreholeDetailFragment = gql`
  fragment boreholeDetailFragment on Borehole {
    id
    mijnbouwwerk_naam
    mijnbouwwerk_code
    startdatum
    einddatum
    uWI
    nITG_nummer
    boorgatcode
    boorgatnaam
    boorgatdoel_code
    huidige_eigenaar
    opdrachtgever
    provincie_naam
    provincie_code
    gemeente_naam
    gemeente_id
    postcode
    longitude_ED50
    latitude_ED50
    x_rijksdriehoek
    y_rijksdriehoek
    kickoff_diepte_ah
    boorgatdiepte_AH
    boorgatdiepte_TVD
    hoogte_referentievlak
    tov
    boorgatdiepte_AH_tov_NAP
    boorgatdiepte_TVD_tov_NAP
    boortoren
    boorfirma
    openbaar_vanaf
    boorgatdoel
    boorgatresultaat
    boorgatresultaat_code
    boorgatstatus
    boorgatvorm
    lithostratigrafie_code
    chronotratigrafie_code
    veld_code
    veld_naam
    isGeothermal
  }
`;
