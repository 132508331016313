import { gql } from 'apollo-angular';

export const LoginMutation = gql`
  mutation loginMutation($email: String!, $password: String!) {
    logIn(email: $email, password: $password) {
      api_token
      message
      success
    }
  }
`;
