export interface PossibleTypesResultData {
  possibleTypes: {
    [key: string]: string[];
  };
}
const result: PossibleTypesResultData = {
  possibleTypes: {
    Location: ['Borehole', 'MiningLocation'],
  },
};
export default result;
