import { Injectable } from '@angular/core';
import { AbstractControl, FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';
import { Apollo, QueryRef } from 'apollo-angular';
import { BehaviorSubject, interval, Subscription } from 'rxjs';
import { debounce, map } from 'rxjs/operators';
import { locationQuery } from '../graphql/queries/location';
import { Borehole, BoreholesTypes, MiningLocation } from '../graphql/types/default';
import { BoreholeWithLocation, MiningLocationWithLocation } from '../interfaces/location.interface';
import { ProModusService } from './pro-modus.service';

@Injectable({
  providedIn: 'root',
})
export class BoreholeDataService {
  public locations = new BehaviorSubject<(BoreholeWithLocation | MiningLocationWithLocation)[] | null>(null);
  public isLoading = new BehaviorSubject<boolean>(false);
  public query!: QueryRef<{ location: (Borehole | MiningLocation)[] }>;
  private initialProModusEvent = false;
  private initialFilterValues!: { [key: string]: any };

  public filterTypes = [
    { description: 'Overige diepe boringen', value: BoreholesTypes.Borehole },
    { description: 'Geothermie boringen', value: BoreholesTypes.GeothermalBorehole },
    { description: 'Geothermische installaties', value: BoreholesTypes.Facilities },
  ];

  public minMaxScenarioInformationRanges = {
    prodtopreservoirdepthlikely: {
      min: 0,
      max: 10000,
    },
    permeabilitylikely: {
      min: 0,
      max: 10000,
    },
    thicknesslikely: {
      min: 0,
      max: 500,
    },
    p50_aquifer_k_h_net_dm: {
      min: 0,
      max: 5000000,
    },
    p50_pump_volume_flow_m3_h: {
      min: 0,
      max: 1000,
    },
    p50_geothermal_power_mw: {
      min: 0,
      max: 100,
    },
    p50_aquifer_pressure_at_producer_bar: {
      min: 0,
      max: 1000,
    },
    p50_pressure_difference_at_injector_bar: {
      min: 0,
      max: 200,
    },
    p50_aquifer_temperature_at_producer_c: {
      min: 0,
      max: 300,
    },
    p50_temperature_at_heat_exchanger_c: {
      min: 0,
      max: 300,
    },
  };

  public searchQuery = new FormControl('');
  public typeControl = new FormControl('');
  public filtersForm!: FormGroup;

  private lastFetchSubscription: Subscription | undefined = undefined;

  constructor(private apollo: Apollo, private formBuilder: FormBuilder, private proModusService: ProModusService) {
    this.initForm();
    this.fetchData();

    this.locations.subscribe(() => {
      this.isLoading.next(false);
    });

    this.subscribeToProModus();
    this.subscribeToTypeControl();
  }

  public refetch() {
    this.fetchData();
  }

  public getFilterFormControl(type: string): AbstractControl {
    return this.filtersForm.get(type) as AbstractControl;
  }

  private setLocation(boreholes: (Borehole | MiningLocation)[]): (BoreholeWithLocation | MiningLocationWithLocation)[] {
    return boreholes.map((borehole: Borehole | MiningLocation) => {
      let location: google.maps.LatLngLiteral | null = null;
      if (borehole.latitude_ED50 && borehole.longitude_ED50) {
        location = {
          lat: parseFloat(borehole.latitude_ED50),
          lng: parseFloat(borehole.longitude_ED50),
        };
      }

      return {
        ...borehole,
        location,
      };
    });
  }

  private constructQueryVariables() {
    let queryVariables = {
      order: null,
      filters: [
        {
          field: 'on_offshore',
          values: ['ON'],
        },
      ],
      search: {
        fields: ['mijnbouwwerk_code', 'mijnbouwwerk_naam', 'boorgatnaam', 'boorgatcode'],
        query: this.searchQuery.value,
      },
      type: this.typeControl.value || null,
      scenario_information_ranges: [] as { field: string; min: number; max: number }[],
      wheres: [],
    };

    Object.entries(this.filtersForm.value).forEach(([key, value]: [key: string, value: any]) => {
      switch (key) {
        case 'provincie_code':
          if (!value) {
            return;
          }
          queryVariables = this.mapQueryFilterVariables(queryVariables, key, [value]);
          break;

        case 'scenario_informations':
          if (!value) {
            return;
          }

          queryVariables = this.mapQueryScenarioInformationRangeVariables(
            queryVariables,
            this.filtersForm.value.scenario_informations
          );
          break;

        case 'boorgatcode':
        case 'mijnbouwwerk_code':
        case 'mijnbouwwerk_naam':
        case 'sde.aanvrager_naam':
          if (!value) {
            return;
          }
          queryVariables = this.mapQueryWhereVariables(queryVariables, key, `%${value}%`, 'LIKE');
          break;

        case 'boorgatdiepte_TVD+min':
          if (!value) {
            return;
          }
          queryVariables = this.mapQueryWhereVariables(queryVariables, key.split('+')[0], `${value}`, '>');
          break;

        case 'boorgatdiepte_TVD+max':
          if (!value) {
            return;
          }
          queryVariables = this.mapQueryWhereVariables(queryVariables, key.split('+')[0], `${value}`, '<');
          break;

        case 'boorgatstatus':
        case 'sde.scenarioInformations.id':
          if (!value) {
            return;
          }
          queryVariables = this.mapQueryWhereVariables(queryVariables, key, `${value}`, '=');
          break;
      }
    });

    return queryVariables;
  }

  public initForm() {
    const scenarioInformationGroups: { [key: string]: FormGroup } = {};
    Object.entries(this.minMaxScenarioInformationRanges).forEach((entry) => {
      const [key, value] = entry;
      const control = this.formBuilder.group({
        min: [null, [Validators.min(value.min)]],
        max: [null, [Validators.max(value.max)]],
      });
      scenarioInformationGroups[key] = control;

      control.valueChanges.subscribe(() => {
        const minControl = control.get('min') as FormControl;
        const maxControl = control.get('max') as FormControl;
        if (minControl.hasError('min')) {
          minControl.setValue(value.min);
        }
        if (maxControl.hasError('max')) {
          maxControl.setValue(value.max);
        }
      });
    });

    this.filtersForm = this.formBuilder.group({
      type: this.formBuilder.array([]),
      provincie_code: [''],
      boorgatcode: [''],
      'boorgatdiepte_TVD+min': [''],
      'boorgatdiepte_TVD+max': [''],
      boorgatstatus: [null],
      mijnbouwwerk_code: [''],
      mijnbouwwerk_naam: [''],
      'sde.aanvrager_naam': [''],
      'sde.scenarioInformations.facility_nm': [''],
      'sde.scenarioInformations.id': [''],
      sde_ronde: [''],
      scenario_informations: this.formBuilder.group(scenarioInformationGroups),
    });

    this.initialFilterValues = this.filtersForm.value;

    this.filtersForm.valueChanges.pipe(debounce(() => interval(500))).subscribe((value) => {
      this.fetchData();
    });
  }

  private mapQueryVariables(queryVariables: any, key: string, value: any): any {
    return {
      ...queryVariables,
      [key]: value,
    };
  }

  private mapQueryFilterVariables(queryVariables: any, key: string, values: any[]): any {
    return this.mapQueryVariables(queryVariables, 'filters', [
      ...queryVariables.filters,
      {
        field: key,
        values: values,
      },
    ]);
  }

  private mapQueryWhereVariables(
    queryVariables: any,
    key: string,
    value: string,
    operator: '=' | '<' | '>' | '<=' | '>=' | '<>' | '!=' | 'LIKE' | 'NOT LIKE' | 'BETWEEN' | 'ILIKE'
  ): any {
    return this.mapQueryVariables(queryVariables, 'wheres', [
      ...queryVariables.wheres,
      {
        field: key,
        value,
        operator,
      },
    ]);
  }

  private mapQueryScenarioInformationRangeVariables(queryVariables: any, values: { [key: string]: any }): any {
    const scenarioInformations = Object.entries(values)
      .filter((entry) => {
        const [key, value]: [key: string, value: any] = entry;
        return value.min || value.min == 0 || value.max || value.max == 0;
      })
      .map((entry) => {
        const [key, value]: [key: string, value: any] = entry;

        const toReturn = {
          field: key,
          ...value,
        };

        return toReturn;
      }) as { field: string; min: number; max: number }[];

    return {
      ...queryVariables,
      scenario_information_ranges: scenarioInformations,
    };
  }

  private subscribeToProModus() {
    this.proModusService.isEnabledSubject.subscribe((value) => {
      if (!this.initialProModusEvent) {
        // this prevents the method from getting called on init
        this.initialProModusEvent = true;
      } else {
        if (!value) {
          setTimeout(() => {
            this.filtersForm.reset(this.initialFilterValues);
          }, 0);
        }
      }
    });
  }

  private subscribeToTypeControl() {
    this.typeControl.valueChanges.subscribe(() => {
      this.filtersForm.reset(this.initialFilterValues);
    });
  }

  private fetchData() {
    this.isLoading.next(true);
    const variables = this.constructQueryVariables();
    // this prevents the previous HTTP request from overwriting the current data when it takes longer to resolve than the last request
    if (this.lastFetchSubscription) {
      this.lastFetchSubscription.unsubscribe();
    }

    this.lastFetchSubscription = this.apollo
      .query<{ location: (Borehole | MiningLocation)[] }>({
        query: locationQuery,
        variables,
      })
      .pipe(
        map((response) => {
          return response.data ? response.data.location : [];
        }),
        map((locations) => this.setLocation(locations))
      )
      .subscribe((response) => {
        this.isLoading.next(false);
        this.locations.next(response);
      });
  }
}
