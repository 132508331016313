<div class="px-8 pb-8 flex flex-col flex-1">
  <div class="mt-4">
    <span *ngFor="let filterType of boreholeDataService.filterTypes" class="tno-radio mr-4">
      <input
        type="radio"
        name="type"
        [id]="'type_' + filterType.value"
        [value]="filterType.value"
        class="mr-2"
        [formControl]="boreholeDataService.typeControl"
      />
      <label class="inline-flex items-center" [for]="'type_' + filterType.value">
        {{ filterType.description }}
      </label>
    </span>
  </div>
  <div class="mt-8 flex-1 overflow-auto">
    <span *ngIf="results.length === 0" class="text-gray-300">geen resultaten</span>
  </div>
</div>
