import { AfterContentInit, Component, ContentChildren, EventEmitter, Input, Output, QueryList } from '@angular/core';
import { TabComponent } from './tab/tab.component';

@Component({
  selector: 'app-tabs',
  templateUrl: './tabs.component.html',
  styleUrls: ['./tabs.component.scss'],
})
export class TabsComponent implements AfterContentInit {
  @ContentChildren(TabComponent) tabs!: QueryList<TabComponent>;
  @Input() tabbarClass: string = '';
  @Input() hideTabBar: boolean = false;

  /**
   * Emits event with new tab index
   */
  @Output() tabChange = new EventEmitter<number>();

  constructor() {}

  ngAfterContentInit() {
    let activeTabs = this.tabs.filter((tab: TabComponent) => tab.active);

    if (activeTabs.length === 0) {
      this.selectTab(this.tabs.first);
    } else {
      this.tabChange.next(this.tabs.toArray().findIndex((item) => item === activeTabs[0]));
    }
  }

  selectTab(tab: TabComponent) {
    this.tabs.toArray().forEach((tab) => (tab.active = false));

    tab.active = true;

    this.tabChange.next(this.tabs.toArray().findIndex((item) => item === tab));
  }
}
