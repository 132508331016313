import { gql } from 'apollo-angular';

export const isLoggedIn = gql`
  query isLoggedIn {
    isLoggedIn {
      user {
        id
        email
        name
        roles {
          name
          permissions {
            id
            name
          }
        }
        permissions {
          id
          name
        }
      }
      loggedIn
    }
  }
`;
