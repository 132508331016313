import { Injectable } from '@angular/core';
import { MatSnackBar } from '@angular/material/snack-bar';
import { downloadHelper } from '@helpers/mapping-helper';
import { Apollo, gql } from 'apollo-angular';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { exportBoreholeQuery, exportSde, exportSdeScenarioQuery } from '../graphql/queries/export';
import { Borehole, MiningLocation, Sde } from '../graphql/types/default';
import { DownloadService } from './download.service';

@Injectable({
  providedIn: 'root',
})
export class ExportProModusService {
  constructor(private apollo: Apollo, private downloadService: DownloadService, private snackBar: MatSnackBar) {}

  public doBoreholeExport(boreholeIds: Borehole['id'][]): Observable<undefined> {
    return new Observable((subscriber) => {
      this.apollo
        .query({
          query: exportBoreholeQuery,
          fetchPolicy: 'no-cache',
          variables: {
            boreholeDownloadable: {
              filters: [{ field: 'id', values: boreholeIds.map((id) => id.toString()) }],
            },
          },
        })
        .subscribe(
          (result) => {
            if (result.data) {
              this.downloadService.downloadExport((<any>result.data).export).subscribe(
                (res) => {
                  downloadHelper(res, (<any>result.data).export);
                  subscriber.complete();
                },
                () => subscriber.error()
              );
            }
          },
          () => subscriber.error()
        );
    });
  }

  public doSdeExport(miningLocationIds: MiningLocation['id'][]) {
    return new Observable((subscriber) => {
      this.getSdeIds(miningLocationIds).subscribe(
        (sdeIds) => {
          this.apollo
            .query({
              query: exportSde,
              fetchPolicy: 'no-cache',
              variables: {
                sdeDownloadable: {
                  filters: [{ field: 'id', values: [...new Set(sdeIds.map((id) => id.toString()))] }],
                },
              },
            })
            .subscribe(
              (result) => {
                if (result.data) {
                  this.downloadService.downloadExport((<any>result.data).export).subscribe(
                    (res) => {
                      downloadHelper(res, (<any>result.data).export);
                      subscriber.complete();
                    },
                    () => subscriber.error()
                  );
                }
              },
              () => subscriber.error()
            );
        },
        (error) => {
          this.snackBar.open('Geen sde informatie om te downloaden');
          subscriber.error(error);
        }
      );
    });
  }

  public doScenarioExport(miningLocationIds: MiningLocation['id'][]) {
    return new Observable((subscriber) => {
      this.getSdeIds(miningLocationIds).subscribe(
        (sdeIds) => {
          this.apollo
            .query({
              query: exportSdeScenarioQuery,
              fetchPolicy: 'no-cache',
              variables: {
                sdeScenarioDownloadable: {
                  filters: [{ field: 'sde.id', values: [...new Set(sdeIds.map((id) => id.toString()))] }],
                },
              },
            })
            .subscribe(
              (result) => {
                if (result.data) {
                  this.downloadService.downloadExport((<any>result.data).export).subscribe(
                    (res) => {
                      downloadHelper(res, (<any>result.data).export);
                      subscriber.complete();
                    },
                    () => subscriber.error()
                  );
                }
              },
              () => subscriber.error()
            );
        },
        (error) => {
          this.snackBar.open('Geen scenarios om te downloaden');
          subscriber.error(error);
        }
      );
    });
  }

  private getSdeIds(miningLocationIds: MiningLocation['id'][]): Observable<Array<Sde['id']>> {
    return this.apollo
      .query<{ miningLocation: MiningLocation[] }>({
        query: gql`
          query MiningLocation($filters: [filterBy]) {
            miningLocation(filters: $filters) {
              id
              sde {
                id
              }
            }
          }
        `,
        fetchPolicy: 'no-cache',
        variables: { filters: [{ field: 'id', values: miningLocationIds.map((id) => id.toString()) }] },
      })
      .pipe(
        map((response) => {
          const sdeIds = response.data.miningLocation
            .map((miningLocation) => miningLocation?.sde?.id)
            .filter((sdeId) => sdeId) as Array<Sde['id']>;

          if (!sdeIds || sdeIds.length === 0) {
            throw new Error();
          }
          return sdeIds;
        })
      );
  }
}
