<div class="flex items-stretch flex-1 py-0 mt-8 pointer-events-none overflow-hidden">
  <div class="bg-white pointer-events-auto flex flex-col" style="width: 300px">
    <div class="p-8">
      <div class="flex justify-between align-middle">
        <h2 class="text-2xl font-bold">Legenda</h2>
        <div *ngIf="hideOptionMenu" class="cursor-pointer text-2xl" (click)="toggleMenu()">-</div>
        <div *ngIf="!hideOptionMenu" class="cursor-pointer text-2xl" (click)="toggleMenu()">+</div>
      </div>
    </div>
    <div *ngIf="hideOptionMenu" class="px-8 overflow-auto mb-4">
      <ul class="mt-4">
        <li class="flex items-center py-2">
          <img src="/assets/svg/borehole.svg" alt="deep boreholes" class="mr-3" />
          Overige diepe boring
        </li>
        <li class="flex items-center py-2">
          <img src="/assets/svg/geothermal-borehole.svg" alt="deep geothermal boreholes" class="mr-3" />
          Geothermie boring
        </li>
        <li class="flex items-center py-2">
          <img src="/assets/svg/mining-location.svg" alt="geothermal facilities" class="mr-3" />
          Geothermische installatie
        </li>
        <li class="flex items-center py-2">
          <img src="/assets/images/cluster.png" alt="cluster" class="mr-1 w-10 -ml-3 -mt-1 -mb-1" />
          Cluster
        </li>
      </ul>
    </div>
  </div>
</div>
