import { Component, Inject, OnInit } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { MatSnackBar } from '@angular/material/snack-bar';
import { downloadHelper } from '@helpers/mapping-helper';
import { DownloadService } from '@services/download.service';
import { Apollo, gql } from 'apollo-angular';
import { ChartData, ChartDataSets, ChartOptions } from 'chart.js';
import { map } from 'rxjs/operators';
import { exportSdeScenarioQuery } from 'src/app/graphql/queries/export';
import { powerFlowGraphQuery } from 'src/app/graphql/queries/queries';
import { MiningLocation, ScenarioInformation, Sde } from 'src/app/graphql/types/default';
import { BubbleChartBaseComponent } from '../bubble-chart-base/bubble-chart-base.component';

@Component({
  selector: 'app-power-flow-chart',
  templateUrl: './power-flow-chart.component.html',
  styleUrls: ['./power-flow-chart.component.scss'],
})
export class PowerFlowChartComponent extends BubbleChartBaseComponent implements OnInit {
  private isDownloading = false;
  private miningLocations!: MiningLocation[];

  constructor(
    private apollo: Apollo,
    public dialogRef: MatDialogRef<PowerFlowChartComponent>,
    private downloadService: DownloadService,
    private snackBar: MatSnackBar,
    @Inject(MAT_DIALOG_DATA)
    public data: {
      miningLocationIds: Array<MiningLocation['id']>;
    }
  ) {
    super();
  }

  ngOnInit(): void {
    this.fetchMiningLocations();

    this.dialogRef.addPanelClass(['w-full', 'max-w-4xl', 'custom-material-styles']);
  }

  public bubbleChartOptions: ChartOptions = {
    ...this.chartOptionsBase,
    tooltips: {
      callbacks: {
        label: function (tooltipItem, data: ChartData) {
          let dataSet: ChartDataSets | undefined = undefined;
          if (data.datasets && tooltipItem.datasetIndex !== undefined) {
            dataSet = data.datasets[tooltipItem.datasetIndex];
          }
          if (!dataSet) {
            return 'undefined';
          }

          switch (dataSet.pointStyle) {
            case 'circle':
              return `(${dataSet?.label}) Geothermisch vermogen (MW): ${tooltipItem.yLabel} MW - Pomp debiet (m3/h): ${tooltipItem.xLabel} m3/h`;
            case 'rectRot':
              return `(${dataSet?.label}) Vermogen: ${tooltipItem.yLabel} MW - Pomp debiet (m3/h): ${tooltipItem.xLabel} m3/h`;

            default:
              return '';
          }
        },
      },
    },
    scales: {
      yAxes: [
        {
          scaleLabel: {
            ...this.scaleLabel,
            labelString: 'Geothermisch Vermogen (MW)',
          },
          id: 'p50_geothermal_power_mw',
          ticks: {
            ...this.ticks,
            suggestedMin: 0,
            suggestedMax: 20,
            stepSize: 2,
          },
          gridLines: this.gridLines,
        },
      ],
      xAxes: [
        {
          scaleLabel: {
            ...this.scaleLabel,
            labelString: 'Pomp debiet (m3/h)',
          },
          ticks: {
            ...this.ticks,
            suggestedMin: 1500,
            suggestedMax: 300,
            stepSize: 50,
            min: undefined,
          },
          gridLines: {
            ...this.gridLines,
            zeroLineColor: 'rgba(255,0,0,0)',
          },
        },
      ],
    },
  };

  private fetchScenarioInformations(referenties: string[]) {
    this.isLoading = true;
    this.apollo
      .query<{ scenarioInformation: ScenarioInformation[] }>({
        query: powerFlowGraphQuery,
        variables: {
          order: null,
          filters: [
            {
              field: 'gf_sde_nr',
              values: referenties,
            },
          ],
        },
      })
      .pipe(map((response) => response.data.scenarioInformation))
      .subscribe((scenarioInformations: ScenarioInformation[]) => {
        this.setChartData(scenarioInformations);
        this.isLoading = false;
      });
  }

  private fetchMiningLocations() {
    this.isLoading = true;
    this.apollo
      .query<{ miningLocation: MiningLocation[] }>({
        query: gql`
          query MiningLocation($order: orderBy, $filters: [filterBy], $excludes: [filterBy]) {
            miningLocation(order: $order, filters: $filters, excludes: $excludes) {
              __typename
              id
              sde {
                id
                referentie
              }
            }
          }
        `,
        variables: {
          order: null,
          filters: [
            {
              field: 'id',
              values: this.data.miningLocationIds.map((id) => id.toString()),
            },
          ],
        },
      })
      .pipe(map((response) => response.data.miningLocation))
      .subscribe((miningLocations: MiningLocation[]) => {
        this.miningLocations = miningLocations;
        const referenties = miningLocations
          .filter((miningLocation) => miningLocation.sde && miningLocation.sde.referentie)
          .map((miningLocation) => miningLocation.sde?.referentie) as string[];
        this.fetchScenarioInformations([...new Set(referenties)]);
      });
  }

  private setChartData(scenarioInformations: ScenarioInformation[]) {
    const dataSets: ChartDataSets[] = [];

    scenarioInformations.forEach((scenarioInformation) => {
      if (scenarioInformation.p50_geothermal_power_mw && scenarioInformation.p50_pump_volume_flow_m3_h) {
        const color = scenarioInformation.facility_nm ? this.getColor(scenarioInformation.facility_nm) : 'red';

        if (scenarioInformation.p50_geothermal_power_mw !== null) {
          dataSets.push({
            label: scenarioInformation.facility_nm ?? '',
            backgroundColor: color,
            borderColor: color,
            radius: 6,
            order: 0,
            data: [
              {
                x: scenarioInformation.p50_pump_volume_flow_m3_h,
                y: scenarioInformation.p50_geothermal_power_mw,
              },
            ],
            pointStyle: 'circle',
          });
        }

        if (scenarioInformation.sde && scenarioInformation.sde.vermogen !== null) {
          dataSets.push({
            label: scenarioInformation.facility_nm ?? '',
            backgroundColor: color,
            borderColor: color,
            radius: 6,
            order: 1,
            data: [
              {
                x: scenarioInformation.p50_pump_volume_flow_m3_h,
                y: scenarioInformation.sde.vermogen ? parseFloat(scenarioInformation.sde.vermogen) : 0,
              },
            ],
            pointStyle: 'rectRot',
          });
        }
      }
    });

    this.chartData = dataSets;

    this.chartHasData = !!dataSets;
  }

  doExport(): void {
    const sdeIds = this.miningLocations
      .map((miningLocation) => miningLocation?.sde?.id)
      .filter((sdeId) => sdeId) as Array<Sde['id']>;

    this.isDownloading = true;
    this.apollo
      .query({
        query: exportSdeScenarioQuery,
        fetchPolicy: 'no-cache',
        variables: {
          sdeScenarioDownloadable: {
            filters: [{ field: 'sde.id', values: [...new Set(sdeIds.map((id) => id.toString()))] }],
          },
        },
      })
      .subscribe(
        (result) => {
          if (result.data) {
            this.downloadService.downloadExport((<any>result.data).export).subscribe(
              (res) => {
                downloadHelper(res, (<any>result.data).export);
              },
              () => this.snackBar.open('Geen informatie om te exporteren')
            );
          }
        },
        () => this.snackBar.open('Geen informatie om te exporteren')
      );
  }
}
