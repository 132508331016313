import { gql } from 'apollo-angular';

export const miningLocationDetailsQuery = gql`
  query MiningLocation($order: orderBy, $filters: [filterBy], $excludes: [filterBy]) {
    miningLocation(order: $order, filters: $filters, excludes: $excludes) {
      __typename
      id
      content {
        id
        title
        header
        body
        images
        links {
          title
          uri
        }
      }
      relatedBoreholes {
        id
        boorgatcode
        boorgatstatus
        put_naam
        hole_function
        isGeothermal
        corrected_temperatures {
          id
          z
          temperature
        }
      }
      sde {
        id
        aanvrager_naam
        referentie
        vermogen
        sde_ronde
        beschikte_productie_per_jaar
        looptijd
        maximale_subsidie
      }
      mijnbouwwerk_naam
      mijnbouwwerk_code
      total_water_amount
      total_injected_water_amount
      isGeothermal
    }
  }
`;

export const miningLocationWaterGraphQuery = gql`
  query MiningLocation($order: orderBy, $filters: [filterBy], $excludes: [filterBy]) {
    miningLocation(order: $order, filters: $filters, excludes: $excludes) {
      __typename
      id
      geo_thermal_graph {
        datum
        water_amount
        injected_water_amount
      }
    }
  }
`;
