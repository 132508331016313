import { Component } from '@angular/core';
import { MouseTool } from '@enums/mouse-tool.enum';
import { BoreholeDataService } from '@services/borehole-data.service';
import { GoogleMapsService } from '@services/google-maps.service';
import { ProModusService } from '@services/pro-modus.service';
import { BehaviorSubject } from 'rxjs';

@Component({
  selector: 'app-tools',
  templateUrl: './tools.component.html',
  styleUrls: ['./tools.component.scss'],
})
export class ToolsComponent {
  public mouseToolEnum = MouseTool;

  public isMinimalized = new BehaviorSubject<boolean>(true);

  constructor(
    public boreholeDataService: BoreholeDataService,
    private googleMapsService: GoogleMapsService,
    public proModusService: ProModusService
  ) {}

  public setMouseTool(tool: MouseTool) {
    this.proModusService.mouseTool.next(tool === this.proModusService.mouseTool.value ? null : tool);
  }

  public removeLassoShapes() {
    this.googleMapsService.removeLassoShapes();
  }
}
