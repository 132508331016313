<button class="flex p-4">
  <div class="w-10 h-10 bg-white shadow-sm flex items-center justify-around mr-4">
    <ion-icon name="bar-chart-sharp" class="text-black text-4xl"></ion-icon>
  </div>
  <div class="flex-1 flex flex-col justify-between text-xl">
    <div class="uppercase">{{ title }}</div>
    <div class="heading">
      <span class="text-red-600">{{ selectedCount }}</span>
      <span> geselecteerd</span>
    </div>
  </div>
</button>
