import { ChangeDetectorRef, Component, OnDestroy, OnInit } from '@angular/core';
import { BoreholeDataService } from '@services/borehole-data.service';
import { ExportProModusService } from '@services/export-pro-modus.service';
import { ProModusService } from '@services/pro-modus.service';
import { Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';
import { BoreholeWithLocation, MiningLocationWithLocation } from 'src/app/interfaces/location.interface';

@Component({
  selector: 'app-pro-modus',
  templateUrl: './pro-modus.component.html',
  styleUrls: ['./pro-modus.component.scss'],
})
export class ProModusComponent implements OnInit, OnDestroy {
  public locations: (BoreholeWithLocation | MiningLocationWithLocation)[] | undefined = undefined;
  public selectedLocations: (BoreholeWithLocation | MiningLocationWithLocation)[] | undefined = undefined;
  private destroy = new Subject();

  constructor(
    public boreholeDataService: BoreholeDataService,
    public proModusService: ProModusService,
    private changeDetectorRef: ChangeDetectorRef,
    private exportProModusService: ExportProModusService
  ) {}

  ngOnInit(): void {
    this.boreholeDataService.locations.subscribe((data) => {
      this.locations = data ?? undefined;
    });

    this.subscribeToSelectedLocations();
  }

  ngOnDestroy(): void {
    this.destroy.next();
    this.destroy.complete();
  }

  public getLocationIsChecked(location: BoreholeWithLocation | MiningLocationWithLocation): boolean {
    return (
      this.proModusService.selectedLocations.value.findIndex((selectedLocation) => {
        return selectedLocation.__typename === location.__typename && selectedLocation.id === location.id;
      }) >= 0
    );
  }

  public doExport(): void {
    if (!this.selectedLocations?.length) {
      window.alert('Geen boreholes of facilities geselecteerd');
      return;
    }

    const boreholeIds = this.selectedLocations
      ?.filter((location) => location.__typename === 'Borehole')
      .map((borehole) => borehole.id);

    const miningLocationIds = this.selectedLocations
      .filter((location) => location.__typename === 'MiningLocation')
      .map((miningLocation) => miningLocation.id);

    this.exportProModusService.doBoreholeExport(boreholeIds).subscribe(() => {});
    this.exportProModusService.doSdeExport(miningLocationIds).subscribe(() => {});
    this.exportProModusService.doScenarioExport(miningLocationIds).subscribe(() => {});
  }

  private subscribeToSelectedLocations() {
    this.proModusService.selectedLocations.pipe(takeUntil(this.destroy)).subscribe((response) => {
      this.selectedLocations = response;
      this.changeDetectorRef.detectChanges();
    });
  }
}
