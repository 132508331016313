import { gql } from 'apollo-angular';
export type Maybe<T> = T | null;
export type Exact<T extends { [key: string]: unknown }> = { [K in keyof T]: T[K] };
export type MakeOptional<T, K extends keyof T> = Omit<T, K> & { [SubKey in K]?: Maybe<T[SubKey]> };
export type MakeMaybe<T, K extends keyof T> = Omit<T, K> & { [SubKey in K]: Maybe<T[SubKey]> };
/** All built-in and custom scalars, mapped to their actual values */
export type Scalars = {
  ID: string;
  String: string;
  Boolean: boolean;
  Int: number;
  Float: number;
};

/** A type */
export type Borehole = {
  readonly __typename?: 'Borehole';
  /** The id */
  readonly X_UTM31_WGS84?: Maybe<Scalars['String']>;
  /** The id */
  readonly Y_UTM31_WGS84?: Maybe<Scalars['String']>;
  /** The id */
  readonly aangeleverd_stelsel?: Maybe<Scalars['String']>;
  /** The id */
  readonly aangeleverde_x?: Maybe<Scalars['String']>;
  /** The id */
  readonly aangeleverde_y?: Maybe<Scalars['String']>;
  /** The id */
  readonly bloknummer?: Maybe<Scalars['String']>;
  /** The id */
  readonly boorfirma?: Maybe<Scalars['String']>;
  /** The id */
  readonly boorgat?: Maybe<Scalars['String']>;
  /** The id */
  readonly boorgatcode?: Maybe<Scalars['String']>;
  /** The id */
  readonly boorgatdiepte_AH?: Maybe<Scalars['String']>;
  /** The id */
  readonly boorgatdiepte_AH_tov_NAP?: Maybe<Scalars['String']>;
  /** The id */
  readonly boorgatdiepte_TVD?: Maybe<Scalars['String']>;
  /** The id */
  readonly boorgatdiepte_TVD_tov_NAP?: Maybe<Scalars['String']>;
  /** The id */
  readonly boorgatdoel?: Maybe<Scalars['String']>;
  /** The id */
  readonly boorgatdoel_code?: Maybe<Scalars['String']>;
  /** The id */
  readonly boorgatnaam?: Maybe<Scalars['String']>;
  /** The id */
  readonly boorgatresultaat?: Maybe<Scalars['String']>;
  /** The id */
  readonly boorgatresultaat_code?: Maybe<Scalars['String']>;
  /** The id */
  readonly boorgatstatus?: Maybe<Scalars['String']>;
  /** The id */
  readonly boorgatvorm?: Maybe<Scalars['String']>;
  /** The id */
  readonly boortoren?: Maybe<Scalars['String']>;
  /** The id */
  readonly chronotratigrafie_code?: Maybe<Scalars['String']>;
  /** Related corrected temperatures */
  readonly corrected_temperatures?: Maybe<ReadonlyArray<Maybe<CorrectedTemperature>>>;
  /** The id */
  readonly einddatum?: Maybe<Scalars['String']>;
  /** The id */
  readonly gemeente_id?: Maybe<Scalars['String']>;
  /** The id */
  readonly gemeente_naam?: Maybe<Scalars['String']>;
  /** water_amount_per_month */
  readonly geo_thermal_graph?: Maybe<ReadonlyArray<Maybe<GeoThermalGraph>>>;
  /** Determines if a borehole produces water or injects water */
  readonly hole_function?: Maybe<HoleFunction>;
  /** The id */
  readonly hoogte_referentievlak?: Maybe<Scalars['String']>;
  /** The id */
  readonly huidige_eigenaar?: Maybe<Scalars['String']>;
  /** The id of this borehole */
  readonly id: Scalars['Int'];
  /** is this a geothermal borehole */
  readonly isGeothermal?: Maybe<Scalars['Int']>;
  /** The id */
  readonly kaartblad?: Maybe<Scalars['String']>;
  /** The id */
  readonly kaartbladindeling?: Maybe<Scalars['String']>;
  /** The id */
  readonly kickoff_diepte_ah?: Maybe<Scalars['String']>;
  /** The id */
  readonly latitude_ED50: Scalars['String'];
  /** The id */
  readonly latitude_WGS84?: Maybe<Scalars['String']>;
  /** The id */
  readonly lithostratigrafie_code?: Maybe<Scalars['String']>;
  /** The id */
  readonly longitude_ED50: Scalars['String'];
  /** The id */
  readonly longitude_WGS84?: Maybe<Scalars['String']>;
  /** The id */
  readonly mijnbouwwerk_code?: Maybe<Scalars['String']>;
  /** The id */
  readonly mijnbouwwerk_naam?: Maybe<Scalars['String']>;
  /** mijnbouw locatie voor borehole */
  readonly miningLocation?: Maybe<MiningLocation>;
  /** The nITG_nummer poroperm identifier */
  readonly nITG_nummer: Scalars['String'];
  /** The id */
  readonly on_Offshore?: Maybe<Scalars['String']>;
  /** The id */
  readonly opdrachtgever?: Maybe<Scalars['String']>;
  /** The id */
  readonly openbaar_vanaf?: Maybe<Scalars['String']>;
  /** poroperms */
  readonly poroperms?: Maybe<ReadonlyArray<Maybe<Poroperm>>>;
  /** The id */
  readonly postcode?: Maybe<Scalars['String']>;
  /** The id */
  readonly provincie_code?: Maybe<Scalars['String']>;
  /** The id */
  readonly provincie_naam?: Maybe<Scalars['String']>;
  /** The id */
  readonly put_naam?: Maybe<Scalars['String']>;
  /** The id */
  readonly referentievlak?: Maybe<Scalars['String']>;
  /** Related boreholes based on the mijnbouwwerk_code column */
  readonly related_mine_boreholes?: Maybe<ReadonlyArray<Maybe<Borehole>>>;
  /** sde */
  readonly sde?: Maybe<Sde>;
  /** The id */
  readonly startdatum?: Maybe<Scalars['String']>;
  /** SUM of injected_water_amount column of geo thermal heads */
  readonly total_injected_water_amount?: Maybe<Scalars['Int']>;
  /** SUM of water_amount column of geo thermal heads */
  readonly total_water_amount?: Maybe<Scalars['Int']>;
  /** The id */
  readonly tov?: Maybe<Scalars['String']>;
  /** The id */
  readonly uWI?: Maybe<Scalars['String']>;
  /** The id */
  readonly veld_code?: Maybe<Scalars['String']>;
  /** The id */
  readonly veld_naam?: Maybe<Scalars['String']>;
  /** The id */
  readonly x_TM5_ED50?: Maybe<Scalars['String']>;
  /** The id */
  readonly x_UTM31_ED50?: Maybe<Scalars['String']>;
  /** The id */
  readonly x_deviatie_UTM31_ED50?: Maybe<Scalars['String']>;
  /** The id */
  readonly x_end_UTM31_ED50?: Maybe<Scalars['String']>;
  /** The id */
  readonly x_rijksdriehoek?: Maybe<Scalars['String']>;
  /** The id */
  readonly y_TM5_ED50?: Maybe<Scalars['String']>;
  /** The id */
  readonly y_UTM31_ED50?: Maybe<Scalars['String']>;
  /** The id */
  readonly y_deviatie_UTM31_ED50?: Maybe<Scalars['String']>;
  /** The id */
  readonly y_end_UTM31_ED50?: Maybe<Scalars['String']>;
  /** The id */
  readonly y_rijksdriehoek?: Maybe<Scalars['String']>;
};

/** An example input */
export type BoreholeDownloadable = {
  readonly excludes?: Maybe<ReadonlyArray<Maybe<FilterBy>>>;
  readonly filters?: Maybe<ReadonlyArray<Maybe<FilterBy>>>;
  readonly order?: Maybe<OrderBy>;
};

/** Enum BoreholesTypes */
export enum BoreholesTypes {
  Borehole = 'BOREHOLE',
  Facilities = 'FACILITIES',
  GeothermalBorehole = 'GEOTHERMAL_BOREHOLE',
}

/** A type */
export type Content = {
  readonly __typename?: 'Content';
  /** The id */
  readonly body?: Maybe<Scalars['String']>;
  /** The id */
  readonly header?: Maybe<Scalars['String']>;
  /** The id of this borehole */
  readonly id: Scalars['Int'];
  /** media for content */
  readonly images?: Maybe<ReadonlyArray<Maybe<Scalars['String']>>>;
  /** url's for content */
  readonly links?: Maybe<ReadonlyArray<Maybe<Link>>>;
  /** The id */
  readonly title?: Maybe<Scalars['String']>;
};

/** A type */
export type CorrectedTemperature = {
  readonly __typename?: 'CorrectedTemperature';
  /** poroperms */
  readonly correction?: Maybe<Correction>;
  /** The id */
  readonly error_code?: Maybe<Scalars['String']>;
  /** The id of this corrected temperature */
  readonly id: Scalars['Int'];
  /** The id */
  readonly short_name?: Maybe<Scalars['String']>;
  /** The id */
  readonly temperature?: Maybe<Scalars['String']>;
  /** The id */
  readonly x_surface?: Maybe<Scalars['String']>;
  /** The id */
  readonly y_surface?: Maybe<Scalars['String']>;
  /** The id */
  readonly z?: Maybe<Scalars['String']>;
};

/** An example input */
export type CorrectedTemperatureDownloadable = {
  /** borehole id's as array */
  readonly borehole_ids?: Maybe<ReadonlyArray<Maybe<Scalars['Int']>>>;
};

/** A type */
export type Correction = {
  readonly __typename?: 'Correction';
  /** The id */
  readonly code?: Maybe<Scalars['String']>;
  /** The id */
  readonly correction?: Maybe<Scalars['String']>;
  /** The id */
  readonly label?: Maybe<Scalars['String']>;
  /** The id */
  readonly type_of_T_measurement?: Maybe<Scalars['String']>;
};

/** Current user info */
export type CurrentUserType = {
  readonly __typename?: 'CurrentUserType';
  /** logged in status */
  readonly loggedIn?: Maybe<Scalars['Boolean']>;
  /** user */
  readonly user?: Maybe<User>;
};

/** Summed water_amount and injected_water_amount per month */
export type GeoThermalGraph = {
  readonly __typename?: 'GeoThermalGraph';
  /** The id */
  readonly datum?: Maybe<Scalars['String']>;
  /** Summed injected water amount of month */
  readonly injected_water_amount?: Maybe<Scalars['Int']>;
  /** Summed produced water amount of month */
  readonly water_amount?: Maybe<Scalars['Int']>;
};

/** A type */
export type GeoThermalHeat = {
  readonly __typename?: 'GeoThermalHeat';
  /** The id */
  readonly aardwarmte_installatie?: Maybe<Scalars['String']>;
  /** The id */
  readonly condensate_amount?: Maybe<Scalars['String']>;
  /** The id */
  readonly datum?: Maybe<Scalars['String']>;
  /** The id */
  readonly gas_amount?: Maybe<Scalars['String']>;
  /** The id of this geothermal heat */
  readonly id: Scalars['Int'];
  /** The id */
  readonly inhibitor_amount?: Maybe<Scalars['String']>;
  /** The id */
  readonly injected_inhibitor_amount?: Maybe<Scalars['String']>;
  /** The id */
  readonly injected_water_amount?: Maybe<Scalars['String']>;
  /** The id */
  readonly oil_amount?: Maybe<Scalars['String']>;
  /** The id */
  readonly operator?: Maybe<Scalars['String']>;
  /** The id */
  readonly put?: Maybe<Scalars['String']>;
  /** The id */
  readonly water_amount?: Maybe<Scalars['String']>;
};

/** An example input */
export type GeoThermalHeatDownloadable = {
  readonly by_type_name?: Maybe<GeoThermalHeatsByTypeNames>;
  readonly excludes?: Maybe<ReadonlyArray<Maybe<FilterBy>>>;
  readonly filters?: Maybe<ReadonlyArray<Maybe<FilterBy>>>;
  readonly order?: Maybe<OrderBy>;
};

/** Enum GeoThermalHeatsByTypeNames */
export enum GeoThermalHeatsByTypeNames {
  Borehole = 'Borehole',
  GeoThermalHeat = 'GeoThermalHeat',
  MiningLocation = 'MiningLocation',
}

/** Enum HoleType */
export enum HoleFunction {
  Injection = 'INJECTION',
  Production = 'PRODUCTION',
}

/** A link */
export type Link = {
  readonly __typename?: 'Link';
  /** The id of this borehole */
  readonly id: Scalars['Int'];
  /** The title */
  readonly title?: Maybe<Scalars['String']>;
  /** The uri */
  readonly uri?: Maybe<Scalars['String']>;
};

/** An example union */
export type Location = Borehole | MiningLocation;

/** A type */
export type MiningLocation = {
  readonly __typename?: 'MiningLocation';
  /** bopreholes bij locatie */
  readonly content?: Maybe<Content>;
  /** total_water_amount_per_month */
  readonly geo_thermal_graph?: Maybe<ReadonlyArray<Maybe<GeoThermalGraph>>>;
  /** The id of this borehole */
  readonly id: Scalars['Int'];
  /** is this a geothermal borehole */
  readonly isGeothermal?: Maybe<Scalars['Int']>;
  /** The id */
  readonly latitude_ED50: Scalars['String'];
  /** The id */
  readonly longitude_ED50: Scalars['String'];
  /** The id */
  readonly mijnbouwwerk_code?: Maybe<Scalars['String']>;
  /** The id */
  readonly mijnbouwwerk_naam?: Maybe<Scalars['String']>;
  /** bopreholes bij locatie */
  readonly relatedBoreholes?: Maybe<ReadonlyArray<Borehole>>;
  /** sde */
  readonly sde?: Maybe<Sde>;
  /** SUM of injected_water_amount column of geo thermal heads */
  readonly total_injected_water_amount?: Maybe<Scalars['Float']>;
  /** SUM of water_amount column of geo thermal heads */
  readonly total_water_amount?: Maybe<Scalars['Float']>;
};

export type Mutation = {
  readonly __typename?: 'Mutation';
  readonly logIn?: Maybe<LogIn>;
};

export type MutationLogInArgs = {
  email: Scalars['String'];
  password: Scalars['String'];
};

/** An example input */
export type PermeabilityDownloadable = {
  /** borehole id's as array */
  readonly borehole_ids?: Maybe<ReadonlyArray<Maybe<Scalars['Int']>>>;
};

/** A type */
export type Poroperm = {
  readonly __typename?: 'Poroperm';
  /** The id */
  readonly analysis_nr?: Maybe<Scalars['String']>;
  /** The id */
  readonly bottom_strat?: Maybe<Scalars['String']>;
  /** The id */
  readonly core_nr?: Maybe<Scalars['String']>;
  /** The id */
  readonly depth?: Maybe<Scalars['String']>;
  /** The id */
  readonly formation_factor?: Maybe<Scalars['String']>;
  /** The id */
  readonly grain_density?: Maybe<Scalars['String']>;
  /** The id */
  readonly hor_permeability?: Maybe<Scalars['String']>;
  /** The id */
  readonly lab?: Maybe<Scalars['String']>;
  /** The id */
  readonly lab_nr?: Maybe<Scalars['String']>;
  /** The id */
  readonly lab_report_date?: Maybe<Scalars['String']>;
  /** The id */
  readonly nitg_nr?: Maybe<Scalars['String']>;
  /** The id */
  readonly porosity?: Maybe<Scalars['String']>;
  /** The id */
  readonly remark?: Maybe<Scalars['String']>;
  /** The id */
  readonly short_nm?: Maybe<Scalars['String']>;
  /** The id */
  readonly strat_unit_cd?: Maybe<Scalars['String']>;
  /** The id */
  readonly strat_unit_nm?: Maybe<Scalars['String']>;
  /** The id */
  readonly top_strat?: Maybe<Scalars['String']>;
  /** The id */
  readonly uwi?: Maybe<Scalars['String']>;
  /** The id */
  readonly vert_permeability?: Maybe<Scalars['String']>;
};

/** An example input */
export type PoropermDownloadable = {
  /** borehole id's as array */
  readonly borehole_ids?: Maybe<ReadonlyArray<Maybe<Scalars['Int']>>>;
};

export type Query = {
  readonly __typename?: 'Query';
  readonly Dummy?: Maybe<CurrentUserType>;
};

/** Scenario information type */
export type ScenarioInformation = {
  readonly __typename?: 'ScenarioInformation';
  /** the aquifer_inj_p_bar column */
  readonly aquifer_inj_p_bar?: Maybe<Scalars['Float']>;
  /** the aquifer_inj_t_deg_c column */
  readonly aquifer_inj_t_deg_c?: Maybe<Scalars['Float']>;
  /** the aquifer_prod_bottom_p_bar column */
  readonly aquifer_prod_bottom_p_bar?: Maybe<Scalars['Float']>;
  /** the aquifer_prod_bottom_t_deg_c column */
  readonly aquifer_prod_bottom_t_deg_c?: Maybe<Scalars['Float']>;
  /** the aquifer_prod_p_bar column */
  readonly aquifer_prod_p_bar?: Maybe<Scalars['Float']>;
  /** the aquifer_prod_t_deg_c column */
  readonly aquifer_prod_t_deg_c?: Maybe<Scalars['Float']>;
  /** the average_producer_density_kg_m3 column */
  readonly average_producer_density_kg_m3?: Maybe<Scalars['Float']>;
  /** the average_producer_heat_capacity_k_j_kg_k column */
  readonly average_producer_heat_capacity_k_j_kg_k?: Maybe<Scalars['Float']>;
  /** the average_producer_s_kg_g column */
  readonly average_producer_s_kg_g?: Maybe<Scalars['Float']>;
  /** the average_producer_s_ppm column */
  readonly average_producer_s_ppm?: Maybe<Scalars['Int']>;
  /** the average_producer_viscosity_pa_s column */
  readonly average_producer_viscosity_pa_s?: Maybe<Scalars['Float']>;
  /** the base_case_aquifer_k_h_net_dm column */
  readonly base_case_aquifer_k_h_net_dm?: Maybe<Scalars['Float']>;
  /** the base_case_aquifer_pressure_at_producer_bar column */
  readonly base_case_aquifer_pressure_at_producer_bar?: Maybe<Scalars['Float']>;
  /** the base_case_aquifer_temperature_at_producer_c column */
  readonly base_case_aquifer_temperature_at_producer_c?: Maybe<Scalars['Float']>;
  /** the base_case_cop_k_w_k_w column */
  readonly base_case_cop_k_w_k_w?: Maybe<Scalars['Float']>;
  /** the base_case_geothermal_power_mw column */
  readonly base_case_geothermal_power_mw?: Maybe<Scalars['Float']>;
  /** the base_case_mass_flow_kg_s column */
  readonly base_case_mass_flow_kg_s?: Maybe<Scalars['Float']>;
  /** the base_case_pressure_at_heat_exchanger_bar column */
  readonly base_case_pressure_at_heat_exchanger_bar?: Maybe<Scalars['Float']>;
  /** the base_case_pressure_difference_at_injector_bar column */
  readonly base_case_pressure_difference_at_injector_bar?: Maybe<Scalars['Float']>;
  /** the base_case_pressure_difference_at_producer_bar column */
  readonly base_case_pressure_difference_at_producer_bar?: Maybe<Scalars['Float']>;
  /** the base_case_pump_volume_flow_m3_h column */
  readonly base_case_pump_volume_flow_m3_h?: Maybe<Scalars['Float']>;
  /** the base_case_required_pump_power_k_w column */
  readonly base_case_required_pump_power_k_w?: Maybe<Scalars['Float']>;
  /** the base_case_temperature_at_heat_exchanger_c column */
  readonly base_case_temperature_at_heat_exchanger_c?: Maybe<Scalars['Float']>;
  /** the bron column */
  readonly bron?: Maybe<Scalars['String']>;
  /** the exit_he_inj_top_p_bar column */
  readonly exit_he_inj_top_p_bar?: Maybe<Scalars['Float']>;
  /** the exit_he_inj_top_t_deg_c column */
  readonly exit_he_inj_top_t_deg_c?: Maybe<Scalars['Float']>;
  /** the facility_nm column */
  readonly facility_nm?: Maybe<Scalars['String']>;
  /** the geothermgradient column */
  readonly geothermgradient?: Maybe<Scalars['Float']>;
  /** the gf_sde_nr column */
  readonly gf_sde_nr?: Maybe<Scalars['String']>;
  /** The id */
  readonly id: Scalars['Int'];
  /** the inj_bottom_aquifer_p_bar column */
  readonly inj_bottom_aquifer_p_bar?: Maybe<Scalars['Float']>;
  /** the inj_bottom_aquifer_t_deg_c column */
  readonly inj_bottom_aquifer_t_deg_c?: Maybe<Scalars['Float']>;
  /** the injectionangle column */
  readonly injectionangle?: Maybe<Scalars['Int']>;
  /** the injecttopreservoirdepthlikely column */
  readonly injecttopreservoirdepthlikely?: Maybe<Scalars['Float']>;
  /** the input_file_name_dc_results column */
  readonly input_file_name_dc_results?: Maybe<Scalars['String']>;
  /** the input_file_name_xml_scenario column */
  readonly input_file_name_xml_scenario?: Maybe<Scalars['String']>;
  /** the khkvratio column */
  readonly khkvratio?: Maybe<Scalars['Float']>;
  /** the maxdifference column */
  readonly maxdifference?: Maybe<Scalars['Float']>;
  /** the nettogrosslikely column */
  readonly nettogrosslikely?: Maybe<Scalars['Float']>;
  /** the nettogrossmax column */
  readonly nettogrossmax?: Maybe<Scalars['Float']>;
  /** the nettogrossmin column */
  readonly nettogrossmin?: Maybe<Scalars['Float']>;
  /** the operator column */
  readonly operator?: Maybe<Scalars['String']>;
  /** the outerdiameterinj column */
  readonly outerdiameterinj?: Maybe<Scalars['Float']>;
  /** the outerdiameterprd column */
  readonly outerdiameterprd?: Maybe<Scalars['Float']>;
  /** the p10_aquifer_k_h_net_dm column */
  readonly p10_aquifer_k_h_net_dm?: Maybe<Scalars['Float']>;
  /** the p10_aquifer_pressure_at_injector_bar column */
  readonly p10_aquifer_pressure_at_injector_bar?: Maybe<Scalars['Float']>;
  /** the p10_aquifer_pressure_at_producer_bar column */
  readonly p10_aquifer_pressure_at_producer_bar?: Maybe<Scalars['Float']>;
  /** the p10_aquifer_temperature_at_producer_c column */
  readonly p10_aquifer_temperature_at_producer_c?: Maybe<Scalars['Float']>;
  /** the p10_cop_k_w_k_w column */
  readonly p10_cop_k_w_k_w?: Maybe<Scalars['Float']>;
  /** the p10_geothermal_power_mw column */
  readonly p10_geothermal_power_mw?: Maybe<Scalars['Float']>;
  /** the p10_mass_flow_kg_s column */
  readonly p10_mass_flow_kg_s?: Maybe<Scalars['Float']>;
  /** the p10_pressure_difference_at_injector_bar column */
  readonly p10_pressure_difference_at_injector_bar?: Maybe<Scalars['Float']>;
  /** the p10_pressure_difference_at_producer_bar column */
  readonly p10_pressure_difference_at_producer_bar?: Maybe<Scalars['Float']>;
  /** the p10_pump_volume_flow_m3_h column */
  readonly p10_pump_volume_flow_m3_h?: Maybe<Scalars['Float']>;
  /** the p10_required_pump_power_k_w column */
  readonly p10_required_pump_power_k_w?: Maybe<Scalars['Float']>;
  /** the p10_temperature_at_heat_exchanger_c column */
  readonly p10_temperature_at_heat_exchanger_c?: Maybe<Scalars['Float']>;
  /** the p50_aquifer_k_h_net_dm column */
  readonly p50_aquifer_k_h_net_dm?: Maybe<Scalars['Float']>;
  /** the p50_aquifer_pressure_at_injector_bar column */
  readonly p50_aquifer_pressure_at_injector_bar?: Maybe<Scalars['Float']>;
  /** the p50_aquifer_pressure_at_producer_bar column */
  readonly p50_aquifer_pressure_at_producer_bar?: Maybe<Scalars['Float']>;
  /** the p50_aquifer_temperature_at_producer_c column */
  readonly p50_aquifer_temperature_at_producer_c?: Maybe<Scalars['Float']>;
  /** the p50_cop_k_w_k_w column */
  readonly p50_cop_k_w_k_w?: Maybe<Scalars['Float']>;
  /** the p50_geothermal_power_mw column */
  readonly p50_geothermal_power_mw?: Maybe<Scalars['Float']>;
  /** the p50_mass_flow_kg_s column */
  readonly p50_mass_flow_kg_s?: Maybe<Scalars['Float']>;
  /** the p50_pressure_difference_at_injector_bar column */
  readonly p50_pressure_difference_at_injector_bar?: Maybe<Scalars['Float']>;
  /** the p50_pressure_difference_at_producer_bar column */
  readonly p50_pressure_difference_at_producer_bar?: Maybe<Scalars['Float']>;
  /** the p50_pump_volume_flow_m3_h column */
  readonly p50_pump_volume_flow_m3_h?: Maybe<Scalars['Float']>;
  /** the p50_required_pump_power_k_w column */
  readonly p50_required_pump_power_k_w?: Maybe<Scalars['Float']>;
  /** the p50_temperature_at_heat_exchanger_c column */
  readonly p50_temperature_at_heat_exchanger_c?: Maybe<Scalars['Float']>;
  /** the p90_aquifer_k_h_net_dm column */
  readonly p90_aquifer_k_h_net_dm?: Maybe<Scalars['Float']>;
  /** the p90_aquifer_pressure_at_injector_bar column */
  readonly p90_aquifer_pressure_at_injector_bar?: Maybe<Scalars['Float']>;
  /** the p90_aquifer_pressure_at_producer_bar column */
  readonly p90_aquifer_pressure_at_producer_bar?: Maybe<Scalars['Float']>;
  /** the p90_aquifer_temperature_at_producer_c column */
  readonly p90_aquifer_temperature_at_producer_c?: Maybe<Scalars['Float']>;
  /** the p90_cop_k_w_k_w column */
  readonly p90_cop_k_w_k_w?: Maybe<Scalars['Float']>;
  /** the p90_geothermal_power_mw column */
  readonly p90_geothermal_power_mw?: Maybe<Scalars['Float']>;
  /** the p90_mass_flow_kg_s column */
  readonly p90_mass_flow_kg_s?: Maybe<Scalars['Float']>;
  /** the p90_pressure_difference_at_injector_bar column */
  readonly p90_pressure_difference_at_injector_bar?: Maybe<Scalars['Float']>;
  /** the p90_pressure_difference_at_producer_bar column */
  readonly p90_pressure_difference_at_producer_bar?: Maybe<Scalars['Float']>;
  /** the p90_pump_volume_flow_m3_h column */
  readonly p90_pump_volume_flow_m3_h?: Maybe<Scalars['Float']>;
  /** the p90_required_pump_power_k_w column */
  readonly p90_required_pump_power_k_w?: Maybe<Scalars['Float']>;
  /** the p90_temperature_at_heat_exchanger_c column */
  readonly p90_temperature_at_heat_exchanger_c?: Maybe<Scalars['Float']>;
  /** the permeabilitylikely column */
  readonly permeabilitylikely?: Maybe<Scalars['Float']>;
  /** the permeabilitymax column */
  readonly permeabilitymax?: Maybe<Scalars['Float']>;
  /** the permeabilitymin column */
  readonly permeabilitymin?: Maybe<Scalars['Float']>;
  /** the pressinjtop column */
  readonly pressinjtop?: Maybe<Scalars['Float']>;
  /** the pressprodtop column */
  readonly pressprodtop?: Maybe<Scalars['Float']>;
  /** the prod_top_entry_he_p_bar column */
  readonly prod_top_entry_he_p_bar?: Maybe<Scalars['Float']>;
  /** the prod_top_entry_he_t_deg_c column */
  readonly prod_top_entry_he_t_deg_c?: Maybe<Scalars['Float']>;
  /** the prodtopreservoirdepthlikely column */
  readonly prodtopreservoirdepthlikely?: Maybe<Scalars['Float']>;
  /** the productionangle column */
  readonly productionangle?: Maybe<Scalars['Int']>;
  /** the pumpdepth column */
  readonly pumpdepth?: Maybe<Scalars['Float']>;
  /** the pumpefficiency column */
  readonly pumpefficiency?: Maybe<Scalars['Float']>;
  /** the regeling column */
  readonly regeling?: Maybe<Scalars['String']>;
  /** the roughnessinjector column */
  readonly roughnessinjector?: Maybe<Scalars['String']>;
  /** the roughnessproducer column */
  readonly roughnessproducer?: Maybe<Scalars['String']>;
  /** the runs column */
  readonly runs?: Maybe<Scalars['Int']>;
  /** the rvo_project_naam column */
  readonly rvo_project_naam?: Maybe<Scalars['String']>;
  /** the salinitylikely column */
  readonly salinitylikely?: Maybe<Scalars['Float']>;
  /** the salinitymax column */
  readonly salinitymax?: Maybe<Scalars['Float']>;
  /** the salinitymin column */
  readonly salinitymin?: Maybe<Scalars['Float']>;
  /** the scenario column */
  readonly scenario?: Maybe<Scalars['String']>;
  /** the scenario_nr column */
  readonly scenario_nr?: Maybe<Scalars['String']>;
  /** sde */
  readonly sde?: Maybe<Sde>;
  /** the segmentlength column */
  readonly segmentlength?: Maybe<Scalars['Int']>;
  /** the segmentsinjector column */
  readonly segmentsinjector?: Maybe<Scalars['String']>;
  /** the segmentsinjectortvd column */
  readonly segmentsinjectortvd?: Maybe<Scalars['String']>;
  /** the segmentsproducer column */
  readonly segmentsproducer?: Maybe<Scalars['String']>;
  /** the segmentsproducertvd column */
  readonly segmentsproducertvd?: Maybe<Scalars['String']>;
  /** the skininject column */
  readonly skininject?: Maybe<Scalars['Float']>;
  /** the skinproduct column */
  readonly skinproduct?: Maybe<Scalars['Float']>;
  /** the surfacetemp column */
  readonly surfacetemp?: Maybe<Scalars['Float']>;
  /** the tempinjectwater column */
  readonly tempinjectwater?: Maybe<Scalars['Float']>;
  /** the temptop column */
  readonly temptop?: Maybe<Scalars['Float']>;
  /** the thicknesslikely column */
  readonly thicknesslikely?: Maybe<Scalars['Float']>;
  /** the thicknessmax column */
  readonly thicknessmax?: Maybe<Scalars['Float']>;
  /** the thicknessmin column */
  readonly thicknessmin?: Maybe<Scalars['Float']>;
  /** the vergunning column */
  readonly vergunning?: Maybe<Scalars['String']>;
  /** the welldiameterinjector column */
  readonly welldiameterinjector?: Maybe<Scalars['String']>;
  /** the welldiameterproducer column */
  readonly welldiameterproducer?: Maybe<Scalars['String']>;
  /** the welldistance column */
  readonly welldistance?: Maybe<Scalars['Float']>;
};

/** SDE type */
export type Sde = {
  readonly __typename?: 'Sde';
  /** The id */
  readonly aanvrager_naam: Scalars['String'];
  /** The id */
  readonly adres: Scalars['String'];
  /** The id */
  readonly beschikte_productie_per_jaar?: Maybe<Scalars['String']>;
  /** The id */
  readonly categorie: Scalars['String'];
  /** The id */
  readonly gerealiseerd: Scalars['String'];
  /** The id */
  readonly id: Scalars['Int'];
  /** The id */
  readonly looptijd: Scalars['String'];
  /** The id */
  readonly maximale_subsidie: Scalars['String'];
  /** The id */
  readonly opstelling_zon_pv: Scalars['String'];
  /** The id */
  readonly plaats_lokatie: Scalars['String'];
  /** The id */
  readonly postcode: Scalars['String'];
  /** The id */
  readonly provincie: Scalars['String'];
  /** The id */
  readonly referentie: Scalars['String'];
  /** sde */
  readonly scenarioInformations?: Maybe<ReadonlyArray<ScenarioInformation>>;
  /** The id */
  readonly sde_ronde: Scalars['String'];
  /** The id */
  readonly thema: Scalars['String'];
  /** The id */
  readonly vermogen: Scalars['String'];
};

/** Distinct rows from sdes table. */
export type SdeDistinct = {
  readonly __typename?: 'SdeDistinct';
  /** The id */
  readonly aanvrager_naam: Scalars['String'];
  /** The id */
  readonly adres: Scalars['String'];
  /** The id */
  readonly beschikte_productie_per_jaar?: Maybe<Scalars['String']>;
  /** The id */
  readonly categorie: Scalars['String'];
  /** The id */
  readonly gerealiseerd: Scalars['String'];
  /** The id */
  readonly looptijd: Scalars['String'];
  /** The id */
  readonly maximale_subsidie: Scalars['String'];
  /** The id */
  readonly opstelling_zon_pv: Scalars['String'];
  /** The id */
  readonly plaats_lokatie: Scalars['String'];
  /** The id */
  readonly postcode: Scalars['String'];
  /** The id */
  readonly provincie: Scalars['String'];
  /** The id */
  readonly referentie: Scalars['String'];
  /** The id */
  readonly sde_ronde: Scalars['String'];
  /** The id */
  readonly thema: Scalars['String'];
  /** The id */
  readonly vermogen: Scalars['String'];
};

/** An example input */
export type SdeDownloadable = {
  readonly excludes?: Maybe<ReadonlyArray<Maybe<FilterBy>>>;
  readonly filters?: Maybe<ReadonlyArray<Maybe<FilterBy>>>;
  readonly order?: Maybe<OrderBy>;
};

/** An example input */
export type SdeScenarioDownloadable = {
  readonly filters?: Maybe<ReadonlyArray<Maybe<FilterBy>>>;
};

/** An example input */
export type TemperatureDownloadable = {
  readonly export_type?: Maybe<Scalars['String']>;
  readonly mijnbouwwerk_code?: Maybe<Scalars['String']>;
};

/** A user */
export type User = {
  readonly __typename?: 'User';
  /** The token of a user */
  readonly api_token: Scalars['String'];
  /** Date a was created */
  readonly created_at?: Maybe<Scalars['String']>;
  /** The email address of a user */
  readonly email: Scalars['String'];
  /** The id of a user */
  readonly id: Scalars['Int'];
  /** The name of a user */
  readonly name: Scalars['String'];
  /** Date a was updated */
  readonly updated_at?: Maybe<Scalars['String']>;
};

/** An example input */
export type FilterBy = {
  /** field to filter */
  readonly field?: Maybe<Scalars['String']>;
  /** values to filter on */
  readonly values?: Maybe<ReadonlyArray<Maybe<Scalars['String']>>>;
};

export type LogIn = {
  readonly __typename?: 'logIn';
  readonly api_token?: Maybe<Scalars['String']>;
  readonly message?: Maybe<Scalars['String']>;
  readonly success?: Maybe<Scalars['Boolean']>;
};

export type Logout = {
  readonly __typename?: 'logout';
  readonly message?: Maybe<Scalars['String']>;
};

/** An example input */
export type OrderBy = {
  /** direction of sorting. */
  readonly direction?: Maybe<Scalars['String']>;
  /** field to order */
  readonly field?: Maybe<Scalars['String']>;
};

/** Range filter by */
export type RangeFilterBy = {
  /** field to filter */
  readonly field?: Maybe<Scalars['String']>;
  /** maximum of range */
  readonly max?: Maybe<ReadonlyArray<Maybe<Scalars['Int']>>>;
  /** minimum of range */
  readonly min?: Maybe<ReadonlyArray<Maybe<Scalars['Int']>>>;
};

/** Search input */
export type Search = {
  /** fields to search */
  readonly fields?: Maybe<ReadonlyArray<Maybe<Scalars['String']>>>;
  /** Search query */
  readonly query?: Maybe<Scalars['String']>;
};

/** An example input */
export type WhereClause = {
  /** field to filter */
  readonly field?: Maybe<Scalars['String']>;
  /** optional operator to use in the where clause */
  readonly operator?: Maybe<Scalars['String']>;
  /** values to filter on */
  readonly value?: Maybe<Scalars['String']>;
};
