<div class="bg-white pointer-events-auto flex flex-col ml-4 max-h-full overflow-auto py-8 mt-4" style="width: 300px">
  <div class="flex justify-between items-center px-8">
    <span class="font-bold">Filters</span>
    <button type="button" (click)="isMinimalized.next(!isMinimalized.value)" class="p-2">
      <div class="border-b-2 border-white w-2 h-2 block"></div>
    </button>
  </div>

  <div *ngIf="isMinimalized.value" class="flex-1 overflow-auto">
    <div class="max-h-full overflow-auto">
      <div>
        <form [formGroup]="boreholeDataService.filtersForm">
          <div class="px-8 mt-4">
            <label class="text-sm block mb-2">Selecteer type</label>
            <div>
              <span *ngFor="let filterType of filterTypes" class="tno-radio mr-4">
                <input
                  type="radio"
                  name="type"
                  [id]="'type_' + filterType.value"
                  [value]="filterType.value"
                  class="mr-2"
                  [formControl]="typeControl"
                />
                <label class="inline-flex items-center" [for]="'type_' + filterType.value">
                  {{ filterType.description }}
                </label>
              </span>
            </div>
          </div>
          <div
            *ngIf="
              typeControl.value === this.boreholeTypeEnum.Borehole ||
              typeControl.value === this.boreholeTypeEnum.GeothermalBorehole ||
              typeControl.value === this.boreholeTypeEnum.Facilities
            "
            class="px-8 mt-4"
          >
            <label class="mb-2 block text-sm" for="provincie_code">Provincie</label>
            <select
              class="w-full tno-select"
              [ngClass]="{ empty: !boreholeDataService.filtersForm.value.province }"
              formControlName="provincie_code"
              name="provincie_code"
            >
              <option value="">selecteer optie</option>
              <option *ngFor="let provinceOption of provinceOptions" [value]="provinceOption.value">
                {{ provinceOption.description }}
              </option>
            </select>
          </div>

          <div
            *ngIf="
              typeControl.value === this.boreholeTypeEnum.Borehole ||
              typeControl.value === this.boreholeTypeEnum.GeothermalBorehole
            "
            class="px-8 mt-4"
          >
            <label class="mb-2 block text-sm" for="boorgatcode">Boorgatcode</label>
            <input type="text" name="boorgatcode" formControlName="boorgatcode" class="input" />
          </div>

          <div
            *ngIf="
              typeControl.value === this.boreholeTypeEnum.Borehole ||
              typeControl.value === this.boreholeTypeEnum.GeothermalBorehole
            "
            class="px-8 mt-4"
          >
            <label class="mb-2 block text-sm">Boorgatdiepte (m TVD)</label>
            <div class="range-input">
              <input
                name="boorgatdiepte_TVD+min"
                formControlName="boorgatdiepte_TVD+min"
                type="string"
                placeholder="-"
              />
              <input
                name="boorgatdiepte_TVD+max"
                formControlName="boorgatdiepte_TVD+max"
                type="string"
                placeholder="-"
              />
            </div>
          </div>

          <div
            *ngIf="
              typeControl.value === this.boreholeTypeEnum.Borehole ||
              typeControl.value === this.boreholeTypeEnum.GeothermalBorehole
            "
            class="px-8 mt-4"
          >
            <label class="mb-2 block text-sm" for="provincie_code">Status</label>
            <select
              class="w-full tno-select"
              [ngClass]="{ empty: !boreholeDataService.filtersForm.value.boorgatstatus }"
              formControlName="boorgatstatus"
              name="boorgatstatus"
            >
              <option value="">selecteer optie</option>
              <option *ngFor="let boreholeStatusOption of boreholeStatusOptions" [value]="boreholeStatusOption">
                {{ boreholeStatusOption }}
              </option>
            </select>
          </div>

          <div *ngIf="typeControl.value === this.boreholeTypeEnum.GeothermalBorehole" class="px-8 mt-4">
            <label class="mb-2 block text-sm" for="mijnbouwwerk_naam">Mijnbouwwerk naam</label>
            <input type="text" name="mijnbouwwerk_naam" formControlName="mijnbouwwerk_naam" class="input" />
          </div>

          <div *ngIf="typeControl.value === this.boreholeTypeEnum.GeothermalBorehole" class="px-8 mt-4">
            <label class="mb-2 block text-sm" for="mijnbouwwerk_code">Mijnbouwwerk code</label>
            <input type="text" name="mijnbouwwerk_code" formControlName="mijnbouwwerk_code" class="input" />
          </div>

          <div *ngIf="typeControl.value === 'SDE'" class="px-8 mt-4">
            <label class="mb-2 block text-sm" for="sde.scenarioInformations.id">Scenario</label>
            <select
              class="w-full tno-select"
              [ngClass]="{ empty: !boreholeDataService.filtersForm.value['sde.scenarioInformations.id'] }"
              formControlName="sde.scenarioInformations.id"
              name="sde.scenarioInformations.id"
            >
              <option value="">selecteer optie</option>
              <option *ngFor="let scenarioOption of scenarioOptions" [value]="scenarioOption.id">
                {{ scenarioOption.scenario }}
              </option>
            </select>
          </div>

          <div
            *ngIf="typeControl.value === this.boreholeTypeEnum.Facilities || typeControl.value === 'SDE'"
            class="px-8 mt-4"
          >
            <label class="mb-2 block text-sm" for="sde.aanvrager_naam">Aanvrager naam</label>
            <input type="text" name="sde.aanvrager_naam" formControlName="sde.aanvrager_naam" class="input" />
          </div>

          <div
            *ngIf="typeControl.value === this.boreholeTypeEnum.Facilities || typeControl.value === 'SDE'"
            class="px-8 mt-4"
          >
            <label class="mb-2 block text-sm" for="mijnbouwwerk_naam">Facility naam</label>
            <input type="text" name="mijnbouwwerk_naam" formControlName="mijnbouwwerk_naam" class="input" />
          </div>

          <ng-container *ngIf="typeControl.value === 'SDE'">
            <div class="mt-8 grid grid-cols-1 gap-4 px-8" formGroupName="scenario_informations">
              <div class="flex flex-col justify-end" formGroupName="prodtopreservoirdepthlikely">
                <div class="py-2">
                  <label class="block text-sm">Top diepte aquifer bij producer (m TVD)</label>
                </div>
                <div class="">
                  <div class="range-input">
                    <input
                      formControlName="min"
                      name="prodtopreservoirdepthlikely_min"
                      type="string"
                      [min]="boreholeDataService.minMaxScenarioInformationRanges.prodtopreservoirdepthlikely"
                      [max]="boreholeDataService.minMaxScenarioInformationRanges.prodtopreservoirdepthlikely"
                      placeholder="-"
                    />
                    <input
                      formControlName="max"
                      name="prodtopreservoirdepthlikely_max"
                      type="string"
                      [min]="boreholeDataService.minMaxScenarioInformationRanges.prodtopreservoirdepthlikely"
                      [max]="boreholeDataService.minMaxScenarioInformationRanges.prodtopreservoirdepthlikely"
                      placeholder="-"
                    />
                  </div>
                </div>
              </div>

              <div class="flex flex-col justify-end" formGroupName="permeabilitylikely">
                <div class="py-2">
                  <label class="block text-sm">Permeabiliteit (mD)</label>
                </div>
                <div class="">
                  <div class="range-input">
                    <input
                      formControlName="min"
                      name="permeabilitylikely_min"
                      type="string"
                      [min]="boreholeDataService.minMaxScenarioInformationRanges.permeabilitylikely"
                      [max]="boreholeDataService.minMaxScenarioInformationRanges.permeabilitylikely"
                      placeholder="-"
                    />
                    <input
                      formControlName="max"
                      name="permeabilitylikely_max"
                      type="string"
                      [min]="boreholeDataService.minMaxScenarioInformationRanges.permeabilitylikely"
                      [max]="boreholeDataService.minMaxScenarioInformationRanges.permeabilitylikely"
                      placeholder="-"
                    />
                  </div>
                </div>
              </div>

              <div class="flex flex-col justify-end" formGroupName="thicknesslikely">
                <div class="py-2">
                  <label class="block text-sm">Dikte (m)</label>
                </div>
                <div class="">
                  <div class="range-input">
                    <input
                      formControlName="min"
                      name="thicknesslikely_min"
                      type="string"
                      [min]="boreholeDataService.minMaxScenarioInformationRanges.thicknesslikely"
                      [max]="boreholeDataService.minMaxScenarioInformationRanges.thicknesslikely"
                      placeholder="-"
                    />
                    <input
                      formControlName="max"
                      name="thicknesslikely_max"
                      type="string"
                      [min]="boreholeDataService.minMaxScenarioInformationRanges.thicknesslikely"
                      [max]="boreholeDataService.minMaxScenarioInformationRanges.thicknesslikely"
                      placeholder="-"
                    />
                  </div>
                </div>
              </div>

              <div class="flex flex-col justify-end" formGroupName="p50_aquifer_k_h_net_dm">
                <div class="py-2">
                  <label class="block text-sm">Netto transmissiviteit (Dm)</label>
                </div>
                <div class="">
                  <div class="range-input">
                    <input
                      formControlName="min"
                      name="p50_aquifer_k_h_net_dm_min"
                      type="string"
                      [min]="boreholeDataService.minMaxScenarioInformationRanges.p50_aquifer_k_h_net_dm"
                      [max]="boreholeDataService.minMaxScenarioInformationRanges.p50_aquifer_k_h_net_dm"
                      placeholder="-"
                    />
                    <input
                      formControlName="max"
                      name="p50_aquifer_k_h_net_dm_max"
                      type="string"
                      [min]="boreholeDataService.minMaxScenarioInformationRanges.p50_aquifer_k_h_net_dm"
                      [max]="boreholeDataService.minMaxScenarioInformationRanges.p50_aquifer_k_h_net_dm"
                      placeholder="-"
                    />
                  </div>
                </div>
              </div>

              <div class="flex flex-col justify-end" formGroupName="p50_pump_volume_flow_m3_h">
                <div class="py-2">
                  <label class="block text-sm">Pomp debiet (m³/h)</label>
                </div>
                <div class="">
                  <div class="range-input">
                    <input
                      formControlName="min"
                      name="p50_pump_volume_flow_m3_h_min"
                      type="string"
                      [min]="boreholeDataService.minMaxScenarioInformationRanges.p50_pump_volume_flow_m3_h"
                      [max]="boreholeDataService.minMaxScenarioInformationRanges.p50_pump_volume_flow_m3_h"
                      placeholder="-"
                    />
                    <input
                      formControlName="max"
                      name="p50_pump_volume_flow_m3_h_max"
                      type="string"
                      [min]="boreholeDataService.minMaxScenarioInformationRanges.p50_pump_volume_flow_m3_h"
                      [max]="boreholeDataService.minMaxScenarioInformationRanges.p50_pump_volume_flow_m3_h"
                      placeholder="-"
                    />
                  </div>
                </div>
              </div>

              <div class="flex flex-col justify-end" formGroupName="p50_geothermal_power_mw">
                <div class="py-2">
                  <label class="block text-sm">Geothermisch vermogen (MW)</label>
                </div>
                <div class="">
                  <div class="range-input">
                    <input
                      formControlName="min"
                      name="p50_geothermal_power_mw_min"
                      type="string"
                      [min]="boreholeDataService.minMaxScenarioInformationRanges.p50_geothermal_power_mw"
                      [max]="boreholeDataService.minMaxScenarioInformationRanges.p50_geothermal_power_mw"
                      placeholder="-"
                    />
                    <input
                      formControlName="max"
                      name="p50_geothermal_power_mw_max"
                      type="string"
                      [min]="boreholeDataService.minMaxScenarioInformationRanges.p50_geothermal_power_mw"
                      [max]="boreholeDataService.minMaxScenarioInformationRanges.p50_geothermal_power_mw"
                      placeholder="-"
                    />
                  </div>
                </div>
              </div>

              <div class="flex flex-col justify-end" formGroupName="p50_aquifer_pressure_at_producer_bar">
                <div class="py-2">
                  <label class="block text-sm">Aquifer druk bij producer (bar)</label>
                </div>
                <div class="">
                  <div class="range-input">
                    <input
                      formControlName="min"
                      name="p50_aquifer_pressure_at_producer_bar_min"
                      type="string"
                      [min]="boreholeDataService.minMaxScenarioInformationRanges.p50_aquifer_pressure_at_producer_bar"
                      [max]="boreholeDataService.minMaxScenarioInformationRanges.p50_aquifer_pressure_at_producer_bar"
                      placeholder="-"
                    />
                    <input
                      formControlName="max"
                      name="p50_aquifer_pressure_at_producer_bar_max"
                      type="string"
                      [min]="boreholeDataService.minMaxScenarioInformationRanges.p50_aquifer_pressure_at_producer_bar"
                      [max]="boreholeDataService.minMaxScenarioInformationRanges.p50_aquifer_pressure_at_producer_bar"
                      placeholder="-"
                    />
                  </div>
                </div>
              </div>

              <div class="flex flex-col justify-end" formGroupName="p50_pressure_difference_at_injector_bar">
                <div class="py-2">
                  <label class="block text-sm">Druk verschil bij injector (bar)</label>
                </div>
                <div class="">
                  <div class="range-input">
                    <input
                      formControlName="min"
                      name="p50_pressure_difference_at_injector_bar_min"
                      type="string"
                      [min]="
                        boreholeDataService.minMaxScenarioInformationRanges.p50_pressure_difference_at_injector_bar
                      "
                      placeholder="-"
                    />
                    <input
                      formControlName="max"
                      name="p50_pressure_difference_at_injector_bar_max"
                      type="string"
                      [min]="boreholeDataService.minMaxScenarioInformationRanges.p50_aquifer_temperature_at_producer_c"
                      [max]="
                        boreholeDataService.minMaxScenarioInformationRanges.p50_pressure_difference_at_injector_bar
                      "
                      placeholder="-"
                    />
                  </div>
                </div>
              </div>

              <div class="flex flex-col justify-end" formGroupName="p50_aquifer_temperature_at_producer_c">
                <div class="py-2">
                  <label class="block text-sm">Aquifer temperatuur bij producer (°C)</label>
                </div>
                <div class="">
                  <div class="range-input">
                    <input
                      formControlName="min"
                      name="p50_aquifer_temperature_at_producer_c_min"
                      type="string"
                      [min]="boreholeDataService.minMaxScenarioInformationRanges.p50_aquifer_temperature_at_producer_c"
                      [max]="boreholeDataService.minMaxScenarioInformationRanges.p50_aquifer_temperature_at_producer_c"
                      placeholder="-"
                    />
                    <input
                      formControlName="max"
                      name="p50_aquifer_temperature_at_producer_c_max"
                      type="string"
                      [min]="boreholeDataService.minMaxScenarioInformationRanges.p50_temperature_at_heat_exchanger_c"
                      [max]="boreholeDataService.minMaxScenarioInformationRanges.p50_aquifer_temperature_at_producer_c"
                      placeholder="-"
                    />
                  </div>
                </div>
              </div>

              <div class="flex flex-col justify-end" formGroupName="p50_temperature_at_heat_exchanger_c">
                <div class="py-2">
                  <label class="block text-sm">Temperatuur bij warmtewisselaar (°C)</label>
                </div>
                <div class="">
                  <div class="range-input">
                    <input
                      formControlName="min"
                      name="p50_temperature_at_heat_exchanger_c_min"
                      type="string"
                      [min]="boreholeDataService.minMaxScenarioInformationRanges.p50_temperature_at_heat_exchanger_c"
                      [max]="boreholeDataService.minMaxScenarioInformationRanges.p50_temperature_at_heat_exchanger_c"
                      placeholder="-"
                    />
                    <input
                      formControlName="max"
                      name="p50_temperature_at_heat_exchanger_c_max"
                      type="string"
                      [max]="boreholeDataService.minMaxScenarioInformationRanges.p50_temperature_at_heat_exchanger_c"
                      placeholder="-"
                    />
                  </div>
                </div>
              </div>
            </div>
          </ng-container>
        </form>
      </div>
    </div>
  </div>
</div>
