<ng-container *ngIf="shouldShowMaps$ | async">
  <app-map
    class="w-full h-full absolute inset-0 transition-all duration-700"
    [ngStyle]="{ height: proModusService.isEnabled ? 'calc(100vh - 42px)' : '100vh' }"
    [locationsSubject]="locationsSubject"
    (boreholeClick)="handleBoreholeClick($event)"
    (miningLocationClick)="handleMiningLocationClick($event)"
  >
  </app-map>
</ng-container>
<div
  class="relative flex flex-col pointer-events-none transition-all duration-700"
  [ngStyle]="{
    height: proModusService.isEnabled ? 'calc(100vh - 42px)' : '100vh',
    'max-height': proModusService.isEnabled ? 'calc(100vh - 42px)' : '100vh'
  }"
>
  <div class="p-2 flex items-start flex-0" style="background-color: #4a90e3">
    <ng-container *ngIf="userService.getIsLoggedIn; else logInButton">
        <div
          class="btn right-3 absolute hover:border-white rounded pointer-events-auto cursor-pointer flex items-center"
          (click)="handleLogOut()"
        >
          <span class="mr-2">Uitloggen</span>
          <ion-icon name="log-out-outline" size="large"> </ion-icon>
        </div>
      </ng-container>
      <ng-template #logInButton>
        <div
          class="btn right-3 absolute hover:border-white rounded pointer-events-auto cursor-pointer flex items-center"
          (click)="handleLogIn()"
        >
          <span class="mr-2">Inloggen</span>
          <ion-icon name="log-in-outline" size="large"></ion-icon>
        </div>
      </ng-template>
    <a class="flex items-center flex-0" style="width: 400px">
      <img src="/assets/images/logo-white.png" class="w-16 h-16 mr-2" />
      <h1 class="heading text-3xl text-white">Geothermie in nederland</h1>
    </a>

    <div *ngIf="proModusService.isEnabled" class="flex-1 flex items-start justify-start px-4 pointer-events-auto text-white">
      <app-graph-button
        title="Temperatuur"
        [selectedCount]="selectedBoreholeCount"
        (click)="openTemperatureGraph()"
      ></app-graph-button>
      <app-graph-button
        title="Porositeit"
        [selectedCount]="selectedBoreholeCount"
        (click)="openPorosityGraph()"
      ></app-graph-button>
      <app-graph-button
        title="Permeabiliteit"
        [selectedCount]="selectedBoreholeCount"
        (click)="openPermeabilityGraph()"
      ></app-graph-button>
      <app-graph-button
        title="Vermogen/Diepte"
        [selectedCount]="selectedMiningLocationCount"
        (click)="openPowerdepthGraph()"
      ></app-graph-button>
      <app-graph-button
        title="Vermogen/Debiet"
        [selectedCount]="selectedMiningLocationCount"
        (click)="openPowerFlowGraph()"
      ></app-graph-button>
    </div>
  </div>

  <div class="p-4 flex justify-between items-stretch py-10 pointer-events-none overflow-hidden">
    <div class="flex justify-between items-stretch">
      <div class="bg-white pointer-events-auto flex flex-col" style="width: 400px">
        <div class="p-8 pb-4">
          <h2 *ngIf="!proModusService.isEnabled" class="text-3xl heading">Functies</h2>
          <h2 *ngIf="proModusService.isEnabled" class="text-3xl heading">
            <span class="text-red">Pro</span> zoek modus
          </h2>
        </div>

        <app-tabs
          tabbarClass="px-8"
          class="flex flex-col overflow-hidden"
          [hideTabBar]="proModusService.isEnabled"
        >
          <app-tab icon="information-circle" containerClass="overflow-auto">
            <div class="px-8 my-8">
              <h3 class="font-bold text-xl mb-2">Wat is geothermie?</h3>
              <p>
                Geothermie is duurzame warmte uit de ondergrond waarmee je huizen, gebouwen en kassen kunt verwarmen.
                Diep in de ondergrond bevindt zich warm water in (poreuze) zand- en gesteentelagen. Geothermie ofwel
                aardwarmte, wordt gewonnen uit heet water in deze lagen. In Nederland wordt aardwarmte doorgaans
                gewonnen op een diepte van tussen de 2 á 3 km, waarbij het water een temperatuur heeft van 70 – 90 ℃.
                Meer informatie op geothermie.nl en allesoveraardwarmte.nl.
              </p>
              <p class="mt-4">
                Deze website biedt u de mogelijkheid om basisdata die belangrijk zijn voor geothermie en geothermische
                installaties te bekijken en te downloaden. Dit is een bèta versie waarvoor geen garantie wordt gegeven
                voor de actualiteit en acuuraatheid van de gepresenteerde data.
              </p>

              <div class="flex space-x-4 mt-8">
                <img src="/assets/images/intro-foto1.png" alt="Wat is aardwarmte" class="flex-1 w-28" />
                <img src="/assets/images/intro-foto2.png" alt="Wat is aardwarmte" class="flex-1 w-28" />
                <img src="/assets/images/intro-foto3.png" alt="Wat is aardwarmte" class="flex-1 w-28" />
              </div>
            </div>
          </app-tab>
          <app-tab icon="search" containerClass="flex-1 flex flex-col">
            <div class="flex-1 flex flex-col">
              <div class="px-8">
                <div class="relative">
                  <input
                    name="search"
                    autocomplete="off"
                    placeholder="selecteer of zoek"
                    class="
                      bg-transparent
                      border-b border-gray-300 border-opacity-50
                      focus:border-opacity-100
                      w-full
                      px-1
                      py-4
                      mt-4
                      focus:outline-none
                    "
                    [formControl]="boreholeDataService.searchQuery"
                    (keydown.enter)="boreholeDataService.refetch()"
                  />
                  <ion-icon
                    name="search"
                    class="text-white text-2xl pointer-events-none absolute top-1/2 transform right-3"
                  ></ion-icon>
                </div>
              </div>

              <div class="flex justify-between items-center p-8">
                <span>
                  {{ proModusService.isEnabled ? 'Deactiveer' : 'Activeer' }}
                  <span class="text-red uppercase font-bold">pro</span> zoek modus
                </span>
                <app-toggle [control]="proModusControl" name="pro_modus"></app-toggle>
              </div>

              <app-nonpro-modus *ngIf="!proModusService.isEnabled"></app-nonpro-modus>
              <app-pro-modus *ngIf="proModusService.isEnabled"></app-pro-modus>
            </div>
          </app-tab>
          <app-tab icon="help-circle">
            <p class="px-8 my-8">Bekijk hier een instructiefilm voor het gebruik van de website.</p>
            <div class="p-8">
              <iframe
                width="350"
                height="300"
                src="https://www.youtube.com/embed/f7iQjDAluJA"
                title="Geothermieplatform instructiefilm"
                frameborder="0"
                allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
                allowfullscreen
              ></iframe>
            </div>
          </app-tab>
        </app-tabs>
      </div>
      <div *ngIf="proModusService.isEnabled" class="flex flex-col overflow-hidden">
        <app-tools></app-tools>
        <app-filters></app-filters>
      </div>
    </div>
    <div>
      <app-map-options></app-map-options>
      <app-map-legend></app-map-legend>
    </div>
  </div>
</div>
<div
  class="
    absolute
    block
    w-full
    bottom-0
    left-0
    right-0
    bg-red bg-opacity-75
    z-10
    uppercase
    transform
    transition-transform
    duration-700
  "
  [ngClass]="{ 'translate-y-full': !proModusService.isEnabled, 'translate-y-0': proModusService.isEnabled }"
  style="height: 42px"
>
  <div class="container mx-auto p-4 flex items-center">
    <img src="/assets/svg/pro-modus-active.svg" alt="Pro modus is actief" class="w-5 mr-4" /> Pro modus is acief
  </div>
</div>
