<div class="mb-5 flex">
  <div class="w-1/2">
    <div class="font-bold leading-6">Provincie</div>
    <div>
      {{ borehole.provincie_naam ? borehole.provincie_naam : '-' }} ({{
        borehole.provincie_code ? borehole.provincie_code : '-'
      }})
    </div>
  </div>
  <div class="w-1/2">
    <div class="font-bold">Gemeente (ID)</div>
    <div>
      {{ borehole.gemeente_naam ? borehole.gemeente_naam : '-' }} ({{
        borehole.gemeente_id ? borehole.gemeente_id : '-'
      }})
    </div>
  </div>
</div>

<div class="mb-5 flex">
  <div class="w-1/2">
    <div class="font-bold leading-6">Postcode</div>
    <div>{{ borehole.postcode ? borehole.postcode : '-' }}</div>
  </div>
</div>

<div class="mb-5 flex">
  <div class="w-1/2">
    <div class="font-bold leading-6">Longitude - Latitude</div>
    <div>{{ borehole.longitude_ED50 }} - {{ borehole.latitude_ED50 }}</div>
  </div>
  <div class="w-1/2">
    <div class="font-bold">X-Y Rijksdriehoek</div>
    <div>{{ borehole.x_rijksdriehoek }} - {{ borehole.y_rijksdriehoek }}</div>
  </div>
</div>
