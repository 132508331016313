import { Component, Inject, OnInit } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { Apollo } from 'apollo-angular';
import { boreholeDetailsQuery, boreholeQuery } from 'src/app/graphql/queries/queries';
import { Borehole } from 'src/app/graphql/types/default';
import { downloadHelper } from '@helpers/mapping-helper';
import { DownloadService } from '@services/download.service';
import { exportBoreholeQuery, exportTemperatureQuery } from 'src/app/graphql/queries/export';

@Component({
  selector: 'app-borehole-details',
  templateUrl: './borehole-details.component.html',
  styleUrls: ['./borehole-details.component.scss'],
})
export class BoreholeDetailsComponent implements OnInit {
  public borehole!: Borehole;
  public isDownloading!: boolean;

  constructor(
    private apollo: Apollo,
    public dialogRef: MatDialogRef<BoreholeDetailsComponent>,
    @Inject(MAT_DIALOG_DATA) public data: { id: number },
    private downloadService: DownloadService
  ) {}

  ngOnInit(): void {
    this.fetchBorehole();
  }

  private fetchBorehole(): void {
    this.apollo
      .query<{ borehole: Borehole[] }>({
        query: boreholeDetailsQuery,
        variables: {
          order: null,
          filters: [
            {
              field: 'id',
              values: [this.data.id.toString()],
            },
          ],
        },
      })
      .subscribe((response) => {
        if (response.data) {
          this.borehole = response.data.borehole[0];
        }
      });
  }

  doExport(): void {
    this.isDownloading = true;
    this.apollo
      .query({
        query: exportBoreholeQuery,
        fetchPolicy: 'no-cache',
        variables: {
          boreholeDownloadable: {
            filters: [
              {
                field: 'id',
                values: [this.data.id.toString()],
              },
            ],
          },
        },
      })
      .subscribe((result) => {
        if (result.data) {
          this.downloadService.downloadExport((<any>result.data).export).subscribe((res) => {
            downloadHelper(res, (<any>result.data).export);
            this.isDownloading = false;
          });
        }
      });
  }
}
