<div class="flex items-stretch flex-1 py-0 pointer-events-none overflow-hidden">
  <div class="bg-white pointer-events-auto flex flex-col" style="width: 300px">
    <div class="p-8">
      <div class="flex justify-between align-middle">
        <h2 class="text-2xl font-bold">Kaart opties</h2>
        <div *ngIf="!hideOptionMenu" class="cursor-pointer text-2xl" (click)="toggleMenu()">-</div>
        <div *ngIf="hideOptionMenu" class="cursor-pointer text-2xl" (click)="toggleMenu()">+</div>
      </div>
    </div>
    <div *ngIf="!hideOptionMenu" class="px-8 overflow-auto mb-4">
      <p>
        Kies één of meer van de onderstaande kaartopties als toevoeging op de basiskaart. Je kunt ze hier aan of uit
        zetten.
      </p>
      <div class="mt-4">
        <label class="flex items-center p-2">
          <app-toggle color="brand-blue" [control]="warmtenettenControl" name="warmtenetten" class="mr-2"></app-toggle>
          Warmtenetten
        </label>
        <label class="flex items-center p-2">
          <app-toggle color="brand-blue" [control]="woonkernenControl" name="woonkernen" class="mr-2"></app-toggle>
          Woonkernen
        </label>
        <label class="flex items-center p-2">
          <app-toggle color="brand-blue" [control]="kassenControl" name="kassen" class="mr-2"></app-toggle>
          Kassen
        </label>

        <label class="flex items-center p-2">
          <app-toggle color="brand-blue" [control]="thermoGis629Control" name="thermoGis629" class="mr-2"></app-toggle>
          Berekend vermogen ThermoGIS
        </label>
        <div *ngIf="thermoGis629Control.value" class="p-2">
          <button class="btn min-w-25" mat-button (click)="openLegendPage()">Legenda ThermoGIS kaart</button>
        </div>
      </div>
    </div>
  </div>
</div>
