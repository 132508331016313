import { Apollo } from 'apollo-angular';
import { HttpLink } from 'apollo-angular/http';
import { InMemoryCache, ApolloLink } from '@apollo/client/core';

import { NgModule } from '@angular/core';
import { environment } from 'src/environments/environment';
import introspectionQueryResultData from './graphql/introspection-result';

@NgModule({
  exports: [],
})
export class GraphQLModule {
  constructor(apollo: Apollo, httpLink: HttpLink) {
    const uri = environment.graphURI;
    let http = httpLink.create({ uri });

    const authLink = new ApolloLink((operation, forward) => {
      // Get the authentication token from local storage if it exists
      const token = localStorage.getItem('api_token');

      // Use the setContext method to set the HTTP headers.
      operation.setContext({
        headers: {
          Authorization: token ? `Bearer ${token}` : '',
        },
      });

      // Call the next link in the middleware chain.
      return forward(operation);
    });

    // auth schema
    apollo.create(
      {
        connectToDevTools: true,
        link: http,
        cache: new InMemoryCache({
          addTypename: true,
        }),
      },
      'auth'
    );

    // default schema
    http = httpLink.create({ uri: `${uri}` });
    apollo.create({
      connectToDevTools: true,
      link: authLink.concat(http),
      cache: new InMemoryCache({
        addTypename: true,
        possibleTypes: introspectionQueryResultData.possibleTypes,
        typePolicies: {
          Visit: {
            fields: {
              notes: {
                merge(existing = [], incoming: any[]) {
                  return [...incoming];
                },
              },
            },
          },
        },
      }),
    });
  }
}
