<div class="mb-5 flex">
  <div class="w-1/2">
    <div class="font-bold leading-6">Mijnbouwwerk naam</div>
    <div>{{ borehole.mijnbouwwerk_naam ? borehole.mijnbouwwerk_naam : '-' }}</div>
  </div>
  <div class="w-1/2">
    <div class="font-bold">Mijnbouwwerk code</div>
    <div>{{ borehole.mijnbouwwerk_code ? borehole.mijnbouwwerk_code : '-' }}</div>
  </div>
</div>

<div class="mb-5 flex">
  <div class="w-1/2">
    <div class="font-bold leading-6">Huidige eigenaar</div>
    <div>{{ borehole.huidige_eigenaar ? borehole.huidige_eigenaar : '-' }}</div>
  </div>
  <div class="w-1/2">
    <div class="font-bold">Opdrachtgever</div>
    <div>{{ borehole.opdrachtgever ? borehole.opdrachtgever : '-' }}</div>
  </div>
</div>

<div class="mb-5 flex">
  <div class="w-1/2">
    <div class="font-bold leading-6">UWI</div>
    <div>{{ borehole.uWI ? borehole.uWI : '-' }}</div>
  </div>
  <div class="w-1/2">
    <div class="font-bold">
      NITG nummer
      <button
        matTooltip="DINO identificatienummer: Alle gegevens in DINO hebben een NITG-nummer dat bestaat uit een kaartbladgedeelte, bijvoorbeeld 25A, en een volgnummer."
        aria-label="DINO identificatienummer."
        matTooltipClass="tooltip-blue"
      >
        <ion-icon class="inline" name="information-circle" class="pointer-events-none"></ion-icon>
      </button>
    </div>
    <div>{{ borehole.nITG_nummer ? borehole.nITG_nummer : '-' }}</div>
  </div>
</div>

<div class="mb-5 flex">
  <div class="w-1/2">
    <div class="font-bold leading-6">Boorgatcode (Voluit)</div>
    <div>{{ borehole.boorgatnaam ? borehole.boorgatnaam : '-' }}</div>
  </div>
  <div class="w-1/2">
    <div class="font-bold">Boorgatcode (Afkorting)</div>
    <div>{{ borehole.boorgatcode ? borehole.boorgatcode : '-' }}</div>
  </div>
</div>

<div class="mb-5 flex">
  <div class="w-1/2">
    <div class="font-bold leading-6">Boorgatdoel code</div>
    <div>{{ borehole.boorgatdoel_code ? borehole.boorgatdoel_code : '-' }}</div>
  </div>
</div>

<div class="mb-5 flex">
  <div class="w-1/2">
    <div class="font-bold leading-6">Veld naam</div>
    <div>{{ borehole.veld_naam ? borehole.veld_naam : '-' }}</div>
  </div>
  <div class="w-1/2">
    <div class="font-bold">Veld code</div>
    <div>{{ borehole.veld_code ? borehole.veld_code : '-' }}</div>
  </div>
</div>

<div class="mb-5 flex">
  <div class="w-1/2">
    <div class="font-bold leading-6">Boorgatdoel</div>
    <div>{{ borehole.boorgatdoel ? borehole.boorgatdoel : '-' }}</div>
  </div>
  <div class="w-1/2">
    <div class="font-bold">Boorgatresultaat</div>
    <div>{{ borehole.boorgatresultaat ? borehole.boorgatresultaat : '-' }}</div>
  </div>
</div>

<div class="mb-5 flex">
  <div class="w-1/2">
    <div class="font-bold leading-6">Boorgatresultaat code</div>
    <div>{{ borehole.boorgatresultaat_code ? borehole.boorgatresultaat_code : '-' }}</div>
  </div>
  <div class="w-1/2">
    <div class="font-bold">Boorgatstatus</div>
    <div>{{ borehole.boorgatstatus ? borehole.boorgatstatus : '-' }}</div>
  </div>
</div>

<div class="mb-5 flex">
  <div class="w-1/2">
    <div class="font-bold leading-6">Boorgatvorm</div>
    <div>{{ borehole.boorgatvorm ? borehole.boorgatvorm : '-' }}</div>
  </div>
</div>
