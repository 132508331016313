<div class="w-full bg-white">
  <div class="p-8 pb-0 mb-4">
    <div class="flex items-center">
      <h2 class="text-3xl heading">SDE Scenarios</h2>
    </div>
    <span class="text-brand-blue text-2xl heading">{{ data.miningLocation.mijnbouwwerk_naam }}</span>
  </div>

  <div *ngIf="!scenarioInformations" class="flex items-center justify-around p-10">
    <mat-spinner class="custom-material-styles" [diameter]="20"></mat-spinner>
  </div>

  <div class="flex" *ngIf="scenarioInformations && scenarioInformations.length > 0">
    <div class="w-3/5 p-8 pr-4">
      <ng-container *ngIf="selectedScenario">
        <table>
          <thead>
            <tr class="text-left text-sm mb-4">
              <td class="w-2/5 pl-8 font-bold text-gray-400 leading-6">Parameter</td>
              <td class="w-1/5 font-bold text-gray-400 leading-6">Minimum</td>
              <td class="w-1/5 font-bold text-gray-400 leading-6">Gemiddeld</td>
              <td class="w-1/5 pr-8 font-bold text-gray-400 leading-6">Maximum</td>
            </tr>
          </thead>

          <tbody class="border-b border-gray-700">
            <tr class="pb-2 pt-2 border-t border-gray-700">
              <td class="w-2/5 pb-2 pt-2 font-bold leading-6 align-middle pr-2 pl-8">Netto transmissiviteit (Dm)</td>
              <td class="w-1/5 pb-2 pt-2 leading-6">
                {{ selectedScenario.p90_aquifer_k_h_net_dm ? selectedScenario.p90_aquifer_k_h_net_dm + ' Dm' : '-' }}
              </td>
              <td class="w-1/5 pb-2 pt-2 leading-6">
                {{ selectedScenario.p50_aquifer_k_h_net_dm ? selectedScenario.p50_aquifer_k_h_net_dm + ' Dm' : '-' }}
              </td>
              <td class="w-1/5 pb-2 pt-2 leading-6 pr-4">
                {{ selectedScenario.p10_aquifer_k_h_net_dm ? selectedScenario.p10_aquifer_k_h_net_dm + ' Dm' : '-' }}
              </td>
            </tr>

            <tr class="border-t border-gray-700">
              <td class="w-2/5 pb-2 pt-2 font-bold leading-6 pr-2 pl-8">Pomp debiet (m³/h)</td>
              <td class="w-1/5 pb-2 pt-2 leading-6">
                {{
                  selectedScenario.p90_pump_volume_flow_m3_h
                    ? selectedScenario.p90_pump_volume_flow_m3_h + ' m³/h'
                    : '-'
                }}
              </td>
              <td class="w-1/5 pb-2 pt-2 leading-6">
                {{
                  selectedScenario.p50_pump_volume_flow_m3_h
                    ? selectedScenario.p50_pump_volume_flow_m3_h + ' m³/h'
                    : '-'
                }}
              </td>
              <td class="w-1/5 pb-2 pt-2 leading-6 pr-4">
                {{
                  selectedScenario.p10_pump_volume_flow_m3_h
                    ? selectedScenario.p10_pump_volume_flow_m3_h + ' m³/h'
                    : '-'
                }}
              </td>
            </tr>

            <tr class="border-t border-gray-700">
              <td class="w-2/5 pb-2 pt-2 font-bold leading-6 pr-2 pl-8">Geothermisch vermogen (MW)</td>
              <td class="w-1/5 pb-2 pt-2 leading-6">
                {{ selectedScenario.p90_geothermal_power_mw ? selectedScenario.p90_geothermal_power_mw + ' MW' : '-' }}
              </td>
              <td class="w-1/5 pb-2 pt-2 leading-6">
                {{ selectedScenario.p50_geothermal_power_mw ? selectedScenario.p50_geothermal_power_mw + ' MW' : '-' }}
              </td>
              <td class="w-1/5 pb-2 pt-2 leading-6 pr-4">
                {{ selectedScenario.p10_geothermal_power_mw ? selectedScenario.p10_geothermal_power_mw + ' MW' : '-' }}
              </td>
            </tr>

            <tr class="border-t border-gray-700">
              <td class="w-2/5 pb-2 pt-2 font-bold leading-6 pl-8">
                Aquifer druk bij <br />
                producer (bar)
              </td>
              <td class="w-1/5 pb-2 pt-2 leading-6">
                {{
                  selectedScenario.p90_aquifer_pressure_at_producer_bar
                    ? selectedScenario.p90_aquifer_pressure_at_producer_bar + ' bar'
                    : '-'
                }}
              </td>
              <td class="w-1/5 pb-2 pt-2 leading-6">
                {{
                  selectedScenario.p50_aquifer_pressure_at_producer_bar
                    ? selectedScenario.p50_aquifer_pressure_at_producer_bar + ' bar'
                    : '-'
                }}
              </td>
              <td class="w-1/5 pb-2 pt-2 leading-6 pr-4">
                {{
                  selectedScenario.p10_aquifer_pressure_at_producer_bar
                    ? selectedScenario.p10_aquifer_pressure_at_producer_bar + ' bar'
                    : '-'
                }}
              </td>
            </tr>

            <tr class="border-t border-gray-700">
              <td class="w-2/5 pb-2 pt-2 font-bold leading-6 pr-2 pl-8">Druk verschil bij injector (bar)</td>
              <td class="w-1/5 pb-2 pt-2 leading-6">
                {{
                  selectedScenario.p90_pressure_difference_at_injector_bar
                    ? selectedScenario.p90_pressure_difference_at_injector_bar + ' bar'
                    : '-'
                }}
              </td>
              <td class="w-1/5 pb-2 pt-2 leading-6">
                {{
                  selectedScenario.p50_pressure_difference_at_injector_bar
                    ? selectedScenario.p50_pressure_difference_at_injector_bar + ' bar'
                    : '-'
                }}
              </td>
              <td class="w-1/5 pb-2 pt-2 leading-6 pr-4">
                {{
                  selectedScenario.p10_pressure_difference_at_injector_bar
                    ? selectedScenario.p10_pressure_difference_at_injector_bar + ' bar'
                    : '-'
                }}
              </td>
            </tr>

            <tr class="border-t border-gray-700">
              <td class="w-2/5 pb-2 pt-2 font-bold leading-6 pr-2 pl-8">Aquifer temperatuur bij producer (°C)</td>
              <td class="w-1/5 pb-2 pt-2 leading-6">
                {{
                  selectedScenario.p90_aquifer_temperature_at_producer_c
                    ? selectedScenario.p90_aquifer_temperature_at_producer_c + ' °C'
                    : '-'
                }}
              </td>
              <td class="w-1/5 pb-2 pt-2 leading-6">
                {{
                  selectedScenario.p50_aquifer_temperature_at_producer_c
                    ? selectedScenario.p50_aquifer_temperature_at_producer_c + ' °C'
                    : '-'
                }}
              </td>
              <td class="w-1/5 pb-2 pt-2 leading-6 pr-4">
                {{
                  selectedScenario.p10_aquifer_temperature_at_producer_c
                    ? selectedScenario.p10_aquifer_temperature_at_producer_c + ' °C'
                    : '-'
                }}
              </td>
            </tr>

            <tr class="border-t border-gray-700">
              <td class="w-2/5 pb-2 pt-2 font-bold leading-6 pr-2 pl-8">Temperatuur bij warmtewisselaar (°C)</td>
              <td class="w-1/5 pb-2 pt-2 leading-6">
                {{
                  selectedScenario.p90_temperature_at_heat_exchanger_c
                    ? selectedScenario.p90_temperature_at_heat_exchanger_c + ' °C'
                    : '-'
                }}
              </td>
              <td class="w-1/5 pb-2 pt-2 leading-6">
                {{
                  selectedScenario.p50_temperature_at_heat_exchanger_c
                    ? selectedScenario.p50_temperature_at_heat_exchanger_c + ' °C'
                    : '-'
                }}
              </td>
              <td class="w-1/5 pb-2 pt-2 leading-6 pr-4">
                {{
                  selectedScenario.p10_temperature_at_heat_exchanger_c
                    ? selectedScenario.p10_temperature_at_heat_exchanger_c + ' °C'
                    : '-'
                }}
              </td>
            </tr>
          </tbody>
        </table>
      </ng-container>

      <ng-container *ngIf="!selectedScenario"><span class="text-gray-500">Geen scenarios</span></ng-container>
    </div>
    <div class="w-2/5 p-8 pl-2">
      <span class="text-sm">Selecteer een scenario</span>
      <div>
        <label *ngFor="let scenarioInformation of scenarioInformations" class="block py-2">
          <input
            type="radio"
            [value]="scenarioInformation.id"
            name="selected_scenario"
            [formControl]="selectedScenarioId"
          />
          {{ scenarioInformation.scenario }}
        </label>
      </div>
    </div>
  </div>

  <div class="p-8" *ngIf="scenarioInformations && scenarioInformations.length === 0">
    <span>Geen scenarios</span>
  </div>
</div>
<div class="flex space-x-4 justify-items-stretch p-8 bg-white">
  <button class="btn bg-white hover:bg-gray-100 text-black min-w-40" mat-button mat-dialog-close>Sluiten</button>
  <button class="btn min-w-40" mat-button (click)="doExport()">Export</button>
</div>
