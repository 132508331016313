import { Component, Input, OnInit, ViewEncapsulation } from '@angular/core';
import { Borehole } from 'src/app/graphql/types/default';
import { MAT_TOOLTIP_SCROLL_STRATEGY_FACTORY_PROVIDER } from '@angular/material/tooltip';

@Component({
  selector: 'app-information',
  templateUrl: './information.component.html',
  styleUrls: ['./information.component.scss'],
  providers: [MAT_TOOLTIP_SCROLL_STRATEGY_FACTORY_PROVIDER],
  encapsulation: ViewEncapsulation.None,
})
export class InformationComponent {
  @Input() borehole!: Borehole;
  constructor() {}
}
