import { Component, Input, OnInit } from '@angular/core';
import { Borehole, MiningLocation } from 'src/app/graphql/types/default';

@Component({
  selector: 'app-information',
  templateUrl: './information.component.html',
  styleUrls: ['./information.component.scss'],
})
export class InformationComponent {
  @Input() miningLocation!: MiningLocation;
  constructor() {}
}
