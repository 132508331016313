<div class="flex items-center justify-center">
  <form>
    <label class="relative w-8 h-5 flex select-none cursor-pointer">
      <input type="checkbox" [name]="name ? name : 'toggle'" class="hidden" [formControl]="control" />
      <span
        class="absolute left-0 top-0 h-full w-full rounded-full border-2 transition-all duration-500 bg-gray-100 border-white"
        [ngClass]="{
          'bg-red border-red': control.value && color === 'red',
          'bg-brand-blue border-brand-blue': control.value && color === 'brand-blue'
        }"
      ></span>

      <span
        class="h-5 w-5 absolute z-10 rounded-full ease-in-out flex justify-center items-center border-2 bg-gray-100 transition-all transform duration-500 border-white"
        [ngClass]="cssClass"
      ></span>
    </label>
  </form>
</div>
