<table class="w-full bg-white">
  <thead>
    <tr>
      <th class="heading text-brand-blue text-xl px-8 pt-4 pb-0 text-left" colspan="4">Boring</th>
    </tr>
    <tr class="text-left text-sm">
      <th class="text-gray-400 p-2 pb-4 pl-8">Naam</th>
      <th class="text-gray-400 p-2 pb-4">Temperatuur</th>
      <th class="text-gray-400 p-2 pb-4">Diepte temperatuur bepaling (TVD)</th>
      <th class="text-gray-400 p-2 pb-4 pr-8">Status</th>
    </tr>
  </thead>
  <tbody class="border-b border-gray-700">
    <tr *ngFor="let relatedBorehole of sortBoreholesBySiteTrack" class="border-t border-gray-700">
      <td class="font-bold text-base px-2 py-2 pl-4 pr-6">
        <div class="flex">
          <div *ngIf="isSideTrack(relatedBorehole.boorgatcode)" class="text-lg font-bold mr-2 ml-3">∟</div>
          <button (click)="handleBoreholeClick(relatedBorehole.id)">
            <img *ngIf="!relatedBorehole.isGeothermal" src="/assets/svg/borehole.svg" class="w-6 mr-3" />
            <img *ngIf="relatedBorehole.isGeothermal" src="/assets/svg/geothermal-borehole.svg" class="w-6 mr-3" />
          </button>
          <button (click)="openWaterChartForBorehole(relatedBorehole)" class="flex whitespace-nowrap">
            {{ relatedBorehole.boorgatcode ? relatedBorehole.boorgatcode : '-' }}
          </button>
        </div>
      </td>
      <td class="px-2 py-4 text-sm">
        <span
          class="text-red"
          *ngIf="
            relatedBorehole &&
            relatedBorehole.corrected_temperatures &&
            relatedBorehole.corrected_temperatures.length > 0
          "
        >
          {{ relatedBorehole.corrected_temperatures[0]?.temperature }}°C
        </span>
        <ng-container
          *ngIf="!relatedBorehole?.corrected_temperatures || relatedBorehole?.corrected_temperatures?.length === 0"
        >
          -
        </ng-container>
      </td>
      <td class="px-2 py-4 text-sm">
        <ng-container
          *ngIf="
            relatedBorehole &&
            relatedBorehole.corrected_temperatures &&
            relatedBorehole.corrected_temperatures.length > 0
          "
        >
          {{ relatedBorehole.corrected_temperatures[0]?.z }} meter
        </ng-container>
        <ng-container
          *ngIf="!relatedBorehole?.corrected_temperatures || relatedBorehole?.corrected_temperatures?.length === 0"
        >
          -
        </ng-container>
      </td>
      <td class="px-2 py-4 pr-8 underline text-sm">{{ relatedBorehole?.boorgatstatus }}</td>
    </tr>
  </tbody>
</table>
