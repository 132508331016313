<div class="flex w-full flex-col bg-white">
  <span class="text-3xl font-bold p-8">{{ data.heading }}</span>
  <div class="pr-4 pl-2">
    <canvas
      baseChart
      width="400"
      height="200"
      [datasets]="lineChartData"
      chartType="line"
      [labels]="lineChartLabels"
      [colors]="lineChartColors"
      [options]="lineChartOptions"
      [legend]="false"
    ></canvas>
  </div>
</div>
<div class="flex space-x-4 justify-items-stretch p-8 bg-white">
  <button class="btn bg-black hover:bg-gray-100 text-white min-w-40" mat-button mat-dialog-close>Sluiten</button>
  <button class="btn min-w-40" mat-button (click)="doExport()">Export</button>
</div>
