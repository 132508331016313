import { gql } from 'apollo-angular';
import { boreholeDetailFragment, boreholeListFragment } from '../fragments/borehole';

export const boreholeQuery = gql`
  query Borehole($order: orderBy, $filters: [filterBy], $excludes: [filterBy]) {
    borehole(order: $order, filters: $filters, excludes: $excludes, type: $type) {
      ...boreholeListFragment
    }
  }
  ${boreholeListFragment}
`;

export const boreholeDetailsQuery = gql`
  query Borehole($order: orderBy, $filters: [filterBy], $excludes: [filterBy]) {
    borehole(order: $order, filters: $filters, excludes: $excludes) {
      ...boreholeDetailFragment
    }
  }
  ${boreholeDetailFragment}
`;

export const boreholeStatus = gql`
  query Borehole($distinct: Boolean, $order: orderBy) {
    borehole(distinct: $distinct, order: $order) {
      boorgatstatus
    }
  }
`;

export const temperatureGraphQuery = gql`
  query Borehole($order: orderBy, $filters: [filterBy], $excludes: [filterBy]) {
    borehole(order: $order, filters: $filters, excludes: $excludes) {
      __typename
      id
      boorgatcode
      boorgatnaam
      nITG_nummer
      corrected_temperatures {
        temperature
        z
      }
    }
  }
`;

export const porosityGraphQuery = gql`
  query Borehole($order: orderBy, $filters: [filterBy], $excludes: [filterBy]) {
    borehole(order: $order, filters: $filters, excludes: $excludes) {
      __typename
      id
      boorgatcode
      boorgatnaam
      nITG_nummer
      poroperms {
        depth
        porosity
      }
    }
  }
`;

export const permeabilityGraphQuery = gql`
  query Borehole($order: orderBy, $filters: [filterBy], $excludes: [filterBy]) {
    borehole(order: $order, filters: $filters, excludes: $excludes) {
      __typename
      id
      boorgatcode
      boorgatnaam
      nITG_nummer
      poroperms {
        depth
        hor_permeability
      }
    }
  }
`;

export const boreholeWaterGraphQuery = gql`
  query Borehole($order: orderBy, $filters: [filterBy], $excludes: [filterBy]) {
    borehole(order: $order, filters: $filters, excludes: $excludes) {
      __typename
      id
      geo_thermal_graph {
        datum
        water_amount
        injected_water_amount
      }
    }
  }
`;

export const powerdepthGraphQuery = gql`
  query ScenarioInformation($order: orderBy, $filters: [filterBy]) {
    scenarioInformation(order: $order, filters: $filters) {
      id
      prodtopreservoirdepthlikely
      p50_geothermal_power_mw
      facility_nm
      sde {
        id
        vermogen
      }
    }
  }
`;

export const powerFlowGraphQuery = gql`
  query ScenarioInformation($order: orderBy, $filters: [filterBy]) {
    scenarioInformation(order: $order, filters: $filters) {
      id
      p50_pump_volume_flow_m3_h
      p50_geothermal_power_mw
      facility_nm
      sde {
        id
        vermogen
      }
    }
  }
`;
