import { HttpClient } from '@angular/common/http';
import { Injectable, OnInit } from '@angular/core';
import { GoogleMap } from '@angular/google-maps';
import { BehaviorSubject, Subject } from 'rxjs';
import { BoreholeDataService } from './borehole-data.service';
import { environment } from '@environments/environment';
import { ProModusService } from './pro-modus.service';
import { FormArray } from '@angular/forms';
import { BoreholeWithLocation, MiningLocationWithLocation } from '../interfaces/location.interface';
import { MouseTool } from '@enums/mouse-tool.enum';

@Injectable({
  providedIn: 'root',
})
export class GoogleMapsService {
  public apiLoaded = new BehaviorSubject(false);

  public warmteAtlasLayers: FormArray = new FormArray([]);
  public thermoGisLayers: FormArray = new FormArray([]);

  public map!: GoogleMap;

  public lassoPolygons: google.maps.Polygon[] = [];
  public lassoRectangles: google.maps.Rectangle[] = [];

  public drawingManager!: google.maps.drawing.DrawingManager;

  private warmteatlas!: google.maps.ImageMapType;
  private rivm!: google.maps.ImageMapType;
  private gis!: google.maps.ImageMapType;

  constructor(
    httpClient: HttpClient,
    private boreholeDataService: BoreholeDataService,
    private proModusService: ProModusService
  ) {
    httpClient
      .jsonp(
        `https://maps.googleapis.com/maps/api/js?key=${environment.mapsApiKey}&libraries=drawing,geometry`,
        'callback'
      )
      .subscribe(
        () => {
          this.apiLoaded.next(true);
        },
        (e) => {
          console.log(e);
          this.apiLoaded.next(false);
        }
      );

    this.warmteAtlasLayers.valueChanges.subscribe(() => {
      this.initLayers();
    });
    this.thermoGisLayers.valueChanges.subscribe(() => {
      this.initLayers();
    });

    this.subscribeToProModus();
  }

  private createBbox(proj: google.maps.Projection, coord: google.maps.Point, zoom: number) {
    var zfactor = Math.pow(2, zoom);
    var ul = new google.maps.Point((coord.x * 256.0) / zfactor, ((coord.y + 1) * 256.0) / zfactor);
    var lr = new google.maps.Point(((coord.x + 1) * 256.0) / zfactor, (coord.y * 256.0) / zfactor);
    var ulw = proj.fromPointToLatLng(ul);
    var lrw = proj.fromPointToLatLng(lr);
    var bbox = ulw.lng() + ',' + ulw.lat() + ',' + lrw.lng() + ',' + lrw.lat();
    return bbox;
  }

  private createLayer(
    name: string,
    map: GoogleMap,
    base: string,
    requestType: string | null,
    SRS: string | null,
    layers: string,
    extra?: string
  ) {
    return new google.maps.ImageMapType({
      name: name,
      getTileUrl: (coord, zoom) => {
        var proj = map.getProjection();
        var bbox = '';

        if (!!proj) {
          bbox = this.createBbox(proj, coord, zoom);
        }

        var url = base;
        if (!!requestType) url += 'REQUEST=' + requestType; //WMS operation
        url += '&SERVICE=WMS'; //WMS service
        url += '&VERSION=1.1.0'; //WMS version
        url += '&LAYERS=' + layers; //WMS layers; Andere: GemeenteEnergieCodes, WarmteNetten, WoonKernen, Kassen
        url += '&FORMAT=image/png'; //WMS format
        url += '&BGCOLOR=0xFFFFFF';
        url += '&TRANSPARENT=TRUE';
        if (!!SRS) url += '&SRS=EPSG:4326'; //set WGS84 3349 ou 4326
        url += '&BBOX=' + bbox; // set bounding box
        url += '&WIDTH=256'; //tile size in google
        url += '&HEIGHT=256';
        if (!!extra) url += '&' + extra;
        return url; // return URL for the tile
      },
      tileSize: new google.maps.Size(256, 256),
    });
  }

  private createGisLayer(name: string, map: GoogleMap, layers: string) {
    return new google.maps.ImageMapType({
      name: name,
      getTileUrl: (coord, zoom) => {
        let url =
          'https://www.thermogis.nl/arcgis/rest/services/thermogis/ThermoGIS_WMS/MapServer/export?VERSION=1.1.0&dpi-=96&format=png32&transparent=true&f=image';

        var proj = map.getProjection();
        var bbox = '';

        if (!!proj) {
          bbox = this.createBbox(proj, coord, zoom);
        }

        if (bbox) {
          url += '&bbox=' + bbox;
        }
        url += '&size=256,256';
        url += '&imageSR=28992';
        url += '&bboxSR=4326';
        url += `&layers=show:${layers}`;

        return url;
      },
      tileSize: new google.maps.Size(256, 256),
    });
  }

  public initLayers(enableGis: boolean = false) {
    this.warmteatlas = this.createLayer(
      'warmteatlas',
      this.map,
      'https://rvo.b3p.nl/geoserver/WarmteAtlas/wms?',
      'GetMap',
      'EPSG:4326',
      this.warmteAtlasLayers.value.join(',')
    ); //WMS layers; Andere: GemeenteEnergieCodes, WarmteNetten, WoonKernen, Kassen);

    let index = this.map.overlayMapTypes.getArray().findIndex((item) => {
      return item.name === this.warmteatlas.name;
    });
    this.map.overlayMapTypes.removeAt(index);
    this.map.overlayMapTypes.push(this.warmteatlas);

    // this.rivm = this.createLayer(
    //   'rivm',
    //   this.map,
    //   'http://geodata.rivm.nl/geoserver/wms?',
    //   'GetMap',
    //   'EPSG:4326',
    //   'dank:rivm_r79_rg_grondwaterbeschermingsgebiedendrinkwater, dank:rivm_r78_rg_boringsvrijezonesdrinkwater, dank:rivm_r82_rg_waterwingebiedendrinkwater, dank:rivm_r70_rg_100jaarszonesdrinkwater'
    // ); //dank:rivm_r78_rg_boringsvrijezonesdrinkwater, dank:rivm_r82_rg_waterwingebiedendrinkwater, dank:rivm_r70_rg_100jaarszonesdrinkwater

    this.gis = this.createGisLayer('gis', this.map, this.thermoGisLayers.value.join(','));

    index = this.map.overlayMapTypes.getArray().findIndex((item) => {
      return item.name === this.gis.name;
    });
    if (index > -1) {
      this.map.overlayMapTypes.removeAt(index);
    }
    this.map.overlayMapTypes.push(this.gis);
  }

  public initDrawingManager() {
    const drawingOptions = {
      drawingMode: null,
      map: this.map.googleMap,
      drawingControl: false,
      drawingControlOptions: {
        position: google.maps.ControlPosition.TOP_CENTER,
        drawingModes: [google.maps.drawing.OverlayType.POLYGON, google.maps.drawing.OverlayType.RECTANGLE],
      },
      polygonOptions: {
        strokeColor: '#D0021B',
        fillColor: '#D0021B',
        fillOpacity: 0.5,
      },
      rectangleOptions: {
        strokeColor: '#D0021B',
        fillColor: '#D0021B',
        fillOpacity: 0.5,
      },
      markerOptions: {
        icon: 'https://developers.google.com/maps/documentation/javascript/examples/full/images/beachflag.png',
      },
    };
    this.drawingManager = new google.maps.drawing.DrawingManager(drawingOptions);

    if (this.map.googleMap) {
      this.listenToMouseTool();
    }

    this.drawingManager.addListener('polygoncomplete', (polygon: google.maps.Polygon) => {
      this.lassoPolygons.push(polygon);

      this.addLocationInPolygon(polygon);
    });
    this.drawingManager.addListener('rectanglecomplete', (rectangle: google.maps.Rectangle) => {
      this.lassoRectangles.push(rectangle);

      this.addLocationInRectangle(rectangle);
    });
  }

  public removeLassoShapes() {
    this.lassoPolygons.forEach((polygon) => {
      polygon.setMap(null);
    });
    this.lassoPolygons = [];

    this.lassoRectangles.forEach((rectangle) => {
      rectangle.setMap(null);
    });
    this.lassoRectangles = [];
  }

  private listenToMouseTool() {
    this.proModusService.mouseTool.subscribe((response) => {
      if (response === MouseTool.LASSO) {
        if (this.map.googleMap) {
          this.drawingManager.setDrawingMode(google.maps.drawing.OverlayType.POLYGON);
        }
      } else if (response === MouseTool.RECTANGLE) {
        this.drawingManager.setDrawingMode(google.maps.drawing.OverlayType.RECTANGLE);
      } else {
        this.drawingManager.setDrawingMode(null);
      }
    });
  }

  private addLocationInPolygon(polygon: google.maps.Polygon): void {
    if (this.boreholeDataService.locations.value) {
      const filteredLocations = this.boreholeDataService.locations.value.filter((location) => {
        if (location.location) {
          const latLng = new google.maps.LatLng(location.location.lat, location.location.lng);

          return google.maps.geometry.poly.containsLocation(latLng, polygon);
        } else {
          return false;
        }
      });

      this.addLocations(filteredLocations);
    }
  }

  private addLocationInRectangle(shape: google.maps.Rectangle): void {
    if (this.boreholeDataService.locations.value) {
      const filteredLocations = this.boreholeDataService.locations.value.filter((location) => {
        if (location.location) {
          const latLng = new google.maps.LatLng(location.location.lat, location.location.lng);

          return shape.getBounds().contains(latLng);
        } else {
          return false;
        }
      });

      this.addLocations(filteredLocations);
    }
  }

  private addLocations(filteredLocations: (BoreholeWithLocation | MiningLocationWithLocation)[]) {
    filteredLocations.forEach((location) => {
      if (!this.proModusService.locationIsSelected(location)) {
        this.proModusService.addLocation(location);
      }
    });
  }

  private subscribeToProModus() {
    this.proModusService.isEnabledSubject.subscribe((value) => {
      if (!value) {
        this.removeLassoShapes();
      }
    });
  }
}
