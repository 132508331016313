import { Component, Inject, OnInit } from '@angular/core';
import { MatDialog, MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { WaterAmountChartComponent } from '@components/water-amount-chart/water-amount-chart.component';
import { downloadHelper } from '@helpers/mapping-helper';
import { DownloadService } from '@services/download.service';
import { Apollo } from 'apollo-angular';
import { exportTemperatureQuery } from 'src/app/graphql/queries/export';
import { miningLocationDetailsQuery } from 'src/app/graphql/queries/mining-location';
import { Borehole, BoreholesTypes, MiningLocation } from 'src/app/graphql/types/default';
import { MiningLocationWithLocation } from 'src/app/interfaces/location.interface';
import { ScenarioInformationComponent } from './scenario-information/scenario-information.component';

@Component({
  selector: 'app-mining-location-details',
  templateUrl: './mining-location-details.component.html',
  styleUrls: ['./mining-location-details.component.scss'],
})
export class MiningLocationDetailsComponent implements OnInit {
  public miningLocation!: MiningLocation;
  public isDownloading!: boolean;

  constructor(
    private apollo: Apollo,
    public dialogRef: MatDialogRef<WaterAmountChartComponent>,
    @Inject(MAT_DIALOG_DATA) public data: { miningLocation: MiningLocationWithLocation },
    private dialogService: MatDialog,
    private downloadService: DownloadService
  ) {}

  ngOnInit(): void {
    this.fetchMiningLocation();
  }

  get vermogen(): number | undefined {
    return this.miningLocation?.sde?.vermogen ? parseFloat(this.miningLocation.sde.vermogen) : undefined;
  }
  get beschikbareProductiePerJaar(): number | undefined {
    return this.miningLocation?.sde?.beschikte_productie_per_jaar
      ? parseInt(this.miningLocation.sde.beschikte_productie_per_jaar, 10)
      : undefined;
  }

  get maximaleSubsidie(): number | undefined {
    return this.miningLocation?.sde?.maximale_subsidie
      ? parseInt(this.miningLocation.sde.maximale_subsidie, 10)
      : undefined;
  }

  public openWaterChartForMiningLocation(dataColumn: 'water_amount' | 'injected_water_amount'): void {
    const heading = dataColumn === 'water_amount' ? 'Hoeveelheid geproduceerd water' : 'Hoeveelheid geïnjecteerd water';
    this.dialogService.open(WaterAmountChartComponent, {
      data: {
        id: this.miningLocation.id,
        miningLocationCode: this.miningLocation.mijnbouwwerk_code,
        data_column: dataColumn,
        heading,
        forType: 'MiningLocation',
      },
      panelClass: ['custom-material-styles', 'bg-white'],
      width: '630px',
      autoFocus: false,
    });
  }

  public openSdeScenarios(): void {
    if (this.miningLocation.sde) {
      this.dialogService.open(ScenarioInformationComponent, {
        data: { miningLocation: this.miningLocation },
        panelClass: ['custom-material-styles', 'bg-white'],
        width: '800px',
        autoFocus: false,
      });
    }
  }

  doExport(): void {
    this.isDownloading = true;
    this.apollo
      .query({
        query: exportTemperatureQuery,
        fetchPolicy: 'no-cache',
        variables: {
          temperatureDownloadable: {
            mijnbouwwerk_code: this.data.miningLocation.mijnbouwwerk_code,
            export_type: 'installation',
          },
        },
      })
      .subscribe((result) => {
        if (result.data) {
          console.log((<any>result.data).export);
          this.downloadService.downloadExport((<any>result.data).export).subscribe((res) => {
            downloadHelper(res, (<any>result.data).export);
            this.isDownloading = false;
          });
        }
      });
  }

  private fetchMiningLocation(): void {
    this.apollo
      .query<{ miningLocation: MiningLocation[] }>({
        query: miningLocationDetailsQuery,
        variables: {
          order: null,
          filters: [
            {
              field: 'id',
              values: [this.data.miningLocation.id.toString()],
            },
          ],
        },
      })
      .subscribe((response) => {
        if (response.data) {
          this.miningLocation = response.data.miningLocation[0];
        }
      });
  }
}
