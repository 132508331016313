import { registerLocaleData } from '@angular/common';
import { Component, OnInit } from '@angular/core';
import nl from '@angular/common/locales/nl';
import { AuthService } from '@services/auth.service';
import { UserService } from '@services/user.service';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss'],
})
export class AppComponent implements OnInit {
  title = 'app';

  constructor(private userService: UserService) {}
  ngOnInit() {
    registerLocaleData(nl);
    this.userService.user().subscribe();
    // this.authService.checkLogin();
  }
}
