import { gql } from 'apollo-angular';
import { boreholeListFragment } from '../fragments/borehole';

export const locationQuery = gql`
  query Location(
    $order: orderBy
    $filters: [filterBy]
    $excludes: [filterBy]
    $wheres: [whereClause]
    $search: search
    $type: [BoreholesTypes]
    $scenario_information_ranges: [rangeFilterBy]
  ) {
    location(
      order: $order
      filters: $filters
      excludes: $excludes
      wheres: $wheres
      search: $search
      type: $type
      scenario_information_ranges: $scenario_information_ranges
    ) {
      ... on Borehole {
        ...boreholeListFragment
      }
      ... on MiningLocation {
        __typename
        id
        mijnbouwwerk_naam
        mijnbouwwerk_code
        longitude_ED50
        latitude_ED50
        isGeothermal
      }
    }
  }
  ${boreholeListFragment}
`;
