import { AfterViewInit, ChangeDetectionStrategy, ChangeDetectorRef, Component, OnInit } from '@angular/core';
import { FormControl } from '@angular/forms';
import { MatDialog } from '@angular/material/dialog';
import { MouseTool } from '@enums/mouse-tool.enum';
import { BoreholeDataService } from '@services/borehole-data.service';
import { ProModusService } from '@services/pro-modus.service';
import { BehaviorSubject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';
import { DestroyComponent } from 'src/app/abstracts/destroy.component';
import { BoreholeDetailsComponent } from 'src/app/dialogs/borehole-details/borehole-details.component';
import { PermeabilityChartComponent } from 'src/app/dialogs/bubble-charts/permeability-chart/permeability-chart.component';
import { PorosityChartComponent } from 'src/app/dialogs/bubble-charts/porosity-chart/porosity-chart.component';
import { PowerFlowChartComponent } from 'src/app/dialogs/bubble-charts/power-flow-chart/power-flow-chart.component';
import { TemperatureChartComponent } from 'src/app/dialogs/bubble-charts/temperature-chart/temperature-chart.component';
import { MiningLocationDetailsComponent } from 'src/app/dialogs/mining-location-details/mining-location-details.component';
import { BoreholeWithLocation, MiningLocationWithLocation } from 'src/app/interfaces/location.interface';
import { PowerdepthChartComponent } from '../../dialogs/bubble-charts/powerdepth-chart/powerdepth-chart.component';
import { AuthService } from '@services/auth.service';
import { UserService } from '@services/user.service';

@Component({
  selector: 'app-dashboard',
  templateUrl: './dashboard.component.html',
  styleUrls: ['./dashboard.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class DashboardComponent extends DestroyComponent implements AfterViewInit, OnInit {
  public proModusControl: FormControl = new FormControl(false);
  public filterLayerControl: FormControl = new FormControl(false);
  public locations: (BoreholeWithLocation | MiningLocationWithLocation)[] | undefined = undefined;
  public shouldShowMaps$ = new BehaviorSubject<boolean>(false);

  public selectedBoreholeCount = 0;
  public selectedMiningLocationCount = 0;

  constructor(
    private dialogService: MatDialog,
    public proModusService: ProModusService,
    private changeDetectorRef: ChangeDetectorRef,
    public boreholeDataService: BoreholeDataService,
    public authService: AuthService,
    public userService: UserService
  ) {
    super();
    changeDetectorRef.detach();
    setInterval(() => {
      this.changeDetectorRef.detectChanges();
    }, 500);
  }

  ngOnInit() {
    this.proModusControl.setValue(this.proModusService.isEnabled);
    this.proModusControl.valueChanges.subscribe((value) => {
      if (value) {
        this.proModusService.enable();
      } else {
        this.proModusService.disable();
      }
    });

    this.proModusService.selectedLocations.pipe(takeUntil(this.destroy)).subscribe((selectedLocation) => {
      this.selectedBoreholeCount = selectedLocation.filter((location) => {
        return location.__typename === 'Borehole';
      }).length;
      this.selectedMiningLocationCount = selectedLocation.filter((location) => {
        return location.__typename === 'MiningLocation';
      }).length;
    });
  }

  ngAfterViewInit(): void {
    this.boreholeDataService.locations.subscribe((data) => {
      this.locations = data ?? undefined;
      this.shouldShowMaps$.next(true);
    });
    this.locations = this.boreholeDataService.locations.value ?? undefined;
  }

  get locationsSubject(): BehaviorSubject<(BoreholeWithLocation | MiningLocationWithLocation)[] | null> {
    return this.boreholeDataService.locations;
  }

  public handleBoreholeClick(borehole: BoreholeWithLocation): void {
    if (this.proModusService.mouseTool.value === MouseTool.POINTER) {
      this.proModusService.toggleLocation(borehole);
    } else {
      this.dialogService.open(BoreholeDetailsComponent, {
        data: { id: borehole.id },
        panelClass: 'custom-material-styles',
        width: '600px',
        autoFocus: false,
      });
    }
  }

  public handleMiningLocationClick(miningLocation: MiningLocationWithLocation): void {
    if (this.proModusService.mouseTool.value === MouseTool.POINTER) {
      this.proModusService.toggleLocation(miningLocation);
    } else {
      if (miningLocation.isGeothermal) {
        this.dialogService.open(MiningLocationDetailsComponent, {
          data: { miningLocation },
          panelClass: 'custom-material-styles',
          width: '700px',
          autoFocus: true,
        });
      } else {
        window.alert('Geen detail scherm voor niet-geothermische boreholes');
      }
    }
  }

  public openTemperatureGraph() {
    const boreholeIds = this.proModusService.selectedLocations.value
      // filter out the MiningLocation's because they don't directly have temperature data
      .filter((location) => location.__typename === 'Borehole')
      .map((borehole) => borehole.id);

    if (boreholeIds.length > 0) {
      this.dialogService.open(TemperatureChartComponent, {
        data: {
          boreholeIds: boreholeIds,
        },
      });
    } else {
      window.alert('Geen boreholes geselecteerd');
    }
  }

  public openPorosityGraph() {
    const boreholeIds = this.proModusService.selectedLocations.value
      // filter out the MiningLocation's because they don't directly have temperature data
      .filter((location) => location.__typename === 'Borehole')
      .map((borehole) => borehole.id);

    if (boreholeIds.length > 0) {
      this.dialogService.open(PorosityChartComponent, {
        data: {
          boreholeIds: boreholeIds,
        },
      });
    } else {
      window.alert('Geen boreholes geselecteerd');
    }
  }

  public openPermeabilityGraph() {
    const boreholeIds = this.proModusService.selectedLocations.value
      // filter out the MiningLocation's because they don't directly have temperature data
      .filter((location) => location.__typename === 'Borehole')
      .map((borehole) => borehole.id);

    if (boreholeIds.length > 0) {
      this.dialogService.open(PermeabilityChartComponent, {
        data: {
          boreholeIds: boreholeIds,
        },
      });
    } else {
      window.alert('Geen boreholes geselecteerd');
    }
  }

  public openPowerdepthGraph() {
    const miningLocationIds = this.proModusService.selectedLocations.value
      .filter((location) => location.__typename === 'MiningLocation')
      .map((borehole) => borehole.id);

    if (miningLocationIds.length > 0) {
      this.dialogService.open(PowerdepthChartComponent, {
        data: {
          miningLocationIds: miningLocationIds,
        },
      });
    } else {
      window.alert('Geen facilities geselecteerd');
    }
  }

  public openPowerFlowGraph() {
    const miningLocationIds = this.proModusService.selectedLocations.value
      .filter((location) => location.__typename === 'MiningLocation')
      .map((borehole) => borehole.id);

    if (miningLocationIds.length > 0) {
      this.dialogService.open(PowerFlowChartComponent, {
        data: {
          miningLocationIds: miningLocationIds,
        },
      });
    } else {
      window.alert('Geen facilities geselecteerd');
    }
  }

  public handleLogOut() {
    this.authService.logout();
  }

  public handleLogIn() {
    this.authService.loginUser();
  }
}
