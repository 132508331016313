import { Component, Input } from '@angular/core';
import { FormControl } from '@angular/forms';

@Component({
  selector: 'app-toggle',
  templateUrl: './toggle.component.html',
  styleUrls: ['./toggle.component.scss'],
})
export class ToggleComponent {
  @Input() control!: FormControl;
  @Input() name?: string;
  @Input() color?: 'red' | 'brand-blue' = 'red';

  constructor() {}

  get cssClass() {
    switch (true) {
      case this.control.value && this.color === 'red':
        return 'border-red translate-x-3';

      case this.control.value && this.color === 'brand-blue':
        return 'border-brand-blue translate-x-3';

      case !this.control.value:
        return 'translate-x-0';

      default:
        return '';
    }
  }
}
