import { Component } from '@angular/core';
import { MatDialogRef } from '@angular/material/dialog';

@Component({
  selector: 'app-image-dialog',
  templateUrl: './legend-dialog.component.html',
})
export class LegendDialogComponent {
  constructor(public dialogRef: MatDialogRef<LegendDialogComponent>) {}

  close(): void {
    this.dialogRef.close();
  }
}
